import React, { useEffect, useState } from "react";
import Creation from "./Creation";
import { TfiLayoutSliderAlt } from "react-icons/tfi";
import { PiClockCounterClockwiseFill } from "react-icons/pi";
import { CiFolderOn } from "react-icons/ci";
import { Link, useParams } from "react-router-dom";
import FeedbackModal from "../../common/FeedbackModal";
import axios from "axios";
import { addCreation } from "../../api/creation.req";
import { useDispatch, useSelector } from "react-redux";
import { getBrandKits, uploadImages } from "../../redux/actions/BrandKit.action";
import { CheckPicker, VStack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { toast } from "react-toastify";
const CreateOption = () => {
  const [activeOption, setActiveOption] = useState("Product");
  const [showMenu, setShowMenu] = useState(true);
  const [show1, setShow1] = useState(false);
  const [formData, setFormData] = useState({
    brandkitId: "",
    keywords: [],
    ctaText: "",
    contentType: "",
    currentCampaign: "",
    season: "",
    mood: "",
    visualElemenets: [],
    startDate: "",
    moodType: "",
    colorScheme: " ",
    contentTitle: "",
    contentDescription: "",
    platform: "",
    posterSize: "",
    postHashtags: "",
    productPrompt: "",
    productImage:"",
    advanced: {
      style: "",
      colorScheme: "",
      lighting: "",
      composition: "",
      perspective: "",
      mood: "",
      background: "",
      timePeriod: "",
    },
  });
  const [imageRespo, setImageRespo] = useState();
  const [keywordInput, setKeywordInput] = useState("");
  const paramsData = useParams();
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      platform: decodeURIComponent(paramsData.name).replace(/-/g, " "),
      posterSize: paramsData.size.replace(/-x-/, " x "),
    }));
  }, [paramsData]);

  const handleKeywordAdd = (e) => {
    if (e.key === "Enter" && keywordInput.trim()) {
      setFormData((prev) => ({
        ...prev,
        keywords: [...(prev.keywords || []), keywordInput.trim()],
      }));
      setKeywordInput("");
    }
  };

  const removeKeyword = (index) => {
    const updatedKeywords = formData.keywords.filter((_, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      keywords: updatedKeywords,
    }));
  };

  const toggle = () => {
    setShow1(!show1);
  };
  const handleActionInputChange = (e) => {
    const { name, value } = e.target;
    const [section, key] = name.split(".");

    if (section && key) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [section]: {
          ...prevFormData[section],
          [key]: value,
        },
      }));
      console.log(formData);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    console.log(formData);
  };
  const handleCheckPickerChange = (selectedValues) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      visualElemenets: selectedValues,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const accessToken = localStorage.getItem("authToken");
      const res = await addCreation(formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        },
      });
      console.log(res);
      setImageRespo(res.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.errors[0]?.msg)
      console.log(error);
    }
  };

  const handleCollpse = () => {
    setShowMenu(!showMenu);
    if (showMenu) {
      setActiveOption("");
    } else {
      setActiveOption("Product");
    }
  };

  const handleSection = (option) => {
    setActiveOption(option);
    setShowMenu(true);
  };
  const dispatch = useDispatch();
  const brandKits = useSelector((state) => state.brandKit.getBrandKits);
  console.log({ brandKits });

  useEffect(() => {
    dispatch(getBrandKits());
  }, [dispatch]);
  const socialLinks = [
    {
      name: "LinkedIn",
      image: "/assets/images/home/linkedin.png",
      alt: "linkedin",
      size: " 1080 x 1080",
      trending: true,
    },
    {
      name: "Facebook",
      image: "/assets/images/home/facebook.png",
      alt: "facebook",
      size: "1200 x 630",
      trending: true,
    },
    {
      name: "Instagram",
      image: "/assets/images/home/instagram.png",
      alt: "instagram",
      size: " 1080 x 1350",

      trending: true,
    },
    {
      name: "Whatsapp",
      image: "/assets/images/home/whatsapp.png",
      alt: "whatsapp",
      trending: true,
      size: "192 x 192",
    },
    {
      name: "Youtube",
      image: "/assets/images/home/youtube.png",
      alt: "youtube",
      size: "2560 x 1440",
      trending: false,
    },
    {
      name: "Pinterest",
      image: "/assets/images/home/pinterest.png",
      alt: "pinterest",
      size: "1200 x 630",
      trending: false,
    },
    {
      name: "Twitter",
      image: "/assets/images/home/twitter.png",
      size: "1200 x 675 ",
      alt: "twitter",
      trending: true,
    },
    {
      name: "Amazon",
      image: "/assets/images/home/amazon.png",
      size: "1200 x 630",
      alt: "amazon",
      trending: false,
    },
    {
      name: "Snapchat",
      image: "/assets/images/home/snapchat.png",
      size: "1200 x 630",
      alt: "snapchat",
      trending: false,
    },
    {
      name: "Display Ads",
      image: "/assets/images/home/ad.png",
      alt: "ad",
      size: "1200 x 630",
      trending: false,
    },
  ];
  console.log(formData);
  const [logoFile, setLogoFile] = useState();
  const [logoPreview, setLogoPreview] = useState();
 


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
   
    if (file) {
      setLogoFile(file);
      setLogoPreview(URL.createObjectURL(file));
    }
  };
  const handleImageUpload = async () => {
    if (logoFile) {
      const imageFormData = new FormData();
      imageFormData.append("image", logoFile);
      const response = await dispatch(uploadImages(imageFormData));
      console.log("response", response);
  
      if (response && response.url) {
        setFormData({
          ...formData,
          productImage: response.url,
        });
      } else {
        console.error("Image upload failed or no URL returned");
      }
    } else {
      console.error("No file to upload");
    }
  };
  useEffect(() => {
    handleImageUpload()
   
  }, [logoFile]);

  return (
    <section className="py-[30px] prompt">
      <div className="container-fluid !px-[24px]">
        <div className="flex">
          <div
            className={`bg-[#FAFAFA] flex fixed left-0 overflow-y-auto ${
              showMenu ? "w-[350px]" : "w-[70px]"
            } top-[56px] create-side`}
            style={{ height: "calc(100vh - 56px)" }}
          >
            <div className="text-center relative z-50 flex flex-col justify-between py-3 px-2">
              <div>
                <div
                  className="mb-3 cursor-pointer"
                  onClick={() => handleSection("Product")}
                >
                  <div
                    className={`w-[45px] mx-auto h-[45px] ${
                      activeOption === "Product" ? "bg-light-theme" : ""
                    } hover:bg-light-theme rounded-lg flex items-center justify-center`}
                  >
                    <img
                      src="/assets/images/creation/icon1.svg"
                      className="grayscale w-[24px]"
                      alt="product"
                    />
                  </div>
                  <span className="text-xs">Details</span>
                </div>
                <div
                  className="mb-3 cursor-pointer"
                  onClick={() => handleSection("Campaign")}
                >
                  <div
                    className={`w-[45px] mx-auto h-[45px] ${
                      activeOption === "Campaign" ? "bg-light-theme" : ""
                    } hover:bg-light-theme rounded-lg flex items-center justify-center`}
                  >
                    <img
                      src="/assets/images/creation/icon2.svg"
                      className="w-[24px]"
                      alt="Campaign"
                    />
                  </div>
                  <span className="text-xs">Campaign</span>
                </div>
                <div
                  className="mb-3 cursor-pointer"
                  onClick={() => handleSection("Image")}
                >
                  <div
                    className={`w-[45px] mx-auto h-[45px] ${
                      activeOption === "Image" ? "bg-light-theme" : ""
                    } hover:bg-light-theme rounded-lg flex items-center justify-center`}
                  >
                    <img
                      src="/assets/images/creation/icon3.svg"
                      className="w-[24px]"
                      alt="Image"
                    />
                  </div>
                  <span className="text-xs">Image</span>
                </div>
                {/* <div
                  className="mb-3 cursor-pointer"
                  onClick={() => handleSection("Visuals")}
                >
                  <div
                    className={`w-[45px] mx-auto h-[45px] ${
                      activeOption === "Visuals" ? "bg-light-theme" : ""
                    } hover:bg-light-theme rounded-lg flex items-center justify-center`}
                  >
                    <img
                      src="/assets/images/creation/icon4.svg"
                      className="w-[24px]"
                      alt="Visuals"
                    />
                  </div>
                  <span className="text-xs">Visuals</span>
                </div> */}
                <div
                  className="mb-3 cursor-pointer"
                  onClick={() => handleSection("Social")}
                >
                  <div
                    className={`w-[45px] mx-auto h-[45px] ${
                      activeOption === "Social" ? "bg-light-theme" : ""
                    } hover:bg-light-theme rounded-lg flex items-center justify-center`}
                  >
                    <img
                      src="/assets/images/creation/icon5.svg"
                      className="w-[24px]"
                      alt="Social Media"
                    />
                  </div>
                  <span className="text-xs">Social</span>
                </div>
                <div
                  className="mb-3 cursor-pointer"
                  onClick={() => handleSection("Setting")}
                >
                  <div
                    className={`w-[45px] mx-auto h-[45px] ${
                      activeOption === "Setting" ? "bg-light-theme" : ""
                    } hover:bg-light-theme rounded-lg flex items-center justify-center`}
                  >
                    <img
                      src="/assets/images/creation/icon6.svg"
                      className="w-[24px]"
                      alt="Setting"
                    />
                  </div>
                  <span className="text-xs">Settings</span>
                </div>
              </div>
              <div>
                <div
                  onClick={toggle}
                  className={`w-[45px] mx-auto h-[45px] rounded-lg hover:bg-light-theme cursor-pointer flex items-center justify-center`}
                >
                  <img
                    src="/assets/images/feedback.svg"
                    className="w-[24px]"
                    alt="Setting"
                  />
                </div>
                <span className="text-xs">Feedback</span>
              </div>
            </div>

            <div
              className={`${
                showMenu ? "left-[0%]" : "left-[-300%]"
              } create-side-animation relative w-full mt-[12px] mr-[12px] overflow-hidden`}
            >
              <div className="px-3 pt-3 pb-4 bg-white border rounded-lg">
                {activeOption === "Product" && (
                  <>
                    <h1 className="text-base mb-4 font-medium">
                      Product & Service Details
                    </h1>
                    {/* <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Keywords
                      </label>
                      <input
                        type="text"
                        placeholder="Enter"
                        className="placeholder:text-sm border rounded-lg px-3 py-2 w-full outline-none"
                      />
                    </div> */}
                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Keywords
                      </label>
                      <input
                        type="text"
                        value={keywordInput}
                        onChange={(e) => setKeywordInput(e.target.value)}
                        onKeyDown={handleKeywordAdd}
                        placeholder="Enter"
                        className="placeholder:text-sm border rounded-lg px-3 py-2 w-full outline-none"
                      />
                      <div className="flex flex-wrap mt-2">
                        {formData.keywords &&
                          formData.keywords.map((keyword, index) => (
                            <span
                              key={index}
                              className="bg-gray-200 rounded-full px-3 py-1 text-sm mr-2 mb-2"
                            >
                              {keyword}
                              <button
                                type="button"
                                onClick={() => removeKeyword(index)}
                                className="ml-2 text-red-500"
                              >
                                &times;
                              </button>
                            </span>
                          ))}
                      </div>
                    </div>

                    <div>
                      <label className="flex text-sm items-center gap-2 mb-2">
                        CTA
                      </label>
                      <input
                        name="ctaText"
                        value={formData.ctaText}
                        onChange={handleInputChange}
                        type="text"
                        placeholder="Enter"
                        className="placeholder:text-sm border rounded-lg px-3 py-2 w-full outline-none"
                      />
                    </div>
                    <div className="mt-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Brand Kit
                      </label>
                      <select
                        name="brandkitId"
                        value={formData.brandkitId}
                        onChange={handleInputChange}
                        className="placeholder:text-sm border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {brandKits?.data?.map((data, i) => (
                          <option key={i} value={data.id}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
                {activeOption === "Campaign" && (
                  <>
                    <h1 className="text-base mb-4 font-medium">
                      Campaign Customization
                    </h1>
                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Current campaign
                      </label>
                      <select
                        name="currentCampaign"
                        value={formData.currentCampaign}
                        onChange={handleInputChange}
                        className="placeholder:text-sm border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {[
                          "Autumn Beauty Sale 2024",
                          "sale",
                          "new",
                          "product",
                          "launch",
                          "event promotion",
                          "social proof",
                          "testimonials",
                          "awards & recognition",
                        ].map((data) => (
                          <option key={data} value={data}>
                            {data}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <div className="mb-4">
                        <label className="flex text-sm items-center gap-2 mb-2">
                          Seasonal
                        </label>
                        <select
                          name="season" // Name matches formData key
                          value={formData.season}
                          onChange={handleInputChange}
                          className="placeholder:text-sm border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {["Christmas", "Black Friday", "New Year"].map(
                            (data) => (
                              <option key={data} value={data}>
                                {data}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className="mb-4">
                        <label className="flex text-sm items-center gap-2 mb-2">
                          Mood
                        </label>
                        <select
                          name="mood" // Name matches formData key
                          value={formData.mood}
                          onChange={handleInputChange}
                          className="placeholder:text-sm border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {[
                            "festive",
                            "celebratory",
                            "professional",
                            "casual",
                          ].map((data) => (
                            <option key={data} value={data}>
                              {data}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </>
                )}
                {activeOption === "Image" && (
                  <>
                    <h1 className="text-base mb-4 font-medium">
                      Image Preference
                    </h1>
                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        If generate with AI
                      </label>
                      <input
                        name="productPrompt"
                        value={formData.productPrompt}
                        onChange={handleInputChange}
                        type="text"
                        placeholder="Enter Prompt for product"
                        className="placeholder:text-sm border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                        disabled={formData.productImage}
                      />
                      <span className="block text-center text-black/50 my-2">
                        or
                      </span>
                      <label className="block">
                        <input
                          type="file"
                          className="hidden"
                          onChange={handleFileChange}
                          disabled={formData.productPrompt}
                        />
                        <div className="text-center border text-black/50 text-sm rounded-lg flex items-center justify-center gap-2 px-3 py-2 w-full outline-none cursor-pointer">
                          <img
                            src= {formData.productImage ? "" :"/assets/images/brand-kit/upload.svg" }
                          />
                         {formData.productImage ? formData.productImage : " Upload graph"} 
                        </div>
                      </label>
                    </div>

                    {/* <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Visual Elements
                      </label>
                      <select
                        name="visualElemenets"
                        value={formData.visualElemenets}
                        onChange={handleInputChange}
                        className="placeholder:text-sm border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                      >
                        <option value="" disabled>
                          Select Visual Element
                        </option>
                        {["gold", "icon", "illustrations", "photography"].map(
                          (option) => (
                            <option key={option} value={option}>
                              {option.charAt(0).toUpperCase() + option.slice(1)}
                            </option>
                          )
                        )}
                      </select> // ssdfs
                    </div> */}
                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Visual Elements
                      </label>
                      <CheckPicker
                        data={[
                          { label: "Gold", value: "gold" },
                          { label: "blue", value: "blue" },
                          { label: "Icon", value: "icon" },
                          { label: "Illustrations", value: "illustrations" },
                          { label: "Photography", value: "photography" },
                        ]}
                        value={formData.visualElemenets}
                        onChange={handleCheckPickerChange}
                        placeholder="Select Visual Elements"
                        style={{ width: "100%" }}
                        block
                      />
                    </div>

                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Content Type
                      </label>
                      <select
                        name="contentType"
                        value={formData.contentType}
                        onChange={handleInputChange}
                        className="placeholder:text-sm border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {["Educational content", "image"].map((data) => (
                          <option key={data} value={data}>
                            {data}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
                <div>
                  {/* {activeOption === "Visuals" && (
                  <>
                    <h1 className="text-base mb-4 font-medium">
                      Visuals Details
                    </h1>
                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Inspiration
                      </label>
                      <input
                        type="text"
                        placeholder="Enter"
                        className="placeholder:text-sm border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Colour
                      </label>
                      <input
                        type="text"
                        placeholder="Enter"
                        className="placeholder:text-sm border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Style
                      </label>
                      <input
                        type="text"
                        placeholder="Enter"
                        className="placeholder:text-sm border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                      />
                    </div>
                  </>
                )} */}
                </div>

                {activeOption === "Social" && (
                  <>
                    <h1 className="text-base mb-4 font-medium">
                      Social Sharing
                    </h1>
                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Platform
                      </label>
                      <select
                        name="platform"
                        className="border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                        value={formData.platform}
                        onChange={handleInputChange}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {socialLinks.map((values, i) => (
                          <option key={i} value={values.name}>
                            {values.name}, {values.size}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Content
                      </label>
                      <textarea
                        name="posterSize"
                        value={formData.posterSize}
                        onChange={handleInputChange}
                        rows={4}
                        placeholder="Enter content"
                        className="placeholder:text-sm border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                      ></textarea>
                    </div> */}
                  </>
                )}
                {activeOption === "Setting" && (
                  <div className="h-[600px] overflow-y-auto">
                    <h1 className="text-base mb-4 font-medium">Settings</h1>

                    {/* <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Project Name
                      </label>
                      <input
                        type="text"
                        placeholder="Enter project name"
                        className="placeholder:text-sm border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Project Link
                      </label>
                      <input
                        type="text"
                        placeholder="Enter project link"
                        className="placeholder:text-sm border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Description
                      </label>
                      <textarea
                        rows={4}
                        placeholder="Enter description"
                        className="placeholder:text-sm border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                      ></textarea>
                    </div> */}

                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Style
                      </label>
                      <select
                        name="advanced.style"
                        className="border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                        value={formData.advanced.style}
                        onChange={handleActionInputChange}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {[
                          "Modern",
                          "Realistic",
                          "Abstract",
                          "Cartoonish",
                          "Minimalist",
                          "Retro",
                          "Futuristic",
                        ].map((style) => (
                          <option key={style} value={style}>
                            {style}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Color Scheme
                      </label>
                      <select
                        name="advanced.colorScheme"
                        className="border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                        value={formData.advanced.colorScheme}
                        onChange={handleActionInputChange}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {[
                          "neutral",
                          "warm",
                          "cool",
                          "monochrome",
                          "pastel",
                        ].map((style) => (
                          <option key={style} value={style}>
                            {style}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Lighting
                      </label>
                      <select
                        name="advanced.lighting"
                        className="border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                        value={formData.advanced.lighting}
                        onChange={handleActionInputChange}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {["Soft", "natural", "dramatic", "dim", "backlit"].map(
                          (style) => (
                            <option key={style} value={style}>
                              {style}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Composition/Framing
                      </label>
                      <select
                        name="advanced.composition"
                        className="border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                        value={formData.advanced.composition}
                        onChange={handleActionInputChange}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {[
                          "Balanced",
                          "close-up",
                          " wide shot",
                          "symmetrical",
                          "off-center",
                        ].map((style) => (
                          <option key={style} value={style}>
                            {style}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Perspective
                      </label>
                      <select
                        name="advanced.perspective"
                        className="border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                        value={formData.advanced.perspective}
                        onChange={handleActionInputChange}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {[
                          "Close-Up",
                          "first-person",
                          "aerial",
                          " worm's-eye view",
                          "side-view",
                        ].map((style) => (
                          <option key={style} value={style}>
                            {style}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* <div className="mb-4">
      <label className="flex text-sm items-center gap-2 mb-2">Medium/Texture</label>
      <select
    name="advanced.mood"
    className="border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
    value={formData.advanced.mood}
    onChange={handleActionInputChange}
  >
    {["watercolor","oil painting","pencil sketch","digital art"].map((style) => (
      <option key={style} value={style}>{style}</option>
    ))}
  </select>
    </div> */}

                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Emotion/Mood
                      </label>
                      <select
                        name="advanced.mood"
                        className="border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                        value={formData.advanced.mood}
                        onChange={handleActionInputChange}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {[
                          "Inviting",
                          "serene",
                          "dramatic",
                          "mysterious",
                          "cheerful",
                        ].map((style) => (
                          <option key={style} value={style}>
                            {style}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Background/Setting
                      </label>
                      <select
                        name="advanced.background"
                        className="border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                        value={formData.advanced.background}
                        onChange={handleActionInputChange}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {[
                          "Floral",
                          "urban",
                          "natural",
                          "fantasy",
                          "empty",
                          "futuristic city",
                        ].map((style) => (
                          <option key={style} value={style}>
                            {style}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="flex text-sm items-center gap-2 mb-2">
                        Time Period
                      </label>
                      <select
                        name="advanced.timePeriod"
                        className="border text-black/50 text-sm rounded-lg px-3 py-2 w-full outline-none"
                        value={formData.advanced.timePeriod}
                        onChange={handleActionInputChange}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {["2024", "medieval", "Victorian", "futuristic"].map(
                          (style) => (
                            <option key={style} value={style}>
                              {style}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    <button
                      onClick={handleSubmit}
                      className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="ml-[350px] pt-[56px] w-full">
  {["Product", "Campaign", "Image", "Social","Setting"].includes(activeOption) && <Creation imageData={imageRespo} />}
 
</div>

        </div>
      </div>

      <FeedbackModal isOpen={show1} onClose={() => setShow1(false)} />
    </section>
  );
};

export default CreateOption;
