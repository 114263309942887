const option1 = [
    {
        id:1,
        name:'Brand Identity',
        icon:'/assets/images/brand-kit/brand1.svg'
    },
    {
        id:2,
        name:'Audience',
        icon:'/assets/images/brand-kit/brand2.svg'
    },
    {
        id:3,
        name:'Brand voice',
        icon:'/assets/images/brand-kit/brand3.svg'
    },
    {
        id:4,
        name:'Competitors',
        icon:'/assets/images/brand-kit/brand4.svg'
    },
    {
        id:5,
        name:'Legal & Compliance',
        icon:'/assets/images/brand-kit/brand5.svg'
    }
]

export default option1;