import React from 'react'
import LatestBlog from './LatestBlog'
import BlogBanner from './BlogBanner'
import MoreBlog from './MoreBlog'
import StayDate from './StayDate'

const Index = () => {
  return (
    <>
        <BlogBanner/>
        <LatestBlog/>
        <MoreBlog/>
        <StayDate/>
    </>
  )
}

export default Index