import React from 'react'
import BrandKitSection from './BrandKitSection'
import BrandKitData from './BrandKitData'
import BrandKitOption from './BrandKitOption'
import Header1 from '../../layout/header/Header1'
import BrandKitHome from './BrandKitHome'

const Index = () => {
  return (
    <>
      <Header1 />
      {/* <BrandKitSection /> */}
      {/* <BrandKitOption /> */}
      {/* <BrandKitData /> */}
      <BrandKitHome />
    </>
  )
}

export default Index