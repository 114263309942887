import React, { useState } from 'react'
import AccountRoute from './AccounRroute'
import PersonalInfo from './PersonalInfo';
import Security from './Security';
import ActiveSection from './ActiveSection';
import Legal from './Legal';


const AccountOption = () => {

    const [activeOption, setActiveOption] = useState(AccountRoute[0]);

    const handleOptionClick = (option) => {
        setActiveOption(option);
    };

    return (
        <div>
            <div className='flex'>
                <div className='bg-white border-r-[1px] border-r-[#00000033] fixed min-h-[100vh] w-[300px] px-3'>
                    <div>
                        <p className='text-xl px-[12px] flex items-center h-[48px] my-1 mb-0 font-semibold'>Accounts</p>
                        {Array.isArray(AccountRoute) && AccountRoute.map((res) => (
                            <a
                                key={res.id}
                                href='#path'
                                className={`text-black hover:bg-light-theme flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg 
                                    ${res.id === activeOption.id ? 'bg-light-theme' : ''}`}
                                onClick={() => handleOptionClick(res)}
                            >
                                <img src={res.icon} alt='brand' className='w-[24px]' /> {res.name}
                            </a>
                        ))}
                    </div>
                </div>

                <section className='ml-[300px] flex-grow'>
                    <div className='container'>
                        <div className='row justify-center'>
                            {activeOption.id === 1 &&
                                <PersonalInfo />
                            }
                            {activeOption.id === 2 &&
                                <Security />
                            }
                            {activeOption.id === 3 &&
                                <ActiveSection />
                            }
                            {activeOption.id === 4 &&
                                <Legal />
                            }
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default AccountOption