const SettingRoute = [
    {
        id: 1,
        name: 'Home',
        icon: '/assets/images/icon1.svg'
    },
    {
        id: 2,
        name: 'Account',
        icon: '/assets/images/account/user.svg',
        subRoute: [
            {
                id: 11,
                name: 'Personal Information',
                icon: '/assets/images/account/user.svg',
            },
            {
                id: 12,
                name: 'Security',
                icon: '/assets/images/account/shield.svg'
            },
            {
                id: 13,
                name: 'Active Sessions',
                icon: '/assets/images/account/Active session.svg'
            },
            {
                id: 14,
                name: 'Legal',
                icon: '/assets/images/account/legal.svg'
            }
        ]
    },
    {
        id: 3,
        name: 'Billing',
        icon: '/assets/images/account/legal.svg',
        subRoute: [
            {
                id: 15,
                name: 'Payment Details',
                icon: '/assets/images/account/credit.svg',
            },
            {
                id: 16,
                name: 'Subscription',
                icon: '/assets/images/account/subs.svg'
            },
            {
                id: 17,
                name: 'Purchase History',
                icon: '/assets/images/account/bill.svg'
            }
        ]
    }
]

export default SettingRoute;