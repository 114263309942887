import React from 'react'
import { Modal } from 'react-bootstrap'

const UploadImageModal = ({uploadImage,uploadToggle}) => {
    return (
        <Modal show={uploadImage} size='lg' centered onHide={uploadToggle} className='img_upload'>
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='!text-[26px]'>Upload an images</Modal.Title>
            </Modal.Header>
            <Modal.Body className='h-[60vh] overflow-auto !pt-0'>
                <label className='h-full w-full border-[2px] rounded-lg border-dashed'>
                    <input type='file' className='d-none'/>
                    <div className='flex flex-col h-full justify-center items-center'>
                        <img src='/assets/images/home/upload.svg' className='w-[160px]' alt='upload-image'/>
                        <div className='text-lg font-semibold mb-2 mt-4 block'>Drag and drop or click here to upload</div>
                        <p className='text-sm'>You can also <span className='text-theme-color hover:underline cursor-pointer'>browse from your computer</span></p>
                    </div>
                </label>
            </Modal.Body>
        </Modal>
    )
}

export default UploadImageModal