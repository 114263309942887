import { CREATE_CREATION ,GET_CREATION } from "../actions/Creation.action";



const initialState = {
  createCreation: "",
  getCreation:"",
};

const creationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CREATION:
      return { ...state, createCreation: action.payload };
      case GET_CREATION:
        return{...state ,getCreation:action.payload }
    default:
      return state;
  }
};

export default creationReducer;
