import React from 'react'
import { FiArrowUpRight } from 'react-icons/fi'

const MoreBlog = () => {
    return (
        <section className='py-[50px]'>
            <div className='container-fluid md:!px-[60px] !px-[16px]'>
                <h1 className='md:text-[40px] text-[24px] text-center mb-5'>More from the Zunno Blog</h1>

                <h2 className='md:text-[24px] text-lg mb-3'>All blog posts</h2>
                <div className='row gy-5'>
                    <div className='col-lg-4 col-md-6'>
                        <div className='overflow-hidden'>
                            <img src='/assets/images/zunno-blog/blog3.png' className='w-full hover:scale-110 duration-300' />
                        </div>
                        <div className='mt-3'>
                            <span className='text-[#2C3A9B] text-sm font-[600]'>Alec Whitten • 1 Jan 2023</span>
                            <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                <h1 className='md:text-[24px] text-xl'>Bill Walsh leadership lessons</h1>
                                <FiArrowUpRight className='text-2xl' />
                            </div>
                            <p className='text-[#667085]'>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?</p>
                            <span className='bg-[#F9F5FF] text-sm font-[500] text-[#6941C6] rounded-pill px-3 py-1'>Leadership</span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='overflow-hidden'>
                            <img src='/assets/images/zunno-blog/blog3.png' className='w-full hover:scale-110 duration-300' />
                        </div>
                        <div className='mt-3'>
                            <span className='text-[#2C3A9B] text-sm font-[600]'>Alec Whitten • 1 Jan 2023</span>
                            <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                <h1 className='md:text-[24px] text-xl'>Bill Walsh leadership lessons</h1>
                                <FiArrowUpRight className='text-2xl' />
                            </div>
                            <p className='text-[#667085]'>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?</p>
                            <span className='bg-[#F9F5FF] text-sm font-[500] text-[#6941C6] rounded-pill px-3 py-1'>Leadership</span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='overflow-hidden'>
                            <img src='/assets/images/zunno-blog/blog3.png' className='w-full hover:scale-110 duration-300' />
                        </div>
                        <div className='mt-3'>
                            <span className='text-[#2C3A9B] text-sm font-[600]'>Alec Whitten • 1 Jan 2023</span>
                            <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                <h1 className='md:text-[24px] text-xl'>Bill Walsh leadership lessons</h1>
                                <FiArrowUpRight className='text-2xl' />
                            </div>
                            <p className='text-[#667085]'>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?</p>
                            <span className='bg-[#F9F5FF] text-sm font-[500] text-[#6941C6] rounded-pill px-3 py-1'>Leadership</span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='overflow-hidden'>
                            <img src='/assets/images/zunno-blog/blog3.png' className='w-full hover:scale-110 duration-300' />
                        </div>
                        <div className='mt-3'>
                            <span className='text-[#2C3A9B] text-sm font-[600]'>Alec Whitten • 1 Jan 2023</span>
                            <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                <h1 className='md:text-[24px] text-xl'>Bill Walsh leadership lessons</h1>
                                <FiArrowUpRight className='text-2xl' />
                            </div>
                            <p className='text-[#667085]'>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?</p>
                            <span className='bg-[#F9F5FF] text-sm font-[500] text-[#6941C6] rounded-pill px-3 py-1'>Leadership</span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='overflow-hidden'>
                            <img src='/assets/images/zunno-blog/blog3.png' className='w-full hover:scale-110 duration-300' />
                        </div>
                        <div className='mt-3'>
                            <span className='text-[#2C3A9B] text-sm font-[600]'>Alec Whitten • 1 Jan 2023</span>
                            <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                <h1 className='md:text-[24px] text-xl'>Bill Walsh leadership lessons</h1>
                                <FiArrowUpRight className='text-2xl' />
                            </div>
                            <p className='text-[#667085]'>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?</p>
                            <span className='bg-[#F9F5FF] text-sm font-[500] text-[#6941C6] rounded-pill px-3 py-1'>Leadership</span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='overflow-hidden'>
                            <img src='/assets/images/zunno-blog/blog3.png' className='w-full hover:scale-110 duration-300' />
                        </div>
                        <div className='mt-3'>
                            <span className='text-[#2C3A9B] text-sm font-[600]'>Alec Whitten • 1 Jan 2023</span>
                            <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                <h1 className='md:text-[24px] text-xl'>Bill Walsh leadership lessons</h1>
                                <FiArrowUpRight className='text-2xl' />
                            </div>
                            <p className='text-[#667085]'>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?</p>
                            <span className='bg-[#F9F5FF] text-sm font-[500] text-[#6941C6] rounded-pill px-3 py-1'>Leadership</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MoreBlog