import React from 'react'
import Index from '../components/my-project/Index'

const MyProject = () => {
  return (
    <>
        <Index/>
    </>
  )
}

export default MyProject