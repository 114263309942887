import React, { useState } from 'react'
import { MdOutlineUnfoldMore } from "react-icons/md";
import { MdUnfoldLess } from "react-icons/md";

const PriceFaq = () => {

    const [expandAll, setExpandAll] = useState(false);

    const handleExpandAll = () => {
        setExpandAll(!expandAll);
    };

    return (
        <div className="partner_faq pt-[50px]">

            <div className=' mb-5'>
                <h2 className="md:text-[35px] text-[24px] font-[700] text-center">Frequently Asked Questions</h2>
            </div>

            <div className='flex justify-end mb-3 cursor-pointer'>
                <span className='flex items-center gap-2 font-semibold text-theme-color' onClick={handleExpandAll}>{!expandAll ? <> Expand all <MdOutlineUnfoldMore /> </> : <>Collapse all <MdUnfoldLess /> </>}</span>
            </div>

            <div className="accordion faqs" id="softwareFaq">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className={`accordion-button ${expandAll ? '' : 'collapsed'}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#softwareOne"
                            aria-expanded={expandAll}
                            aria-controls="softwareOne">
                            How do you ensure the quality of your software solutions?
                        </button>
                    </h2>
                    <div id="softwareOne" className={`accordion-collapse collapse ${expandAll ? 'show' : ''}`} data-bs-parent="#softwareFaq">
                        <div className="accordion-body visible">
                            We follow strict quality assurance protocols, including comprehensive automated and manual testing and continuous monitoring, to ensure our software solutions are reliable, secure, and high-performing.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className={`accordion-button ${expandAll ? '' : 'collapsed'}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#softwareTwo"
                            aria-expanded={expandAll}
                            aria-controls="softwareTwo">
                            What industries do you specialize in for software development?
                        </button>
                    </h2>
                    <div id="softwareTwo" className={`accordion-collapse collapse ${expandAll ? 'show' : ''}`} data-bs-parent="#softwareFaq">
                        <div className="accordion-body visible">
                            Our team has experience across various industries, including healthcare, finance, retail, manufacturing, and more, ensuring tailored solutions for each sector.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className={`accordion-button ${expandAll ? '' : 'collapsed'}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#softwareThree"
                            aria-expanded={expandAll}
                            aria-controls="softwareThree">
                            What is your development process?
                        </button>
                    </h2>
                    <div id="softwareThree" className={`accordion-collapse collapse ${expandAll ? 'show' : ''}`} data-bs-parent="#softwareFaq">
                        <div className="accordion-body visible">
                            Our development process includes discovery and planning, design, development, testing, deployment, and ongoing support to ensure seamless delivery and maintenance.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className={`accordion-button ${expandAll ? '' : 'collapsed'}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#softwareFour"
                            aria-expanded={expandAll}
                            aria-controls="softwareFour">
                            How do you stay updated with the latest technologies?
                        </button>
                    </h2>
                    <div id="softwareFour" className={`accordion-collapse collapse ${expandAll ? 'show' : ''}`} data-bs-parent="#softwareFaq">
                        <div className="accordion-body visible">
                            Our team continually learns and adapts to the latest technological advancements, ensuring we use cutting-edge tools and methodologies in all our projects.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PriceFaq