import React from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { auth } from '../firebase/firebase.config';
import { signOut } from 'firebase/auth';

const Logout = ({ logOut, logOutToggle }) => {

    const navigate = useNavigate();
    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate("/sign-up");
            toast.success("Signed out successfully")
        }).catch((error) => {
            toast.error(error)
        });
    }

    return (
        <section>
            <Modal centered show={logOut} onHide={logOutToggle}>
                {/* <div onClick={toggles} className="absolute cursor-pointer -top-8 -right-8 w-[30px] h-[30px] bg-theme-color text-white flex items-center justify-center rounded-full"><IoClose className='text-lg'/></div> */}
                <Modal.Body className='py-5 '>
                    <div className="rounded-lg px-3">
                        <img src="/assets/images/logout.svg" className='w-[100px] mb-4 mx-auto' alt="logout" />

                        <h1 className='text-[24px] text-center mb-3'>Oh no ! You’re leaving </h1>
                        <h1 className='text-[24px] text-center mb-3'>Are you sure?</h1>

                        <div onClick={logOutToggle} className="w-[200px] cursor-pointer bg-theme-color py-2 mx-auto px-4 mb-3 mt-5 rounded-pill text-center text-white border-[1px] border-theme-color">No</div>
                        <div onClick={handleLogout} className="w-[200px] cursor-pointer py-2 px-4 mx-auto rounded-pill text-center text-theme-color border-[1px] border-theme-color">Yes ,Log me out</div>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default Logout