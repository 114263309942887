import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

const PersonalInfo = () => {

    const [show, setShow] = useState(false);
    const toggle = () => { setShow(!show) }
    const [editMode, setEditMode] = useState({
        name: false,
        birthday: false,
        gender: false,
        email: false,
        phone: false,
        language: false,
    });

    const [formData, setFormData] = useState({
        name: 'John Deo',
        birthday: '04-05-2000',
        gender: 'Male',
        email: 'john@example.com',
        phone: '123456789',
        language: 'English(United Kingdom)',
    });

    const handleEditClick = (field) => {
        setEditMode({ ...editMode, [field]: !editMode[field] });
    };

    const handleInputChange = (e, field) => {
        setFormData({ ...formData, [field]: e.target.value });
    };

    const handleSave = (field) => {
        setEditMode({ ...editMode, [field]: false });
    };

    const handleCancel = (field) => {
        setEditMode({ ...editMode, [field]: false });
    };

    return (
        <div className='col-lg-10 py-[50px]'>
            {/* <div className='text-left'>
                <h1 className='md:text-[28px] mb-2 text-xl'>Personal Info</h1>
                <p className='mb-0 text-black/50'>Info about you and your preferences across Google services</p>
            </div> */}
            <div className='flex items-center justify-between border-b'>
                <div>
                    <h1 className='md:text-[28px] mb-3 text-xl'>Your profile info in Zunno services</h1>
                    <p className='mb-0 '>Personal info and options to manage  it.You can make some of
                        this info , like your contact details , visible to others so that they can reach you easily.
                    </p>
                </div>
                <img src='/assets/images/account/img.png' className='w-[250px]' />
            </div>

            <div className='mt-4 border-[1px] border-black/20 rounded-lg'>
                <div className='py-4 px-5'>
                    <h1 className='mb-2 text-lg'>Basic info</h1>
                    <p className='mb-0 text-sm text-black/50'>Some info may be visible to other people using Zunno services</p>

                    <div className='mt-3 flex items-center justify-between'>
                        <div className='flex items-center gap-5'>
                            <h1 className='text-base mb-0 w-[150px]'>Profile picture</h1>
                            <p className='mb-0 text-black/80'>A profile picture helps personalise your account</p>
                        </div>
                        <div className='relative cursor-pointer' onClick={toggle}>
                            <img src="/assets/images/account/user_profile.svg" alt="user profile" />
                            <label className='absolute -bottom-3 left-1/2 -translate-x-1/2'>
                                <img src="/assets/images/account/camera.svg" alt="camera" />
                            </label>
                        </div>
                    </div>
                </div>
                <div className='border-t px-5 py-3 flex items-center justify-between'>
                    <div className='flex items-center gap-5'>
                        <h1 className='text-base mb-0 w-[150px]'>Name</h1>
                        {editMode.name ? (
                            <input
                                type='text'
                                value={formData.name}
                                onChange={(e) => handleInputChange(e, 'name')}
                                className='border rounded w-[250px] px-2 py-1 outline-none'
                            />
                        ) : (
                            <p className='mb-0 text-black/80'>{formData.name}</p>
                        )}
                    </div>
                    <div className="flex items-center gap-3">
                        {editMode.name ? (
                            <>
                                <button className='border-[1px] text-theme-color  w-[100px] border-theme-color px-4 rounded-pill py-[5px]'
                                    onClick={() => handleCancel('name')}>Cancel</button>
                                <button className='bg-theme-color border-[1px]  w-[100px] border-theme-color text-white px-4 rounded-pill py-[5px]'
                                    onClick={() => handleSave('name')}>Save</button>
                            </>
                        ) : (
                            <button className='bg-theme-color border-[1px]  w-[100px] border-theme-color text-white px-4 rounded-pill py-[5px]'
                                onClick={() => handleEditClick('name')}>Edit</button>
                        )}
                    </div>
                </div>
                <div className='border-t px-5 py-3 flex items-center justify-between'>
                    <div className='flex items-center gap-5'>
                        <h1 className='text-base mb-0 w-[150px]'>Birthday</h1>
                        {editMode.birthday ? (
                            <input
                                type='date'
                                value={formData.birthday}
                                onChange={(e) => handleInputChange(e, 'birthday')}
                                className='border rounded w-[250px] px-2 py-1 outline-none'
                            />
                        ) : (
                            <p className='mb-0 text-black/80'>{formData.birthday}</p>
                        )}
                    </div>
                    <div className="flex items-center gap-3">
                        {editMode.birthday ? (
                            <>
                                <button className='border-[1px] text-theme-color w-[100px] border-theme-color px-4 rounded-pill py-[5px]'
                                    onClick={() => handleCancel('birthday')}>Cancel</button>
                                <button className='bg-theme-color border-[1px] w-[100px] border-theme-color text-white px-4 rounded-pill py-[5px]'
                                    onClick={() => handleSave('birthday')}>Save</button>
                            </>
                        ) : (
                            <button className='bg-theme-color border-[1px] w-[100px] border-theme-color text-white px-4 rounded-pill py-[5px]'
                                onClick={() => handleEditClick('birthday')}>Edit</button>
                        )}
                    </div>
                </div>
                <div className='border-t px-5 py-3 flex items-center justify-between'>
                    <div className='flex items-center gap-5'>
                        <h1 className='text-base mb-0 w-[150px]'>Gender</h1>
                        {editMode.gender ? (
                            <select value={formData.gender} onChange={(e) => handleInputChange(e, 'gender')} className='border w-[250px] outline-none rounded px-2 py-1'>
                                <option value="Male" key="">Male</option>
                                <option value="Female" key="">Female</option>
                                <option value="Other" key="">Prefer not to say</option>
                            </select>
                        ) : (
                            <p className='mb-0 text-black/80'>{formData.gender}</p>
                        )}
                    </div>
                    <div className="flex items-center gap-3">
                        {editMode.gender ? (
                            <>
                                <button className='border-[1px] text-theme-color w-[100px] border-theme-color px-4 rounded-pill py-[5px]'
                                    onClick={() => handleCancel('gender')}>Cancel</button>
                                <button className='bg-theme-color border-[1px] w-[100px] border-theme-color text-white px-4 rounded-pill py-[5px]'
                                    onClick={() => handleSave('gender')}>Save</button>
                            </>
                        ) : (
                            <button className='bg-theme-color border-[1px] w-[100px] border-theme-color text-white px-4 rounded-pill py-[5px]'
                                onClick={() => handleEditClick('gender')}>Edit</button>
                        )}
                    </div>
                </div>
            </div>

            <div className='mt-4 border-[1px] border-black/20 rounded-lg'>
                <div className='py-4 px-5'>
                    <h1 className='mb-3 text-lg'>What will you using Zunno for?</h1>
                    <select className='border rounded-lg outline-none px-3 py-2 col-6'>
                        <option value="">Select Option</option>
                        <option value="Small Business">Small Business</option>
                        <option value="Big Business">Big Business</option>
                    </select>
                </div>
            </div>

            <div className='mt-4 border-[1px] border-black/20 rounded-lg'>
                <div className='py-4 px-5'>
                    <h1 className='mb-2 text-lg'>Contact info</h1>
                    <p className='mb-0 text-sm text-black/50'>Some info may be visible to other people using Zunno services</p>
                </div>
                <div className='border-t px-5 py-3 flex items-center justify-between'>
                    <div className='flex items-center gap-5'>
                        <h1 className='text-base mb-0 w-[150px]'>Email</h1>
                        {editMode.email ? (
                            <input
                                type='text'
                                value={formData.email}
                                onChange={(e) => handleInputChange(e, 'email')}
                                className='border rounded w-[250px] px-2 py-1 outline-none'
                            />
                        ) : (
                            <p className='mb-0 text-black/80'>{formData.email}</p>
                        )}
                    </div>
                    <div className="flex items-center gap-3">
                        {editMode.email ? (
                            <>
                                <button className='border-[1px] text-theme-color w-[100px] border-theme-color px-4 rounded-pill py-[5px]'
                                    onClick={() => handleCancel('email')}>Cancel</button>
                                <button className='bg-theme-color border-[1px] w-[100px] border-theme-color text-white px-4 rounded-pill py-[5px]'
                                    onClick={() => handleSave('email')}>Save</button>
                            </>
                        ) : (
                            <button className='bg-theme-color border-[1px] w-[100px] border-theme-color text-white px-4 rounded-pill py-[5px]'
                                onClick={() => handleEditClick('email')}>Edit</button>
                        )}                    </div>
                </div>
                <div className='border-t px-5 py-3 flex items-center justify-between'>
                    <div className='flex items-center gap-5'>
                        <h1 className='text-base mb-0 w-[150px]'>Phone</h1>
                        {editMode.phone ? (
                            <input
                                type='text'
                                value={formData.phone}
                                onChange={(e) => handleInputChange(e, 'phone')}
                                className='border rounded w-[250px] px-2 py-1 outline-none'
                            />
                        ) : (
                            <p className='mb-0 text-black/80'>{formData.phone}</p>
                        )}
                    </div>
                    <div className="flex items-center gap-3">
                        {editMode.phone ? (
                            <>
                                <button className='border-[1px] text-theme-color w-[100px] border-theme-color px-4 rounded-pill py-[5px]'
                                    onClick={() => handleCancel('phone')}>Cancel</button>
                                <button className='bg-theme-color border-[1px] w-[100px] border-theme-color text-white px-4 rounded-pill py-[5px]'
                                    onClick={() => handleSave('phone')}>Save</button>
                            </>
                        ) : (
                            <button className='bg-theme-color border-[1px] w-[100px] border-theme-color text-white px-4 rounded-pill py-[5px]'
                                onClick={() => handleEditClick('phone')}>Edit</button>
                        )}                     </div>
                </div>
                <div className='border-t px-5 py-3 flex items-center justify-between'>
                    <div className='flex items-center gap-5'>
                        <h1 className='text-base mb-0 w-[150px]'>Languages</h1>
                        {editMode.language ? (
                            <select value={formData.language} onChange={(e) => handleInputChange(e, 'language')} className='border w-[250px] outline-none rounded px-2 py-1'>
                                <option value="English" key="">English(United Kingdom)</option>
                                <option value="Hindi" key="">Hindi</option>
                                <option value="Spanish" key="">Spanish</option>
                                <option value="Gujarati" key="">Gujarati</option>
                            </select>
                        ) : (
                            <p className='mb-0 text-black/80'>{formData.language}</p>
                        )}
                    </div>
                    <div className="flex items-center gap-3">
                        {editMode.language ? (
                            <>
                                <button className='border-[1px] text-theme-color w-[100px] border-theme-color px-4 rounded-pill py-[5px]'
                                    onClick={() => handleCancel('language')}>Cancel</button>
                                <button className='bg-theme-color border-[1px] w-[100px] border-theme-color text-white px-4 rounded-pill py-[5px]'
                                    onClick={() => handleSave('language')}>Save</button>
                            </>
                        ) : (
                            <button className='bg-theme-color border-[1px] w-[100px] border-theme-color text-white px-4 rounded-pill py-[5px]'
                                onClick={() => handleEditClick('language')}>Edit</button>
                        )}                    </div>
                </div>
            </div>

            {/* profile modal */}
            <Modal show={show} onHide={toggle} centered>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="p-3">
                        <h1 className='md:text-[24px] text-xl'>Profile picture</h1>
                        <p className='text-black/50'>A picture helps people to recognize ypu and lets you know when you’re signed in to your account.</p>

                        <img src="/assets/images/account/profile.png" className='w-[200px] h-[200px] object-cover rounded-full mx-auto my-5' alt="camera" />

                        <div className="flex items-center justify-center gap-4">
                            <button className='flex items-center gap-2 font-medium border-[1px] border-light-theme rounded-pill bg-light-theme px-4 py-[5px]'><FiEdit2 />Change</button>
                            <button className='flex items-center gap-2 font-medium border-[1px] border-light-theme rounded-pill bg-light-theme px-4 py-[5px]'><RiDeleteBin6Line />Delete</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PersonalInfo