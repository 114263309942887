import React from 'react'
import Index from '../components/settings/Index'

const SettingsInfo = () => {
    return (
        <>
            <Index />
        </>
    )
}

export default SettingsInfo