import React from 'react'
import { Navbar } from 'react-bootstrap'

const MobileView = () => {
    return (
        <div className='bg-light-theme fixed top-0 flex flex-col justify-center items-center min-h-screen text-center'>
            <div className="flex flex-col justify-center items-center h-full">
                <Navbar.Brand href="/" className='select-none mb-3 d-flex gap-2 items-center'>
                    <img src='/assets/images/logos.svg' alt='logo' />
                    <div className='text-theme-color bg-white py-1 px-3 font-semibold text-xs rounded-pill'>
                        BETA
                    </div>
                </Navbar.Brand>
                <p className='text-lg font-medium text-black/50 px-4'>
                    Zunno is available on desktop, switch to desktop for better experience.
                </p>
            </div>
        </div>
    )
}

export default MobileView