import React from 'react';
import { Link } from 'react-router-dom';

const LinkedinDetails = () => {
    const postTypes = [
        { name: "Ad Landscape", size: "1080 x 1080", imgSrc: '/assets/images/home/linkedin.svg' },
        { name: "Ad Square", size: "1080 x 1080", imgSrc: '/assets/images/home/linkedin.svg' },
        { name: "Post", size: "1080 x 1080", imgSrc: '/assets/images/home/linkedin.svg' },
        { name: "Cover", size: "1080 x 1080", imgSrc: '/assets/images/home/linkedin.svg' },
        { name: "Banner", size: "1080 x 1080", imgSrc: '/assets/images/home/linkedin.svg' }
    ];

    return (
        <div>
            <h1 className='md:text-[24px] text-base my-4'>LinkedIn</h1>
            <div className="flex details gap-3 flex-nowrap overflow-x-auto whitespace-nowrap">
                {postTypes.map((post, index) => (
                    <Link to={`/create/${encodeURIComponent(post.name.replace(/\s+/g, '-'))}/${post.size.replace(/\s*x\s*/, '-x-')}`}  key={index} className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                        <div className='relative rounded-[12px] overflow-hidden'>
                            <img src={post.imgSrc} className='w-full' alt='linkedin' />
                            <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>
                                Start Generating
                            </div>
                        </div>
                        <div className='mt-2'>
                            <p className='mb-0 text-sm font-medium'>{post.name}</p>
                            <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>{post.size}</span>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default LinkedinDetails;
