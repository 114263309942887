import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

function ProtectedRoute({ children }) {
  const token = localStorage.getItem("authToken");  

  return token ? children : <Navigate to="/sign-up" />;
}

export default ProtectedRoute;
