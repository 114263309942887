import React, { useEffect, useRef, useState } from "react";
import { GoArrowLeft } from "react-icons/go";
import option1 from "./BrandRoute";
import Option1 from "./Option1";
import Option2 from "./Option2";
import Option3 from "./Option3";
import Option4 from "./Option4";
import Option5 from "./Option5";
import { Link, useParams } from "react-router-dom";
import { addBrandKit, getBrandKit } from "../../redux/actions/BrandKit.action";
import { useDispatch } from "react-redux";

const BrandKitOption = () => {
  const [activeOption, setActiveOption] = useState({ id: 1 });
  const { brandId } = useParams();
  const componentRef = useRef(null);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    option1: {
      name: "",
      colors: [],
      logo: "",
      titleFont: "",
      subTitleFont: "",
      bodyFont: "",
      tagLine: "",
    },
    option2: {
      industry: "",
      demographic: "",
      psychographic: "",
      audienceInterest: "",
    },
    option3: { toneOfVoice: "", messageStyle: "", visualStyle: "" },
    option4: { competitorBrands: [], benchmarkBrands: [] },
    option5: { brandGuidelinedocs: [], copyrightText: "" },
  });
  console.log({ formData });

  function handleFetch() {
    dispatch(getBrandKit(brandId))
      .then((response) => {
        if (response.data) {
          setFormData({
            option1: {
              name: response.data.name,
              colors: response.data.colors || [],
              logo: response.data.logo || "",
              titleFont: response.data.titleFont || "",
              subTitleFont: response.data.subTitleFont || "",
              bodyFont: response.data.bodyFont || "",
              tagLine: response.data.tagLine || "",
            },
            option2: {
              industry: response.data.industry || "",
              demographic: response.data.demographic || "",
              psychographic: response.data.psychographic || "",
              audienceInterest: response.data.audienceInterest || "",
            },
            option3: {
              toneOfVoice: response.data.toneOfVoice || "",
              messageStyle: response.data.messageStyle || "",
              visualStyle: response.data.visualStyle || "",
            },
            option4: {
              competitorBrands: response.data.competitorBrands || [],
              benchmarkBrands: response.data.benchmarkBrands || [],
            },
            option5: {
              brandGuidelinedocs: response.data.brandGuidelinedocs || [],
              copyrightText: response.data.copyrightText || "",
            },
          });
        }
      })
      .catch((error) => console.error("Failed to fetch brand kit:", error));
  }

  useEffect(() => {
    if (!brandId) return;
    handleFetch();
  }, [brandId]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [activeOption]);

  const handleOptionClick = (option) => {
    setActiveOption(option);
  };

  const handleNext = (data, optionId) => {
    setFormData((prevState) => ({
      ...prevState,
      [`option${optionId}`]: data,
    }));
    setActiveOption((prev) => ({ id: prev.id + 1 }));
  };

  const handlePrev = () => {
    setActiveOption((prev) => ({ id: prev.id - 1 }));
  };
  let finalFormData = null;
  const handleSubmit = (data, step) => {
    setFormData((prevData) => {
      finalFormData = {
        ...prevData.option1,
        ...prevData.option2,
        ...prevData.option3,
        ...prevData.option4,
        ...data,
      };
      return {
        ...prevData,
        [`option${step}`]: data,
      };
    });

    if (brandId) {
      // // Update existing brand kit
      // dispatch(updateBrandKit(brandId, finalFormData))
      //   .then(() => {
      //     console.log("Brand kit updated successfully");
      //   })
      //   .catch((error) => console.error("Failed to update brand kit:", error));
    } else {
      // Add a new brand kit
      dispatch(addBrandKit(finalFormData))
        .then(() => {
          console.log("Brand kit added successfully");
        })
        .catch((error) => console.error("Failed to add brand kit:", error));
    }
  };

  return (
    <div className="flex">
      <div className="bg-[#F9FAFB] border-r-[1px] border-r-[#00000033] fixed min-h-[100vh] w-[300px] px-3">
        <div>
          <Link
            to="/brands-kits"
            className="text-xl px-[12px] text-black flex items-center gap-3 h-[48px] my-1 mb-0 font-semibold"
          >
            <GoArrowLeft /> Brand Kits
          </Link>
          {Array.isArray(option1) &&
            option1.map((res) => (
              <a
                key={res.id}
                href="#"
                className={`text-black hover:bg-light-theme flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg ${
                  res.id === activeOption.id ? "bg-light-theme" : ""
                }`}
                onClick={() => handleOptionClick(res)}
              >
                <img src={res.icon} alt="brand" className="w-[24px]" />{" "}
                {res.name}
              </a>
            ))}
        </div>
      </div>

      <div ref={componentRef}>
        <section className="py-[50px] ml-[300px] flex-grow">
          <div className="container">
            <div className="row gy-4 justify-center">
              {activeOption.id === 1 && (
                <Option1
                  handleNext={(data) => handleNext(data, 1)}
                  handlePrev={handlePrev}
                  formData={formData.option1}
                />
              )}
              {activeOption.id === 2 && (
                <Option2
                  handleNext={(data) => handleNext(data, 2)}
                  handlePrev={handlePrev}
                  formData={formData.option2}
                />
              )}
              {activeOption.id === 3 && (
                <Option3
                  handleNext={(data) => handleNext(data, 3)}
                  handlePrev={handlePrev}
                  formData={formData.option3}
                />
              )}
              {activeOption.id === 4 && (
                <Option4
                  handleNext={(data) => handleNext(data, 4)}
                  handlePrev={handlePrev}
                  formData={formData.option4}
                />
              )}
              {activeOption.id === 5 && (
                <Option5
                  handleSubmit={(data) => handleSubmit(data, 5)}
                  handlePrev={handlePrev}
                  formData={formData?.option5}
                />
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BrandKitOption;
