import React from 'react'

const TermsDetail = () => {
    return (
        <div className='desclimer_detail py-[50px]'>
            <div className="container !px-[16px]">
                <div className="row">
                    <div className="col-md-8 mx-auto">
                        <h1 className='md:text-[35px] text-[24px]'>Zunno Terms and Conditions </h1>
                        <p className='text-[#505050] lg:mb-[80px] mb-[40px]'>Last Modified: August 31 , 2024</p>
                        <div className="privacy-wrap">
                            <ul className='list-decimal list-inside'>
                                <li className='text-xl font-semibold mb-3'>Acceptance of Terms</li>
                                <p>By accessing or using our Website, you agree to be bound by these Terms and our Privacy Policy. If you do not agree with these Terms, please do not use our Website.
                                </p>

                                <li className='text-xl font-semibold mb-3'>Changes to Terms</li>
                                <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on our Website. Your continued use of the Website after any such changes constitutes your acceptance of the new Terms.
                                </p>

                                <li className='text-xl font-semibold mb-3'>Use of the Website</li>
                                <ul className='ms-4 !mb-[40px]'>
                                    <li className='text-xl font-semibold mb-3'>3.1 License to Use</li>
                                    <p>We grant you a limited, non-exclusive, non-transferable, and revocable license to use our Website for personal, non-commercial use.
                                    </p>

                                    <li className='text-xl font-semibold mb-3'>3.2 Restrictions</li>
                                    <span>You agree not to:</span>
                                    <ul className='ms-4'>
                                        <li>- Use the Website for any unlawful purpose.</li>
                                        <li>- Attempt to gain unauthorized access to any part of the Website or its related systems or networks.</li>
                                        <li>- Interfere with the proper working of the Website.</li>
                                        <li>- Use any robot, spider, scraper, or other automated means to access the Website for any purpose without our express written permission.</li>
                                    </ul>
                                </ul>

                                <li className='text-xl font-semibold mb-3'>Intellectual Property</li>
                                <ul className='ms-4'>
                                    <li className='text-xl font-semibold mb-3'>4.1 Ownership</li>
                                    <p>All content, including but not limited to text, graphics, logos, and software, on the Website is the property of Zunno or its content suppliers and is protected by intellectual property laws.</p>

                                    <li className='text-xl font-semibold mb-3'>4.2 Trademarks</li>
                                    <p>The trademarks, logos, and service marks displayed on the Website are the property of Zunno or other third parties. You are not permitted to use these marks without our prior written consent</p>
                                </ul>

                                <li className='text-xl font-semibold mb-3'>User Content</li>
                                <ul className='ms-4'>
                                    <li className='text-xl font-semibold mb-3'>5.1 Submissions</li>
                                    <p>If you submit any content to our Website, including but not limited to feedback, suggestions, or other information, you grant us a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media.</p>

                                    <li className='text-xl font-semibold mb-3'>5.2 Responsibility</li>
                                    <p>You are solely responsible for the content you submit, and you agree not to submit any content that is unlawful, offensive, defamatory, or infringes on the rights of any third party.</p>
                                </ul>

                                <li className='text-xl font-semibold mb-3'>Third-Party Links</li>
                                <p>Our Website may contain links to third-party websites. We do not control, endorse, or assume responsibility for any third-party websites or their content. Your use of third-party websites is at your own risk.
                                </p>

                                <li className='text-xl font-semibold mb-3'>Disclaimer of Warranties</li>
                                <p>Our Website is provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not warrant that the Website will be uninterrupted, error-free, or free of viruses or other harmful components.</p>

                                <li className='text-xl font-semibold mb-3'>Limitation of Liability</li>
                                <p>In no event shall Zunno be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to lost profits, data loss, or business interruption, arising out of or in connection with your use of the Website, even if we have been advised of the possibility of such damages.
                                </p>

                                <li className='text-xl font-semibold mb-3'>Indemnification</li>
                                <p>You agree to indemnify, defend, and hold harmless Zunno, its affiliates, and their respective officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees, arising out of or in any way connected with your use of the Website or your violation of these Terms.</p>

                                <li className='text-xl font-semibold mb-3'>Governing Law</li>
                                <p>These Terms and your use of the Website shall be governed by and construed in accordance with the laws, without regard to its conflict of laws principles.</p>

                                <li className='text-xl font-semibold mb-3'>Severability</li>
                                <p>If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall continue in full force and effect.</p>

                                <li className='text-xl font-semibold mb-3'>Entire Agreement</li>
                                <p>These Terms constitute the entire agreement between you and Zunno regarding your use of the Website and supersede all prior and contemporaneous agreements, proposals, or communications, whether written or oral.</p>

                                <p>By using our Website, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsDetail