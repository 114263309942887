import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { auth, signInWithGooglePopup } from '../firebase/firebase.config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SignIn = () => {

    const backgroundImages = [
        '/assets/images/random/0.png',
        '/assets/images/random/1.jpg',
        '/assets/images/random/2.jpg',
        '/assets/images/random/3.png',
        '/assets/images/random/4.jpeg',
    ];

    const [backgroundImage, setBackgroundImage] = useState('');

    useEffect(() => {
        const randomImage = backgroundImages[Math.floor(Math.random() * backgroundImages.length)];
        setBackgroundImage(randomImage);
    }, []);

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const sendLink = async (e) => {
        e.preventDefault();

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            toast.success('Logged in successfully!');
            console.log(user);
            localStorage.setItem('authToken', user.accessToken);
            navigate('/');
        } catch (error) {
            console.error('Error signing in:', error);
            toast.error('Failed to sign in. Please check your credentials and try again.');
        }
    };

    const logGoogleUser = async () => {
        try {
            await signInWithGooglePopup().then(() => {
                navigate('/')
            })
        } catch (error) {
            toast.error(error)
            console.log(error)
        }
    }

    return (
        <div className='sign-in'
            style={{
                backgroundImage: `linear-gradient(rgba(6, 7, 9, 0.5), rgba(6, 7, 9, 0.5)),url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: '100vh'
            }}>
            <div className='container'>
                <div className='row items-center justify-center min-h-screen'>
                    <div className='col-5 d-xl-block d-none'>
                        <div>
                            <Link to='/' className='inline-block'><img src='/assets/images/black_logos.svg' alt='logo' /></Link>
                            <span className='text-white block'>Unleash your Creativity with AI</span>
                        </div>
                    </div>
                    <div className='col-xl-5 col-lg-6 col-md-8'>
                        <div className='text-center mb-4 d-xl-none'>
                            <Link to='/'><img src='/assets/images/black_logos.svg' className='md:w-auto w-[150px] mx-auto' alt='logo' /></Link>
                            <span className='text-white block'>Unleash your Creativity with AI</span>
                        </div>
                        <div className='bg-white rounded-lg md:p-[40px] p-[20px]'>
                            <h1 className='md:text-[30px] text-[26px] mb-4'>Sign In</h1>
                            <form onSubmit={sendLink}>
                                <div className='mb-4'>
                                    <label className='text-sm mb-2'>Email address</label>
                                    <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} required className='bg-[#F1F3F5] w-full rounded-lg outline-none p-2 border-[1px] border-[rgba(0, 0, 0, 0.2)]' />
                                </div>
                                <div className='mb-4'>
                                    <label className='text-sm mb-2'>Password</label>
                                    <div className='relative'>
                                        <input type={showPassword ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} required className='bg-[#F1F3F5] text-sm w-full rounded-lg outline-none p-2 border-[1px] border-[rgba(0, 0, 0, 0.2)]' />
                                        <span onClick={togglePasswordVisibility} className='absolute top-3 right-4 text-[#505050] cursor-pointer'>
                                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                                        </span>
                                    </div>
                                </div>
                                <div className='text-end'>
                                    <button type='submit' className='text-[12px] bg-theme-color text-white py-[10px] px-[20px] rounded-lg'>Continue</button>
                                </div>
                            </form>
                            <p className='text-center mt-4'>Don’t have a Zunno account? <Link to='/sign-up' className='text-theme-color'>Sign up</Link></p>

                            <div className='my-4 flex items-center'>
                                <div className='w-full h-[1px] bg-[#eaeaea]'></div>
                                <span className='block text-center bg-white px-4 text-sm'>Or</span>
                                <div className='w-full h-[1px] bg-[#eaeaea]'></div>
                            </div>

                            <div onClick={logGoogleUser} className='border-[1px] border-[#13131433] cursor-pointer flex items-center gap-2 justify-center rounded-lg py-2 mt-5'>
                                <img src='/assets/images/google.svg' alt='google' className='h-[30px]' />Continue with Google
                            </div>
                            <div className='border-[1px] border-[#13131433] cursor-pointer flex items-center gap-2 justify-center rounded-lg py-2 mt-3'>
                                <img src='/assets/images/facebook.svg' alt='facebook' className='h-[24px]' />Continue with Facebook
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn