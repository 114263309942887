import React from 'react'
import { GoArrowRight } from "react-icons/go";
import InstagramDetails from './InstagramDetails';
import FacebookDetails from './FacebookDetails';
import LinkedinDetails from './LinkedinDetails ';
import YoutubeDetails from './YoutubeDetails';
import PinterestDetails from './PinterestDetails';
import TwitterSection from './TwitterDwtails';
import WhatsappSection from './WhatsappDetails';
import AmazonSection from './AmazonDetails';
import SnapchatSection from './SnapchatDetails';

const PopularDetails = () => {

    return (
        <div>
            {/* <h1 className='md:text-[24px] text-base mb-4'>Instagram</h1> */}
            {/* <div className="flex details gap-3 flex-nowrap overflow-x-auto whitespace-nowrap">
                <a href='/create' className='flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='group'>
                        <div className='relative rounded-[12px] overflow-hidden'>
                            <img src='/assets/images/home/insta.svg' className='w-full' alt='insta' />
                            <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                        </div>
                        <div className='mt-2 text-black'>
                            <p className='mb-0 text-sm font-medium'>Instagram post (Square)</p>
                            <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                        </div>
                    </div>
                </a>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/insta.svg' className='w-full' alt='insta' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Instagram post (Portrait)</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/insta.svg' className='w-full' alt='insta' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Instagram post (Landscape)</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/insta.svg' className='w-full' alt='insta' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Instagram post (Story)</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>
            </div> */}
            <InstagramDetails/>

            {/* <h1 className='md:text-[24px] text-base my-4'>Facebook</h1> */}
            {/* <div className="flex details gap-3 flex-nowrap overflow-x-auto whitespace-nowrap">
                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/facebook.svg' className='w-full' alt='facebook' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Facebook post</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/facebook.svg' className='w-full' alt='facebook' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Facebook Story</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/facebook.svg' className='w-full' alt='facebook' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Facebook Event Cover Photo</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/facebook.svg' className='w-full' alt='facebook' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Facebook Cover</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>
            </div> */}
            <FacebookDetails/>

            {/* <h1 className='md:text-[24px] text-base my-4'>Linkedin</h1> */}
            {/* <div className="flex details gap-3 flex-nowrap overflow-x-auto whitespace-nowrap">
                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/linkedin.svg' className='w-full' alt='linkedin' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Ad Landscape</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/linkedin.svg' className='w-full' alt='linkedin' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Ad Square</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/linkedin.svg' className='w-full' alt='linkedin' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Post</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/linkedin.svg' className='w-full' alt='linkedin' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Cover</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/linkedin.svg' className='w-full' alt='linkedin' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Banner</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>
            </div> */}
            <LinkedinDetails/>

            {/* <h1 className='md:text-[24px] text-base my-4'>Youtube</h1> */}
            {/* <div className="flex details gap-3 flex-nowrap overflow-x-auto whitespace-nowrap">
                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/pin.svg' className='w-full' alt='youtube' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Banner</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/pin1.svg' className='w-full' alt='youtube' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Video Thumbnail</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>
            </div> */}
            <YoutubeDetails/>

            {/* <h1 className='md:text-[24px] text-base my-4'>Pinterest</h1> */}
            {/* <div className="flex details gap-3 flex-nowrap overflow-x-auto whitespace-nowrap">
                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/pin2.svg' className='w-full' alt='pinterest' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Pin</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/pin3.svg' className='w-full' alt='pinterest' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Idea Pin</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>
            </div> */}
            <PinterestDetails/>

            {/* <h1 className='md:text-[24px] text-base my-4'>Twitter</h1> */}
            {/* <div className="flex details gap-3 flex-nowrap overflow-x-auto whitespace-nowrap">
                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/twit.svg' className='w-full' alt='twitter' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Ad</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/twit1.svg' className='w-full' alt='twitter' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Post</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/twit2.svg' className='w-full' alt='twitter' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Profile Header</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/twit3.svg' className='w-full' alt='twitter' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Cover</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>
            </div> */}
            <TwitterSection/>

            {/* <h1 className='md:text-[24px] text-base my-4'>Whatsapp</h1> */}
            {/* <div className="flex details gap-3 flex-nowrap overflow-x-auto whitespace-nowrap">
                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/wp.svg' className='w-full' alt='wp' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Post</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/linkedin.svg' className='w-full' alt='wp' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Wide Post</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/wp1.svg' className='w-full' alt='wp' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Status</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/wp2.svg' className='w-full' alt='wp' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Business Cover</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>
            </div> */}
            <WhatsappSection/>

            {/* <h1 className='md:text-[24px] text-base my-4'>Amazon</h1> */}
            {/* <div className="flex details gap-3 flex-nowrap overflow-x-auto whitespace-nowrap">
                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/amazon.svg' className='w-full' alt='amazon' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Store page header</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/amazon1.svg' className='w-full' alt='amazon' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Banner rectangle</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/amazon2.svg' className='w-full' alt='amazon' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Banner Square</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/amazon3.svg' className='w-full' alt='amazon' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Business Cover</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/amazon4.svg' className='w-full' alt='amazon' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Product Image</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/amazon5.svg' className='w-full' alt='amazon' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>EBC</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>
            </div> */}
            <AmazonSection/>

            {/* <h1 className='md:text-[24px] text-base my-4'>Snapchat</h1> */}
            {/* <div className="flex details gap-3 flex-nowrap overflow-x-auto whitespace-nowrap">
                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/snap.svg' className='w-full' alt='snap' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Post</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/snap1.svg' className='w-full' alt='snap' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Wide Post</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>

                <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                    <div className='relative rounded-[12px] overflow-hidden'>
                        <img src='/assets/images/home/snap2.svg' className='w-full' alt='snap' />
                        <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>Start Generating</div>
                    </div>
                    <div className='mt-2'>
                        <p className='mb-0 text-sm font-medium'>Status</p>
                        <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>1080 x 1080</span>
                    </div>
                </div>
            </div> */}
            <SnapchatSection/>
        </div>
    )
}

export default PopularDetails