import React, { useEffect, useRef, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { IoCloseCircle } from "react-icons/io5";
import Collapse from "react-bootstrap/Collapse";
import { VscEdit } from "react-icons/vsc";
import { RiDeleteBin6Line } from "react-icons/ri";
import { GoQuestion } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { uploadImages } from "../../redux/actions/BrandKit.action";

const Option1 = ({ handleNext, formData }) => {
  const dispatch = useDispatch();
  // const brandKit = useSelector((state) => state.brandKit);
console.log({formData});


  const [localData, setLocalData] = useState(formData);
  const [colorPickers, setColorPickers] = useState([
    { id: Date.now(), type: "primary", color: "#563d7c" },
  ]);
  const [logoFile, setLogoFile] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const inputRefs = useRef({});

  useEffect(() => {
    console.log("Updated localData:", localData);
  }, [localData]);

  useEffect(() => {
   setLocalData(formData)
  }, [formData]);
  console.log(colorPickers);

  

  const handleFormData = (e) => {
    const { name, value } = e.target;
    setLocalData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

 
  
  useEffect(() => {
    
    if (logoFile) {
      handleImageUpload();
    }
  }, [logoFile]); 
  

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
   
    if (file) {
      setLogoFile(file);
      setLogoPreview(URL.createObjectURL(file));
    }
  };
  
  const handleImageUpload = async () => {
    if (logoFile) {
      const imageFormData = new FormData();
      imageFormData.append("image", logoFile);
      const response = await dispatch(uploadImages(imageFormData));
      console.log("response", response);
  
      if (response && response.url) {
        setLocalData({
          ...localData,
          logo: response.url,
        });
      } else {
        console.error("Image upload failed or no URL returned");
      }
    } else {
      console.error("No file to upload");
    }
  };
  
  

  const handleAddNewClick = () => {
    const newColorPicker = {
      id: Date.now(),
      type: colorPickers.length % 2 === 0 ? "primary" : "secondary",
      color: "#563d7c",
    };
    setColorPickers([...colorPickers, newColorPicker]);
  };

  const handleColorChange = (e, id) => {
    const newColor = e.target.value;
    const updatedColorPickers = colorPickers.map((picker) =>
      picker.id === id ? { ...picker, color: newColor } : picker
    );

    setColorPickers(updatedColorPickers);

    setLocalData((prev) => ({
      ...prev,
      colors: updatedColorPickers.map((picker) => ({
        colorCode: picker.color,
        type: picker.type,
      })),
    }));
  };

  const enableEdit = (field) => {
    setEditingField(field);
    setTimeout(() => inputRefs.current[field]?.focus(), 100);
  };

  const deleteField = (field) => {
    setLocalData((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  const renderFontField = (placeholder, fieldName) => (
    <div className="bg-white shadow-sm flex items-center justify-between p-[12px] mb-3 rounded-lg">
      <input
        ref={(el) => (inputRefs.current[fieldName] = el)}
        type="text"
        placeholder={placeholder}
        className="outline-none"
        readOnly={editingField !== fieldName}
        value={localData[fieldName]}
        onChange={handleFormData}
        name={fieldName}
      />
      <div className="flex items-center text-lg gap-3">
        <VscEdit
          onClick={() => enableEdit(fieldName)}
          className="cursor-pointer"
        />
        <RiDeleteBin6Line
          onClick={() => deleteField(fieldName)}
          className="cursor-pointer"
        />
      </div>
    </div>
  );

  const handleNextClick = () => {
    handleNext(localData);
  };

  return (
    <>
      <div className="col-lg-8 border-b pb-4">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="md:text-[28px] mb-3 text-xl">Brand Identity</h1>
            <p className="mb-0">
              Select push and email notifications that you'd like to receive
            </p>
          </div>
          <img src="/assets/images/brand-kit/Brand.svg" className="w-[250px]" />
        </div>
      </div>
      <div className="col-lg-8">
        <label className="my-[16px] font-semibold">Brand Name</label>
        <input
          type="text"
          placeholder="Enter brand name"
          name="name"
          defaultValue={localData.name}
          onChange={handleFormData}
          className="w-full border rounded-lg px-3 py-2"
        />
        <span className="text-[#737373] text-[12px] py-1 rounded">
          Include the company or product name in the creative.
        </span>
      </div>

      <div className="col-lg-8">
        <div>
          <label className="my-[16px] font-semibold">Brand Colors</label>
          <div className="flex items-center rounded-lg border-[1px] border-[#00000033] overflow-hidden gap-4 py-[40px] px-[30px]">
            {colorPickers.map((picker) => (
              <div key={picker.id} className="relative group">
                <input
                  type="color"
                  id={`colorPicker-${picker.id}`}
                  defaultValue={picker.color}
                  title="Choose your color"
                  onChange={(e) => handleColorChange(e, picker.id)}
                  className="!w-[70px] !h-[70px] !rounded-full cursor-pointer group-hover:scale-[0.9]"
                />
                <p className="mb-0 text-sm mt-1 text-center">{picker.color}</p>
                <div
                  className="opacity-0 group-hover:!opacity-100 duration-300 cursor-pointer"
                  onClick={() => {}}
                >
                  <IoCloseCircle className="absolute -top-2 -right-2 text-xl" />
                </div>
              </div>
            ))}
            <div>
              <div
                onClick={handleAddNewClick}
                className="w-[70px] h-[70px] flex items-center justify-center bg-white border-[1px] border-[#00000033] rounded-full"
              >
                <AiOutlinePlus className="text-3xl" />
              </div>
              <p className="mb-0 text-sm mt-1 text-center">Add New</p>
            </div>
          </div>
          <span className="text-[#737373] text-[12px] py-1 rounded">
            Allow users to input their primary and secondary colors, or provide
            a color palette.
          </span>
        </div>
      </div>

      <div className="col-lg-8">
        <div>
          <label className="my-[16px] font-semibold">Logo</label>
          <div
            id="example-collapse-text"
            className="rounded-lg border-[1px] border-[#00000033]"
          >
            <label className="w-full py-[40px]">
              <input
                type="file"
                className="d-none"
                onChange={handleFileChange}
              />
              <div className="text-center">
                {logoPreview ? (
                  <img
                    src={logoPreview}
                    alt="Logo Preview"
                    className="w-[100px] mx-auto mb-2"
                  />
                ) : (
                  <img
                    src="/assets/images/home/upload.svg"
                    alt="upload"
                    className="w-[30px] mx-auto mb-2"
                  />
                )}
                <div className="md:text-lg font-semibold mb-2 mt-2 block">
                  Drag and drop or click here to upload
                </div>
                <p className="text-sm">
                  You can also{" "}
                  <span className="underline cursor-pointer">
                    browse from your computer
                  </span>
                </p>
              </div>
            </label>
          </div>
          <span className="text-[#737373] text-[12px] py-1 rounded">
            Option to upload the brand’s logo to incorporate into the designs.
          </span>
        </div>
      </div>

      <div className="col-lg-8">
        <div>
          <label className="my-[16px] font-semibold">Font Style</label>

          <div
            id="example-collapse-text"
            className="rounded-lg border-[1px] border-[#00000033] md:px-[30px] px-[16px] py-3"
          >
            {renderFontField("Title", "titleFont")}
            {renderFontField("Subtitle", "subTitleFont")}
            {renderFontField("Heading", "headingFont")}
            {renderFontField("Font Subheading", "subHeadingFont")}
            {renderFontField("Body", "bodyFont")}
          </div>
          <span className="text-[#737373] text-[12px] py-1 rounded">
            Ask for the preferred font or provide a selection of fonts aligned
            with the brand’s style (modern, classic, playful, etc.).
          </span>
        </div>
      </div>

      <div className="col-lg-8">
        <label className="my-[16px] font-semibold">Tagline</label>
        <input
          type="text"
          placeholder="Enter tagLine"
          name="tagLine"
          value={localData.tagLine}
          onChange={handleFormData}
          className="w-full border rounded-lg px-3 py-2"
        />
        <span className="text-[#737373] text-[12px] py-1 rounded">
          Include a company slogan or tagline that can be used in the creatives.
        </span>
      </div>

      <div className="col-lg-8 text-end mt-5">
        <a
          href="#"
          onClick={handleNextClick}
          className="bg-theme-color text-white px-4 rounded-pill py-[5px]"
        >
          Next
        </a>
      </div>
    </>
  );
};

export default Option1;
