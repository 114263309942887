import React, { useState } from 'react'
import MonthData from './MonthData';
import AnnuallyData from './AnnuallyData';

const PricePlans = () => {

    const [isMonthly, setIsMonthly] = useState(true);

    const handleMonthlyClick = () => {
        setIsMonthly(true);
    };

    const handleAnnuallyClick = () => {
        setIsMonthly(false);
    };

    return (
        <div className='container-fluid md:!px-[60px] !px-[16px]'>
            <h1 className='text-center md:text-[35px] text-[24px] mb-3'>Find the right plan for your site</h1>
            <p className='text-center text-base text-[rgba(75, 85, 99, 1)]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>

            <div>
                <label className='flex justify-center'>
                    <input type='checkbox' className='d-none switch' onChange={() => { }} checked={!isMonthly} />
                    <div className='flex items-center rounded-pill p-1 switch_tab bg-light-purple cursor-pointer'>
                        <div className='relative'>
                            <div className={`w-1/2 h-full rounded-pill bg-theme-color layer duration-500 absolute ${isMonthly ? 'left-0' : 'left-1/2'}`}></div>
                            <span onClick={handleMonthlyClick} className={`px-4 py-2 inline-block w-1/2 relative z-[2px] ${isMonthly ? 'text-white' : 'text-gray-500'}`}>
                                Monthly
                            </span>
                            <span onClick={handleAnnuallyClick} className={`px-4 py-2 inline-block w-1/2 relative z-[2px] ${isMonthly ? 'text-gray-500' : 'text-white'}`}>
                                Annually
                            </span>
                        </div>
                    </div>
                </label>
                <p className='text-center mt-2'>Get Upto ~ 20% off with annual subscription</p>

                <div className="mt-5">
                    {isMonthly ? <MonthData text='text-4xl' padding='md:p-[40px] p-[20px]'/> : <AnnuallyData text='text-4xl' padding='md:p-[40px] p-[20px]'/>}
                </div>
            </div>

            <p className='text-center mt-3'>Local taxes (VAT, GST, etc.) will be charged in addition to the prices mentioned.</p>
        </div>
    )
}

export default PricePlans