import React from 'react';

const Tooltip = ({ text, position = 'right' }) => {
  return (
    <div
      className={`absolute tooltips bg-[#616161] z-20 text-white text-xs py-2 px-3 rounded-lg 
      ${position === 'right' ? 'left-[120%] ml-2 top-1/2 transform -translate-y-1/2' : ''} 
      whitespace-nowrap`}
    >
      {text}
    </div>
  );
};

export default Tooltip;
