import React from 'react'
import { Modal } from 'react-bootstrap'

const NotificationModal = ({ notifications, notificationToggle }) => {
    return (
        <div>
            <Modal centered show={notifications} onHide={notificationToggle} className='backdrop-blur-md'>
                <Modal.Header closeButton className='border-0'></Modal.Header>
                <Modal.Body className='pb-5 pt-4'>
                    <div>
                        <dotlottie-player src="https://lottie.host/dc61a84d-6147-4ee5-912a-29f8c50c9fc6/s4QEH9juvN.json" background="transparent" speed="1" style={{ height: '200px', margin: 'auto' }} loop autoplay></dotlottie-player>
                        <div className="text-center">
                            <p className='font-medium text-lg'>No Notification Yet</p>
                            <p className='px-4 text-black/50 text-sm'>You have no notification right now. Come back later</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default NotificationModal