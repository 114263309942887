import React from 'react'
import { RiDeleteBin6Line } from "react-icons/ri";

const ActiveSection = () => {
    return (
        <div className='col-lg-10 py-[50px]'>
            <div className='flex items-center justify-between'>
                <div className='text-left'>
                    <h1 className='md:text-[28px] mb-2 text-xl'>Your Active Sessions</h1>
                    <p className='mb-0'>These are your active login sessions. If you don’t recognize a session, you can terminate it here.</p>
                </div>
                <img src='/assets/images/account/img2.svg' className='w-[250px]' />
            </div>
            <div className="pt-5 border-t border-black/20">
                {/* <a href="#active" className='!underline text-theme-color'>Click here to view your recent teammate activity.</a> */}

                <table class="table text-sm">
                    <thead>
                        <tr>
                            <th className='!text-black/50 !py-3' scope="col">Login Time</th>
                            <th className='!text-black/50 !py-3' scope="col">IP address</th>
                            <th className='!text-black/50 !py-3' scope="col">Browser</th>
                            <th className='!text-black/50 !py-3' scope="col">Operating system</th>
                            <th className='!text-black/50 !py-3' scope="col">Location</th>
                            <th style={{ minWidth: '100px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='group'>
                            <td className='!py-3'>Oct 3, 2024 at 10:16AM</td>
                            <td className='!py-3'>27.59.86.56</td>
                            <td className='!py-3'>Chrome</td>
                            <td className='!py-3'>Windows 10</td>
                            <td className='!py-3'>Vadodara, IN</td>
                            <td className='!py-3'><RiDeleteBin6Line className='text-xl text-danger' /></td>
                        </tr>
                        <tr className='group'>
                            <td className='!py-3'>Oct 5, 2024 at 10:16AM</td>
                            <td className='!py-3'>27.59.86.56</td>
                            <td className='!py-3'>Chrome</td>
                            <td className='!py-3'>Windows 10</td>
                            <td className='!py-3'>Vadodara, IN</td>
                            <td className='!py-3'><RiDeleteBin6Line className='text-xl text-danger' /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ActiveSection