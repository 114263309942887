import React, { useState, useEffect } from "react";
import { getCreation } from "../../api/creation.req";

const Creation = ({ showMenu, imageData }) => {
  const [image, setImage] = useState();
  const [creationData, setCreationData] = useState([]);
  const [showFullImage, setShowFullImage] = useState(false);

  useEffect(() => {
    setImage(imageData?.finalOutputUrl);
    setShowFullImage(!!imageData); // Show full image if imageData is present
  }, [imageData]);

  const downloadImage = async (imageUrl, fileName = "generated-image") => {
    try {
      const response = await fetch(imageUrl, { mode: "cors" });
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download image:", error);
    }
  };

  const fetchCreations = async () => {
    try {
      const accessToken = localStorage.getItem("authToken");
      const { data } = await getCreation({
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        },
      });
      setCreationData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCreations();
  }, [image]);

  return (
    <div>
      {showFullImage ? (
        <div className="relative h-[60vh] flex justify-center items-center">
          <img src={image} alt="Generated" className="h-[80vh]" />
          <button
            onClick={() => downloadImage(image, "generated-image")}
            className="absolute top-2 right-2 bg-theme-color text-white px-3 py-1 rounded-md text-sm cursor-pointer"
          >
            Download
          </button>
          <button
            onClick={() => setShowFullImage(false)}
            className="absolute top-2 left-2 bg-gray-600 text-white px-3 py-1 rounded-md text-sm cursor-pointer"
          >
            ✕
          </button>
        </div>
      ) : (
        <div className={`${showMenu ? "lg:columns-4" : "lg:columns-4"} columns-2`}>
          {creationData.map((item, index) => (
            <div key={index} className="relative overflow-hidden mb-3 group">
              <img
                src={item.finalOutputUrl}
                alt="creation"
                className="rounded-lg cursor-pointer"
                onClick={() => {
                  setImage(item.finalOutputUrl);
                  setShowFullImage(true);
                }}
              />
              <div className="w-full opacity-0 invisible group-hover:!opacity-100 group-hover:!visible duration-300 p-3 text-sm text-white h-full bg-black/60 flex flex-col justify-between absolute top-0 left-0 rounded-lg">
                <div>
                  <p className="font-bold mb-1 sm:text-base text-[12px]">
                    {item.creatorName}
                  </p>
                  <p className="sm:text-base sm:leading-normal text-[10px] leading-[14px]">
                    {item?.productPrompt?.split(" ").slice(0, 50).join(" ")}
                    {item?.productPrompt?.split(" ").length > 50 ? "..." : ""}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => downloadImage(item.finalOutputUrl, "creation-image")}
                    className="bg-white inline-block text-sm rounded-pill px-2 text-black"
                  >
                    Download this image
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Creation;
