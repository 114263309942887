import React from 'react'
import CreateOption from './CreateOption'
import Header1 from '../../layout/header/Header1'

const Index = () => {
  return (
    <>
        <Header1/>
        <CreateOption/>
    </>
  )
}

export default Index

