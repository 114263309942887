import axios from "axios";
import { BASEURL } from "../../baseUrl";
import { toast } from "react-toastify";

export const CREATE_CREATION = "CREATE_CREATION";
export const GET_CREATION = "GET_CREATION";

const createCreation = (payload) => ({
  type: createCreation,
  payload: payload,
});
const getCreation = (payload) => ({
  type: getCreation,
  payload: payload,
});

export const addCreation = (formdata) => {
  return async (dispatch) => {
    try {
      await axios
        .post(`${BASEURL}/api/creation/create`, formdata)
        .then((res) => {
          dispatch(createCreation(res.data));
          toast.success("Form submited successfully");
        });
    } catch (error) {}
  };
};

export const fetchCreation = () => {
  return async (dispatch) => {
    try {
      const accessToken = localStorage.getItem("authToken");
      const apikey = process.env.REACT_APP_API_KEY;
      const response = await axios
        .get(`${BASEURL}/api/creation/read`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "x-api-key": apikey,
          },
        })
        .then((res) => {
          dispatch(getCreation(res.data));
        });
    } catch (error) {
      dispatch(getCreation(error?.response?.data));
      console.log("Error in fetching creation", error?.response?.data || error);
      return null;
    }
  };
};
