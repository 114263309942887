import React from 'react';
import { Link } from 'react-router-dom';

const Legal = () => {
    return (
        <div style={{ height: 'calc(100vh - 40px)' }} className='flex flex-col justify-between col-lg-10 py-[50px]'>
            {/* Content Section */}
            <div>
                <div className="flex items-center justify-between">
                    <div>
                        <h1 className='md:text-[28px] mb-2 text-xl'>Legal Notices</h1>
                        <p className='mb-5'>Info about you and your preferences across Google services</p>
                    </div>
                    <img src='/assets/images/account/img3.svg' className='w-[250px]' />
                </div>
                <div className='pt-5 border-t border-black/20'>
                    <Link to='/' className='block text-theme-color hover:!underline mb-3'>Terms of Use</Link>
                    <Link to='/' className='block text-theme-color hover:!underline mb-3'>Privacy Policy</Link>
                    <Link to='/' className='block text-theme-color hover:!underline mb-3'>Cookie Preference</Link>
                    <Link to='/' className='block text-theme-color hover:!underline mb-3'>Patent & Trademark</Link>
                    <Link to='/' className='block text-theme-color hover:!underline mb-3'>Do not sell or share my personal information</Link>
                </div>
            </div>

            {/* Footer Section */}
            <div className='mt-auto text-center'>
                <p className='text-sm text-black/50'>Copyright ©2024 Zunno. All rights reserved. Various trademarks held by their respective owners.</p>
            </div>
        </div>
    );
};

export default Legal;
