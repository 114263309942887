import React, { useEffect, useRef, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useLocation } from 'react-router-dom';
import { HiOutlineBars3 } from "react-icons/hi2";
import { PiLightbulbFilamentLight } from "react-icons/pi";
import { GoLightBulb } from "react-icons/go";
import Form from 'react-bootstrap/Form';

const SideBar = ({ sideMenu, menuToggle, setSideMenu }) => {

    const sidebarRef = useRef(null);
    const location = useLocation()
    const [checkMenu, setCheckMenu] = useState(
        JSON.parse(localStorage.getItem('checkMenu')) || false
    );
    console.log('checkMenu', checkMenu);

    useEffect(() => {
        localStorage.setItem('checkMenu', JSON.stringify(checkMenu));
    }, [checkMenu]);

    const handleClickOutside = (e) => {
        if (sidebarRef.current && !e.target.closest('a', 'div')) {
            setSideMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Offcanvas show={sideMenu} onHide={menuToggle} className='!w-[300px] sidebar'>
            <Offcanvas.Header className='!py-2'>
                <div className='w-[40px] me-3 h-[40px] group rounded-full flex justify-center items-center hover:bg-light-purple cursor-pointer duration-300'><HiOutlineBars3 className='cursor-pointer text-2xl' onClick={menuToggle} /></div>
                <Offcanvas.Title className='flex items-center gap-1'><a href="/" className='me-0'>
                    <img src='/assets/images/logos.svg' className='w-[150px]' alt='logo' /></a>
                    <div className='text-theme-color bg-light-purple py-[2px] px-[8px] font-[600] text-[12px] rounded-pill' style={{ letterSpacing: '1px' }}>BETA</div>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='flex flex-col justify-between pt-0'>
                <div>
                    {/* <div className={`text-black flex items-center gap-2 h-[48px] mb-3 px-[12px] border-b pb-3`}>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={checkMenu}
                            onChange={() => {
                                setCheckMenu(!checkMenu); // Toggle checkMenu state
                                setSideMenu(!checkMenu); // Toggle sideMenu visibility
                            }}
                        />
                        <div className='flex items-center'>
                            Show side bar
                        </div>
                    </div> */}
                    <Link to={"/"} className={`${location.pathname === '/' ? 'active' : ''} text-black hover:bg-light-purple flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                        <img src='/assets/images/icon1.svg' className='grayscale' alt='home' /> Home
                    </Link>
                    {/* <a href='/my-project' className={`${location.pathname === '#my-project' ? 'active' : ''} text-black hover:bg-light-purple flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                        <img src='/assets/images/icon2.svg' alt='folder' /> My Projects
                    </a> */}
                    <Link to={"/brands-kits"} href='/brands-kits' className={`${location.pathname === '/brands-kits' ? 'active' : ''} text-black hover:bg-light-purple flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                        <img src='/assets/images/brand-kit.svg' alt='brand' /> Brand Kits
                    </Link>
                    {/* <a href='#zunno' className={`${location.pathname === '#zunno' ? 'active' : ''} text-black hover:bg-light-purple flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                        <img src='/assets/images/studio.svg' alt='studio' className='w-[24px]' /> Zunno Studio
                    </a> */}
                    {/* <a href='/plans-pricing' className={`${location.pathname === '/plans-pricing' ? 'active' : ''} text-black hover:bg-light-purple flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                        <img src='/assets/images/icon4.svg' alt='price' /> Pricing Plans
                    </a> */}
                    <Link to={"https://zunnoweb.canny.io/feature-requests"} href='https://zunnoweb.canny.io/feature-requests' target='_blank' className={`${location.pathname === '#request' ? 'active' : ''} text-black hover:bg-light-purple flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                        <GoLightBulb className='text-[24px]' /> Feature Request
                    </Link>
                </div>
                <div className='border-t pt-1'>
                    {/* <a href='#faqs' className='text-black hover:bg-light-purple flex items-center gap-3 pb-3'><img src='/assets/images/faq.png' className='w-[24px]' alt='faqs' />FAQs</a> */}
                    {/* <a href='#help' className={`${location.pathname === '#help' ? 'active' : ''} text-black hover:bg-light-purple flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                        {location.pathname==='#help'?<img src='/assets/images/help1.png' className='w-[24px]' alt='help' />:<img src='/assets/images/help.png' className='w-[24px]' alt='help' />}Help Center
                    </a>
                    <a href='#setting' className={`${location.pathname === '#setting' ? 'active' : ''} text-black hover:bg-light-purple flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                        {location.pathname === '#setting'?<img src='/assets/images/setting1.png' className='w-[24px]' alt='setting' />:<img src='/assets/images/setting.png' className='w-[24px]' alt='setting' />}Setting
                    </a> */}
                    {/* <a href='#help' className={`${location.pathname === '#help' ? 'active' : ''} text-black hover:bg-light-purple flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                        <img src='/assets/images/help.png' className='w-[24px]' alt='help' />Help Center
                    </a> */}
                    <a href='/setting' className={`${location.pathname === '#setting' ? 'active' : ''} text-black hover:bg-light-purple flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                        <img src='/assets/images/icon6.svg' className='w-[24px]' alt='setting' />Setting
                    </a>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default SideBar