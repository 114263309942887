import React, { useEffect, useState } from 'react';
import { CiSearch } from "react-icons/ci";

const HomeBanner = () => {

    const logo = [
        { id: 1, img: '/assets/images/home/banner1.png' },
        { id: 2, img: '/assets/images/home/banner2.png' },
        { id: 3, img: '/assets/images/home/banner3.png' },
        { id: 4, img: '/assets/images/home/banner4.png' },
        { id: 5, img: '/assets/images/home/banner5.png' },
        { id: 6, img: '/assets/images/home/banner6.png' },
        { id: 7, img: '/assets/images/home/banner7.png' },
        { id: 8, img: '/assets/images/home/banner8.png' },
        { id: 1, img: '/assets/images/home/banner1.png' },
        { id: 2, img: '/assets/images/home/banner2.png' },
        { id: 3, img: '/assets/images/home/banner3.png' },
        { id: 4, img: '/assets/images/home/banner4.png' },
        { id: 5, img: '/assets/images/home/banner5.png' },
        { id: 6, img: '/assets/images/home/banner6.png' },
        { id: 7, img: '/assets/images/home/banner7.png' },
        { id: 8, img: '/assets/images/home/banner8.png' },
    ];

    const logo1 = [
        { id: 5, img: '/assets/images/home/banner5.png' },
        { id: 6, img: '/assets/images/home/banner6.png' },
        { id: 7, img: '/assets/images/home/banner7.png' },
        { id: 8, img: '/assets/images/home/banner8.png' },
        { id: 1, img: '/assets/images/home/banner1.png' },
        { id: 2, img: '/assets/images/home/banner2.png' },
        { id: 3, img: '/assets/images/home/banner3.png' },
        { id: 4, img: '/assets/images/home/banner4.png' },
        { id: 5, img: '/assets/images/home/banner5.png' },
        { id: 6, img: '/assets/images/home/banner6.png' },
        { id: 7, img: '/assets/images/home/banner7.png' },
        { id: 8, img: '/assets/images/home/banner8.png' },
        { id: 1, img: '/assets/images/home/banner1.png' },
        { id: 2, img: '/assets/images/home/banner2.png' },
        { id: 3, img: '/assets/images/home/banner3.png' },
        { id: 4, img: '/assets/images/home/banner4.png' },
    ];

    const logo2 = [
        { id: 6, img: '/assets/images/home/banner10.png' },
        { id: 5, img: '/assets/images/home/banner9.png' },
        { id: 6, img: '/assets/images/home/banner6.png' },
        { id: 8, img: '/assets/images/home/banner12.png' },
        { id: 8, img: '/assets/images/home/banner8.png' },
        { id: 5, img: '/assets/images/home/banner5.png' },
        { id: 7, img: '/assets/images/home/banner7.png' },
        { id: 7, img: '/assets/images/home/banner11.png' },
        { id: 6, img: '/assets/images/home/banner10.png' },
        { id: 5, img: '/assets/images/home/banner9.png' },
        { id: 6, img: '/assets/images/home/banner6.png' },
        { id: 8, img: '/assets/images/home/banner12.png' },
        { id: 8, img: '/assets/images/home/banner8.png' },
        { id: 5, img: '/assets/images/home/banner5.png' },
        { id: 7, img: '/assets/images/home/banner7.png' },
        { id: 7, img: '/assets/images/home/banner11.png' },
    ];

    const texts = [
        'A serene forest path at dawn...',
        'A bustling cityscape at night...',
        'A tranquil beach during sunset...',
        'A snowy peak under a clear sky...',
    ];

    const [currentTextIndex, setCurrentTextIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 2000);

        return () => clearInterval(intervalId);
    }, [texts.length]);

    return (
        <>
            <div className='relative home_banner'>
                {/* <div class="slider">
                    <div class="slide-track">
                        {
                            logo.map((i) => {
                                return (
                                    <div class="slide" key={i.id}>
                                        <img src={i.img} alt="banner_img" />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div class="slider">
                    <div class="slide-track slide-track1">
                        {
                            logo1.map((i) => {
                                return (
                                    <div class="slide" key={i.id}>
                                        <img src={i.img} alt="banner_img" />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div class="slider">
                    <div class="slide-track">
                        {
                            logo2.map((i) => {
                                return (
                                    <div class="slide" key={i.id}>
                                        <img src={i.img} alt="banner_img" />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div> */}
                <div className='bg-black/40 w-full h-full absolute top-0 left-0 z-10'></div>

                <div className='px-[16px] absolute w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10'>
                    <div className='col-xl-7 col-lg-8 col-12 rounded-[20px] mx-auto p-4'>
                        <h1 className='text-center md:text-[50px] text-[24px] text-white mb-5'>What will you craft today? </h1>
                        <div className='col-md-6 col-sm-7 bg-white mx-auto flex items-center justify-between border-[1px] border-[#00000033] py-2 px-3 my-4 rounded-pill'>
                            <input type='text' value={texts[currentTextIndex]} className='w-full outline-none texts-gradient text-sm' placeholder='Search your Content' />
                            <CiSearch />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container mt-5 !px-[80px]'>
                {/* <div className='row row-cols-sm-6 row-cols-3 gy-4'>
                    <div className='col text-center'>
                        <div className='w-[60px] h-[60px] mx-auto bg-light-purple rounded-full flex items-center justify-center'>
                            <img src='/assets/images/home/icon1.svg' className='w-[35px]' />
                        </div>
                        <p className='mb-0 text-[12px] mt-2'>Social Media</p>
                    </div>
                    <div className='col text-center'>
                        <div className='w-[60px] h-[60px] mx-auto bg-light-purple rounded-full flex items-center justify-center'>
                            <img src='/assets/images/home/icon2.svg' className='w-[35px]' />
                        </div>
                        <p className='mb-0 text-[12px] mt-2'>App store</p>
                    </div>
                    <div className='col text-center'>
                        <div className='w-[60px] h-[60px] mx-auto bg-light-purple rounded-full flex items-center justify-center'>
                            <img src='/assets/images/home/icon3.svg' className='w-[35px]' />
                        </div>
                        <p className='mb-0 text-[12px] mt-2'>Product Photo</p>
                    </div>
                    <div className='col text-center'>
                        <div className='w-[60px] h-[60px] mx-auto bg-light-purple rounded-full flex items-center justify-center'>
                            <img src='/assets/images/home/icon4.svg' className='w-[35px]' />
                        </div>
                        <p className='mb-0 text-[12px] mt-2'>Landing Pages</p>
                    </div>
                    <div className='col text-center'>
                        <div className='w-[60px] h-[60px] mx-auto bg-light-purple rounded-full flex items-center justify-center'>
                            <img src='/assets/images/home/icon5.svg' className='w-[35px]' />
                        </div>
                        <p className='mb-0 text-[12px] mt-2'>Pitch Deck</p>
                    </div>
                    <div className='col text-center'>
                        <div className='w-[60px] h-[60px] mx-auto bg-light-purple rounded-full flex items-center justify-center'>
                            <img src='/assets/images/home/icon6.svg' className='w-[35px]' />
                        </div>
                        <p className='mb-0 text-[12px] mt-2'>Custom Size</p>
                    </div>
                </div> */}

                <div className='row row-cols-sm-5 row-cols-3 gy-4'>
                    <div className='col text-center'>
                        <img src='/assets/images/home/icons1.jpeg' className='w-[160px] h-[160px] mx-auto rounded-[12px]' alt='insta' />
                        <p className='mb-0 text-[14px] mt-2'>Social Media</p>
                    </div>
                    <div className='col text-center'>
                        <img src='/assets/images/home/icons7.jpeg' className='w-[160px] h-[160px] object-cover mx-auto rounded-[12px]' alt='insta' />
                        <p className='mb-0 text-[14px] mt-2'>App store</p>
                    </div>
                    <div className='col text-center'>
                        <img src='/assets/images/home/icons6.jpeg' className='w-[160px] h-[160px] mx-auto rounded-[12px]' alt='insta' />
                        <p className='mb-0 text-[14px] mt-2'>Product Photo</p>
                    </div>
                    <div className='col text-center'>
                        <img src='/assets/images/home/icons4.jpeg' className='w-[160px] h-[160px] mx-auto rounded-[12px]' alt='insta' />
                        <p className='mb-0 text-[14px] mt-2'>Landing Pages</p>
                    </div>
                    <div className='col text-center'>
                        <img src='/assets/images/home/icons5.jpeg' className='w-[160px] h-[160px] mx-auto rounded-[12px]' alt='insta' />
                        <p className='mb-0 text-[14px] mt-2'>Pitch Deck</p>
                    </div>
                    {/* <div className='col text-center'>
                        <div className='bg-light-purple rounded-[12px] h-[180px] flex items-center justify-center'>
                            <img src='/assets/images/home/icon6.svg' className='w-[50px] rounded-[12px]' alt='insta' />
                        </div>
                        <p className='mb-0 text-[14px] mt-2'>Custom Size</p>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default HomeBanner;
