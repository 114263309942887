import React, { useEffect, useState } from 'react'
import { CiSquarePlus } from 'react-icons/ci'
import { IoIosSearch } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getBrandKit } from '../../redux/actions/BrandKit.action'
import BrandKitData from './BrandKitData'

const BrandKitHome = () => {

    const dispatch = useDispatch()
    const getKit = useSelector((state) => state.brandKit)
    console.log('getKit', getKit);
    const getKitId = useSelector((state) => state.brandKit.brandKitId);
    console.log('Stored Brand Kit ID:', getKitId);
    const brandKits = useSelector((state) => state.brandKit.getBrandKits);
    console.log("Brand Kits Dataaaaaaa:", brandKits);

    useEffect(() => {
        dispatch(getBrandKit(getKitId))
    }, [])

    return (
        <div className='flex'>
            <div className='bg-[#F9FAFB] border-r-[1px] border-r-[#00000033] fixed min-h-[100vh] w-[300px] px-3'>
                <div>
                    <p className='text-xl px-[12px] flex items-center h-[48px] my-1 mb-0 font-semibold'>Home</p>
                    <a
                        href='#path'
                        className={`text-black bg-light-theme flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}
                    >
                        <img src='/assets/images/icon1.svg' alt='brand' className='w-[24px] grayscale' /> Brand Kits
                    </a>
                </div>
            </div>

            <section className='py-[50px] ml-[300px] flex-grow'>
                <div className='container'>
                    <div className='row gy-4 justify-center'>
                        <div className="col-lg-11">

                            <div className="relative">
                                <img src="/assets/images/brand-kit/kitbanner.png" alt="kit-banner" />
                                <h1 className='md:text-[32px] text-[24px] text-white absolute top-[30px] left-[30px]'>Get started with Zunno Brand Kits</h1>
                            </div>

                            <div className="flex mt-5 items-center justify-between">
                                <div className='flex items-center gap-2 border-[1px] border-black/20 col-md-4 rounded-lg p-[12px]'>
                                    <IoIosSearch className='text-xl text-black/50' />
                                    <input type='search' placeholder='Search for Brand Kit' className='w-full border-0 outline-none' />
                                </div>
                                <Link to='/brands-kits/brand-option' className='cursor-pointer inline-flex items-center gap-2 bg-theme-color text-white px-[12px] py-[6px] rounded-full border-[1px] border-theme-color text-sm'><CiSquarePlus className='text-xl' />Add New</Link>
                            </div>

                            <div className="row mt-5">
                                {/* <BrandKitData/> */}
                                {brandKits.data?.map((data)=>{
                                    return(
                                        <div className='col-4'>
                                    <Link to='/brands-kits/brand-option'>
                                        <div className="h-[260px] hover:shadow-md duration-300 rounded-2xl p-6 border-[1px] border-[#f1f1f1] flex flex-col justify-between">
                                            <div>
                                                <img src={data?.logo} className='w-[60px]' alt={data?.logo} />
                                            </div>
                                            <div className='text-sm'>
                                                <span class="font-semibold text-black">{data.name}</span>
                                                <p class="pt-1 mb-0">
                                                    <span class="text-black/50">Learn how to use Workflows, Fin AI Agent, and Fin AI Copilot to empower your team.</span>
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                    )
                                })
                                }

                                {/* <div className="col-4">
                                    <Link to='/brands-kits/brand-option'>
                                        <div className="h-[260px] hover:shadow-md duration-300 rounded-2xl p-6 border-[1px] border-[#f1f1f1] flex flex-col justify-between">
                                            <div>
                                                <img src="/assets/images/subscription.svg" className='w-[60px]' alt="" />
                                            </div>
                                            <div className='text-sm'>
                                                <span class="font-semibold text-black">Improve your customer experience</span>
                                                <p class="pt-1 mb-0">
                                                    <span class="text-black/50">Learn to identify and track key metrics, to improve your customer experience.</span>
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BrandKitHome