import React from 'react'
import { FiArrowUpRight } from "react-icons/fi";

const LatestBlog = () => {
    return (
        <section>
            <div className='pt-[50px]'>
                <div className='container-fluid md:!px-[60px] !px-[16px]'>
                    <h2 className='text-[24px] mb-4'>Read the latest Blogs</h2>
                    <div className='row gy-4'>
                        <div className='col-lg-5 order-lg-1 order-1'>
                            <div className='overflow-hidden h-full'>
                                <img src='/assets/images/zunno-blog/blog2.png' className='w-full h-full hover:scale-110 duration-300 object-cover' />
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-6 order-lg-2 order-3'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='overflow-hidden'>
                                        <img src='/assets/images/zunno-blog/blog3.png' className='w-full h-full hover:scale-110 duration-300 object-cover' />
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mt-lg-0 mt-3'>
                                        <span className='text-[#2C3A9B] text-sm font-[600]'>Phoenix Baker • 1 Jan 2023</span>
                                        <div className='flex items-start my-2 justify-between'>
                                            <h1 className='md:text-[24px] text-xl mb-0'>Reading books.........</h1>
                                            <FiArrowUpRight className='text-2xl' />
                                        </div>
                                        <p className='text-[#667085]'>Lorem Ipsum is simply dummy text of the printing and typesetting!</p>
                                        <span className='bg-[#F9F5FF] text-sm font-[500] text-[#6941C6] rounded-pill px-3 py-1'>Read More</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 order-lg-3 order-2'>
                            <div>
                                <span className='text-[#2C3A9B] text-sm font-[600]'>Olivia Rhye • 1 Jan 2023</span>
                                <div className='flex items-start my-2 justify-between'>
                                    <h1 className='md:text-[24px] text-xl mb-0'>Zunno continued commitment to creators in generative AI</h1>
                                    <FiArrowUpRight className='text-3xl' />
                                </div>
                                <p className='text-[#667085]'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy!</p>
                                <span className='bg-[#F9F5FF] text-sm font-[500] text-[#6941C6] rounded-pill px-3 py-1'>Read More</span>
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-6 order-lg-4 order-4'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='overflow-hidden'>
                                        <img src='/assets/images/zunno-blog/blog4.png' className='w-full h-full hover:scale-110 duration-300 object-cover' />
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mt-lg-0 mt-3'>
                                        <span className='text-[#2C3A9B] text-sm font-[600]'>Lana Steiner • 1 Jan 2023</span>
                                        <div className='flex items-start my-2 justify-between'>
                                            <h1 className='md:text-[24px] text-xl mb-0'>Generative AI</h1>
                                            <FiArrowUpRight className='text-2xl' />
                                        </div>
                                        <p className='text-[#667085]'>Lorem Ipsum is simply dummy text of the printing and typesetting!</p>
                                        <span className='bg-[#F9F5FF] text-sm font-[500] text-[#6941C6] rounded-pill px-3 py-1'>Read More</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LatestBlog