import React, { useState } from 'react'
import CoinPrice from '../../common/CoinPrice';

const Subscription = ({ space, text, border }) => {

    const [show, setShow] = useState(false);
    const showToggle = () => { setShow(!show) }

    return (
        <div className={space}>
            <h1 className={`${border} md:text-[28px] mb-0 text-xl`}>{text}</h1>
            <div className='text-center mt-5'>
                <img src='/assets/images/subscription.svg' alt='studio' className='w-[60px] mx-auto' />
                <h1 className='text-[20px] mt-4 font-[500] text-[#333333]/60'>No current subscription</h1>
                <h1 className='text-[20px] font-[500] text-[#333333]/60'>Start with zunno today ,select a plan that works for your business.</h1>
                <button onClick={showToggle} className='bg-theme-color border-[1px] border-theme-color mt-5 text-white px-4 rounded-pill py-[5px]'>Select a plan</button>
            </div>
            <CoinPrice show={show} showToggle={showToggle} />
        </div>
    )
}

export default Subscription