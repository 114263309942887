const AccountRoute =[
    {
        id:1,
        name:'Personal Information',
        icon:'/assets/images/account/user.svg'
    },
    {
        id:2,
        name:'Security',
        icon:'/assets/images/account/shield.svg'
    },    
    {
        id:3,
        name:'Active Sessions',
        icon:'/assets/images/account/Active session.svg'
    },    
    {
        id:4,
        name:'Legal',
        icon:'/assets/images/account/legal.svg'
    },    
]

export default AccountRoute;