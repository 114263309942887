import React from 'react';
import { Link } from 'react-router-dom';

const InstagramDetails = () => {
    const postTypes = [
        { name: "Instagram post (Square)", size: "1080 x 1080", imgSrc: '/assets/images/home/insta.svg' },
        { name: "Instagram post (Portrait)", size: "1080 x 1350", imgSrc: '/assets/images/home/insta.svg' },
        { name: "Instagram post (Landscape)", size: "1080 x 566", imgSrc: '/assets/images/home/insta.svg' },
        { name: "Instagram post (Story)", size: "1080 x 1920", imgSrc: '/assets/images/home/insta.svg' }
    ];

    return (
        <div>
            <h1 className='md:text-[24px] text-base mb-4'>Instagram</h1>
            <div className="flex details gap-3 flex-nowrap overflow-x-auto whitespace-nowrap">
                {postTypes.map((post, index) => (
                    <Link 
                        key={index} 
                        to={`/create/${encodeURIComponent(post.name.replace(/\s+/g, '-'))}/${post.size.replace(/\s*x\s*/, '-x-')}`} 
                        className='flex-shrink-0 md:w-[220px] w-[170px]'
                    >
                        <div className='group'>
                            <div className='relative rounded-[12px] overflow-hidden'>
                                <img src={post.imgSrc} className='w-full' alt='insta' />
                                <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>
                                    Start Generating
                                </div>
                            </div>
                            <div className='mt-2 text-black'>
                                <p className='mb-0 text-sm font-medium'>{post.name}</p>
                                <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>{post.size}</span>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default InstagramDetails;
