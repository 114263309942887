import { toast } from "react-toastify";
import { BASEURL } from "../../baseUrl";
import axios from "axios";

export const ADD_BRAND_KIT = "ADD_BRAND_KIT";
export const UPLOAD_IMAGES = "UPLOAD_IMAGES";
export const GET_BRAND_KIT = "GET_BRAND_KIT";
export const GET_BRAND_KITS = "GET_BRAND_KITS";
export const DELETE_BRAND_KITS = "DELETE_BRAND_KITS";

const addBrandKitAction = (payload) => ({
  type: ADD_BRAND_KIT,
  payload: payload,
});
const uploadImagesAction = (payload) => ({
  type: UPLOAD_IMAGES,
  payload: payload,
});
const getBrandKitAction = (payload) => ({
  type: GET_BRAND_KIT,
  payload: payload,
});
const getBrandKitsAction = (payload) => ({
  type: GET_BRAND_KITS,
  payload: payload,
});
const deleteBrandKitAction = (payload) => ({
  type: DELETE_BRAND_KITS,
  payload: payload,
});



export const addBrandKit = (formData) => {
  return async (dispatch) => {
    try {
      console.log("formData",formData);
      
      const accessToken = localStorage.getItem("authToken");
      const apikey = process.env.REACT_APP_API_KEY;
      await axios
        .post(`${BASEURL}/api/brandkit/create`, formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "x-api-key": apikey,
          },
        })
        .then((res) => {
          dispatch(addBrandKitAction(res.data));
          window.location.href = "/brands-kits";
          toast.success("Form submited successfully");
          console.log("res", res.data);
        })
        .catch((error) => {
          dispatch(addBrandKitAction(error?.response?.data));
          console.log("error", error);
          toast.error(error?.response?.data);
        });
    } catch (error) {
      console.log("Error", error);
      toast.error("Something went wrong");
    }
  };
};

export const uploadImages = (formData) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${BASEURL}/api/uploadimage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("Full API response:", response);
      dispatch(uploadImagesAction(response.data));
      toast.success("Images uploaded successfully");
      return response.data;
    } catch (error) {
      dispatch(uploadImagesAction(error?.response?.data));
      console.log("Error during upload:", error?.response?.data || error);
      toast.error(error?.response?.data?.message || "Something went wrong");
      return null;
    }
  };
};
export const getBrandKit = (id) => {
  return async (dispatch) => {
    try {
      const accessToken = localStorage.getItem("authToken");
      const apikey = process.env.REACT_APP_API_KEY;
      const response = await axios.get(`${BASEURL}/api/brandkit/read/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "x-api-key": apikey,
        },
      });
      console.log("Full API response:", response);
      dispatch(getBrandKitAction(response.data));
      return response.data;
    } catch (error) {
      dispatch(getBrandKitAction(error?.response?.data));
      console.log("Error during upload:", error?.response?.data || error);
      return null;
    }
  };
};
export const getBrandKits = () => {
  return async (dispatch) => {
    try {
      const accessToken = localStorage.getItem("authToken");
      const apikey = process.env.REACT_APP_API_KEY;

      const response = await axios.get(`${BASEURL}/api/brandkit/read`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "x-api-key": apikey,
        },
      });

      console.log(response);
      dispatch(getBrandKitsAction(response.data));
      return response.data;
    } catch (error) {
      dispatch(getBrandKitAction(error?.response?.data));
      console.log(
        "Error in fetching brand kits:",
        error?.response?.data || error
      );
      return null;
    }
  };
};
export const deleteBrandKit = (id) => {
  return async (dispatch) => {
    try {
      const accessToken = localStorage.getItem("authToken");
      const apikey = process.env.REACT_APP_API_KEY;
      const response = await axios.delete(`${BASEURL}/api/brandkit/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "x-api-key": apikey,
        },
      });
      console.log("Delete API response:", response);
      dispatch(deleteBrandKitAction(response.data));
      return response.data;
    } catch (error) {
      dispatch(deleteBrandKitAction(error?.response?.data));
      console.log("Error during deletion:", error?.response?.data || error);
      return null;
    }
  };
};

export const setBrandKitId = (id) => {
  return {
    type: "SET_BRAND_KIT_ID",
    payload: id,
  };
};
