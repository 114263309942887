import React, { useEffect, useState } from 'react'
import { GrFacebookOption } from "react-icons/gr";
import { GrTwitter } from "react-icons/gr";
import { BiSolidMessageRoundedDots } from "react-icons/bi";
import { MdOutlineMessage } from "react-icons/md";
import { MdContentCopy } from "react-icons/md";
import { Modal } from 'react-bootstrap';
import { IoClose } from "react-icons/io5";

const ReferAccount = ({ shows1, toggles }) => {

    const [showAnimation, setShowAnimation] = useState(true);

    useEffect(() => {
        if (shows1) {
            setShowAnimation(true);
            const timer = setTimeout(() => {
                setShowAnimation(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [shows1]);

    return (
        <section>
            <Modal centered show={shows1} onHide={toggles} className='refer-modal relative'>
                {/* <div onClick={toggles} className="absolute cursor-pointer -top-8 -right-8 w-[30px] h-[30px] bg-theme-color text-white flex items-center justify-center rounded-full"><IoClose className='text-lg'/></div> */}
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-4'>
                    <div className="rounded-lg px-3">
                        {showAnimation && (
                            <>
                                <div className="absolute -top-5 left-0">
                                    <dotlottie-player src="https://lottie.host/4bd03fe5-b885-492e-87b0-04c44f81f964/cNSLori4FR.json" background="transparent" speed="1" style={{ width: '350px', height: '400px', margin: 'auto' }} loop autoplay></dotlottie-player>
                                </div>
                                <div className="absolute -top-5 right-0">
                                    <dotlottie-player src="https://lottie.host/4bd03fe5-b885-492e-87b0-04c44f81f964/cNSLori4FR.json" background="transparent" speed="1" style={{ width: '350px', height: '400px', margin: 'auto' }} loop autoplay></dotlottie-player>
                                </div>
                            </>
                        )}

                        <h1 className='text-[24px] text-center mb-3'>Refer a friend Give $10, Get $10.</h1>
                        <p className='text-center mb-0 text-sm text-black/50'>You'll get $10 for each friend who order.</p>

                        <form className='py-[35px] border-b-[1px] border-b-black/10 mb-[35px]'>
                            <div className="flex items-center gap-2 justify-center">
                                <input type="email" placeholder="Your Friend's emails (separated by commas)" className='w-[400px] border outline-none px-4 py-2 rounded-lg placeholder:text-sm' />
                                <button className='bg-theme-color rounded-lg text-white py-2 px-4'>Send</button>
                            </div>
                        </form>

                        <div className="flex items-center justify-evenly text-center">
                            <div>
                                <div className="w-[40px] mb-2 h-[40px] mx-auto bg-theme-color text-white rounded-full flex items-center justify-center text-lg"><GrFacebookOption /></div>
                                <span className="text-sm">Share</span>
                            </div>
                            <div>
                                <div className="w-[40px] mb-2 h-[40px] mx-auto bg-theme-color text-white rounded-full flex items-center justify-center text-lg"><GrTwitter /></div>
                                <span className="text-sm">Tweet</span>
                            </div>
                            <div>
                                <div className="w-[40px] mb-2 h-[40px] mx-auto bg-theme-color text-white rounded-full flex items-center justify-center text-lg"><BiSolidMessageRoundedDots /></div>
                                <span className="text-sm">Message</span>
                            </div>
                            <div>
                                <div className="w-[40px] mb-2 h-[40px] mx-auto bg-theme-color text-white rounded-full flex items-center justify-center text-lg"><MdContentCopy /></div>
                                <span className="text-sm">Copy</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default ReferAccount