import { Outlet } from "react-router-dom";
import Header1 from "./header/Header1";
import { Footer, Header } from "./Index";
import SideMenu from "./SideMenu";
import { useEffect, useState } from "react";
import { auth } from "../firebase/firebase.config";
import Footer1 from "./footer/Footer1";

export default function Wrapper() {

    const [collapseds, setCollapseds] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const collapseToggle = () => setCollapseds(!collapseds);
    const checkSide = localStorage.getItem('checkMenu')
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        // <div className='min-h-screen flex flex-col'>
        //     {isLoggedIn ? <Header1 collapseToggle={collapseToggle} /> : <Header />}

        //     {
        //         isLoggedIn ?
        //             <>
        //                 <aside>
        //                     <SideMenu collapseds={collapseds} />
        //                 </aside>

        //                 <main className='flex flex-1 overflow-hidden pt-[55px]'>
        //                     <section className={`${collapseds ? 'ml-[80px]' : 'ml-[250px]'} flex-1 overflow-y-auto`}>
        //                         <Outlet />
        //                     </section>
        //                 </main>
        //             </> :
        //             <main className='flex flex-1 overflow-hidden'>
        //                 <section className={`flex-1 overflow-y-auto`}>
        //                     <Outlet />
        //                 </section>
        //             </main>
        //     }
        //     {
        //         isLoggedIn ?
        //             ''
        //             :
        //             <Footer1 />
        //     }
        // </div>

        <div className='min-h-screen flex flex-col'>
            <Header1 collapseToggle={collapseToggle} />

            <aside>
                <SideMenu collapseds={collapseds} />
            </aside>

            <main className='flex flex-1 overflow-hidden pt-[55px]'>
                <section className={`${collapseds ? 'ml-[80px]' : 'ml-[250px]'} flex-1 overflow-y-auto`}>
                    <Outlet />
                </section>
            </main>

        </div>
    )
}
