import React from 'react'
import PlansSection from './PlansSection'

const Index = () => {
  return (
    <div>
        <PlansSection/>
    </div>
  )
}

export default Index