import { Link } from "react-router-dom";

const whatsappItems = [
    {
        imgSrc: '/assets/images/home/wp.svg',
        title: 'Post',
        dimensions: '1080 x 1080',
    },
    {
        imgSrc: '/assets/images/home/linkedin.svg',
        title: 'Wide Post',
        dimensions: '1080 x 1080',
    },
    {
        imgSrc: '/assets/images/home/wp1.svg',
        title: 'Status',
        dimensions: '1080 x 1080',
    },
    {
        imgSrc: '/assets/images/home/wp2.svg',
        title: 'Business Cover',
        dimensions: '1080 x 1080',
    },
];

const WhatsappItem = ({ imgSrc, title, dimensions }) => (
    <div className='group flex-shrink-0 md:w-[220px] w-[170px]'>
        <div className='relative rounded-[12px] overflow-hidden'>
            <img src={imgSrc} className='w-full' alt={title} />
            <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>
                Start Generating
            </div>
        </div>
        <div className='mt-2'>
            <p className='mb-0 text-sm font-medium'>{title}</p>
            <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>
                {dimensions}
            </span>
        </div>
    </div>
);

const WhatsappSection = () => (
    <div>
        <h1 className='md:text-[24px] text-base my-4'>Whatsapp</h1>
        <div className="flex details gap-3 flex-nowrap overflow-x-auto whitespace-nowrap">
            {whatsappItems.map((item, index) => (
                   <Link
                   to={`/create/${encodeURIComponent(
                     item.title.replace(/\s+/g, "-")
                   )}/${item.dimensions.replace(/\s*x\s*/, "-x-")}`}
                   key={index}>
                <WhatsappItem 
                    key={index} // Ideally, use a unique identifier if available
                    imgSrc={item.imgSrc} 
                    title={item.title} 
                    dimensions={item.dimensions} 
                />
                </Link>
            ))}
        </div>
    </div>
);

export default WhatsappSection;
