import { Link } from "react-router-dom";

const amazonItems = [
  {
    imgSrc: "/assets/images/home/amazon.svg",
    title: "Store page header",
    dimensions: "1080 x 1080",
  },
  {
    imgSrc: "/assets/images/home/amazon1.svg",
    title: "Banner rectangle",
    dimensions: "1080 x 1080",
  },
  {
    imgSrc: "/assets/images/home/amazon2.svg",
    title: "Banner Square",
    dimensions: "1080 x 1080",
  },
  {
    imgSrc: "/assets/images/home/amazon3.svg",
    title: "Business Cover",
    dimensions: "1080 x 1080",
  },
  {
    imgSrc: "/assets/images/home/amazon4.svg",
    title: "Product Image",
    dimensions: "1080 x 1080",
  },
  {
    imgSrc: "/assets/images/home/amazon5.svg",
    title: "EBC",
    dimensions: "1080 x 1080",
  },
];

const AmazonItem = ({ imgSrc, title, dimensions }) => (
  <div className="group flex-shrink-0 md:w-[220px] w-[170px]">
    <div className="relative rounded-[12px] overflow-hidden">
      <img src={imgSrc} className="w-full" alt={title} />
      <div className="bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0">
        Start Generating
      </div>
    </div>
    <div className="mt-2">
      <p className="mb-0 text-sm font-medium">{title}</p>
      <span className="text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500">
        {dimensions}
      </span>
    </div>
  </div>
);

const AmazonSection = () => (
  <div>
    <h1 className="md:text-[24px] text-base my-4">Amazon</h1>
    <div className="flex details gap-3 flex-nowrap overflow-x-auto whitespace-nowrap">
      {amazonItems.map((item, index) => (
        <Link
          to={`/create/${encodeURIComponent(
            item.title.replace(/\s+/g, "-")
          )}/${item.dimensions.replace(/\s*x\s*/, "-x-")}`}
          key={index}
        >
          <AmazonItem
            imgSrc={item.imgSrc}
            title={item.title}
            dimensions={item.dimensions}
          />
        </Link>
      ))}
    </div>
  </div>
);

export default AmazonSection;
