import React from 'react';
import { useLocation } from 'react-router-dom';
import { GoLightBulb } from "react-icons/go";
import Tooltip from '../common/Tooltip ';

const SideMenu = ({ collapseds }) => {
    const location = useLocation();
    return (
        <div className={`fixed side_menu sidebar bg-[#F9FAFB] top-[56px] border-r-[1px] border-r-[#00000033] px-3 left-0 z-50 ${collapseds ? 'w-[80px]' : 'w-[250px]'} flex flex-col justify-between `}>
            <div>
                {/* Sidebar Links */}
                <div className="relative group">
                    <a href='/' className={`${location.pathname === '/' ? 'active' : ''} text-black hover:bg-light-theme flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                        <img src='/assets/images/icon1.svg' className='grayscale' alt='home' />
                        {/* <span className={`menu-text ${collapseds ? 'collapsed' : ''}`}>Home</span> */}                                                                                                                                         
                    </a>
                    {collapseds && <Tooltip text="Home" position="right" />}
                </div>
                <div className="relative group">
                    <a href='/my-project' className={`${location.pathname === '/my-project' ? 'active' : ''} text-black hover:bg-light-theme flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                        <img src='/assets/images/icon2.svg' alt='folder' />
                        <span className={`menu-text ${collapseds ? 'collapsed' : ''}`}>My projects</span>
                    </a>
                    {collapseds && <Tooltip text="My projects" position="right" />}
                </div>
                <div className="relative group">
                    <a href='/brands-kits' className={`${location.pathname === '/brands-kits' ? 'active' : ''} text-black hover:bg-light-theme flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                        <img src='/assets/images/brand-kit.svg' className='w-[23px]' alt='brand' />
                        <span className={`menu-text ${collapseds ? 'collapsed' : ''}`}>Brand kits</span>
                    </a>
                    {collapseds && <Tooltip text="Brand kits" position="right" />}
                </div>
                <div className="relative group">
                    <a href='#zunno' className={`${location.pathname === '#zunno' ? 'active' : ''} text-black hover:bg-light-theme flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                        <img src='/assets/images/studio.svg' alt='studio' className='w-[23px]'/>
                        <span className={`menu-text ${collapseds ? 'collapsed' : ''}`}>Zunno Studio</span>
                    </a>
                    {collapseds && <Tooltip text="Zunno Studio" position="right" />}
                </div>
                <div className="relative group">
                    <a href='/plans-pricing' className={`${location.pathname === '/plans-pricing' ? 'active' : ''} text-black hover:bg-light-theme flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                        <img src='/assets/images/icon4.svg' alt='price' />
                        <span className={`menu-text ${collapseds ? 'collapsed' : ''}`}>Pricing plans</span>
                    </a>
                    {collapseds && <Tooltip text="Pricing plans" position="right" />}
                </div>
                <a href='https://zunnoweb.canny.io/feature-requests' target='_blank' className={`${location.pathname === '#request' ? 'active' : ''} ${collapseds ? 'd-none' : 'd-flex'} text-black hover:bg-light-theme items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                    {/* <GoLightBulb className='text-[30px]' /> */}
                    <img src='/assets/images/feature.svg' className='w-[23px]' alt='request' />
                    <span className={`menu-text ${collapseds ? 'collapsed' : ''}`}>Feature request</span>
                </a>
            </div>
            <div className={`border-t pt-3  ${collapseds ? 'd-none' : 'd-block'}`}>
                {/* Help and Setting Links */}
                {/* <a href='#help' className={`${location.pathname === '#help' ? 'active' : ''} text-black hover:bg-light-theme flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                    <img src='/assets/images/help.svg' className='w-[24px]' alt='help' />
                    <span className={`menu-text ${collapseds ? 'collapsed' : ''}`}>Help Center</span>
                </a> */}
                <a href='/setting' className={`${location.pathname === '#setting' ? 'active' : ''} text-black hover:bg-light-theme flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg`}>
                    <img src='/assets/images/icon6.svg' className='w-[24px]' alt='setting' />
                    <span className={`menu-text ${collapseds ? 'collapsed' : ''}`}>Settings</span>
                </a>
            </div>
        </div >
    );
}

export default SideMenu;
