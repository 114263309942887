// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBmm04JriE5wqPEKcCt2jsy4iH5hrg1Gl0",
  authDomain: "zunno-7c407.firebaseapp.com",
  projectId: "zunno-7c407",
  storageBucket: "zunno-7c407.appspot.com",
  messagingSenderId: "1070864884825",
  appId: "1:1070864884825:web:c15c633e32c2d17aa6fd48",
  measurementId: "G-Z5VC3PHZR9",
};
const googleProvider = new GoogleAuthProvider();


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;

export const auth = getAuth(app);

// Google provider
googleProvider.setCustomParameters({
  prompt: "select_account"
});

export const signInWithGooglePopup = () => signInWithPopup(auth, googleProvider);

// Facebook provider
const facebookProvider = new FacebookAuthProvider();
export const signInWithFacebookPopup = () => signInWithPopup(auth, facebookProvider);
