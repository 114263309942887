import React from 'react'
import { useState } from 'react';
import { IoIosInformationCircleOutline, IoIosArrowDown } from "react-icons/io";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { GrCheckmark } from "react-icons/gr";
import PriceFaq from './PriceFaq';
import PricePlans from './PricePlans';

const PlansSection = () => {

    const [open, setOpen] = useState(true);

    return (
        <section className='py-[50px]'>
            <PricePlans />
            <div className='container-fluid md:!px-[60px] !px-[16px]'>
                <div>
                    <div className='mt-5'>
                        <h1 className='md:text-[24px] text-xl font-normal mb-5'>Compare all features</h1>

                        <div className='max-w-[100vw] overflow-x-auto'>
                            <div className='price_table xl:w-full min-w-[1100px]'>
                                <div className='flex items-center bg-light-purple rounded-lg'>
                                    <div className='basis-[40%] shrink-0 px-4'>
                                        <h1 className='text-2xl'>Observe</h1>
                                        <p className='flex items-center gap-1'>Lorem ipsum dolor <IoIosInformationCircleOutline className='text-xl' /></p>
                                    </div>
                                    <div className='flex w-full'>
                                        <div className='text-center flex-1 border-l border-l-black/20 py-3'>
                                            <span className='font-medium text-xl'>Free</span>
                                            <p className='font-medium my-2'>$0 USD/ <span className='font-normal text-sm text-black/50'>month</span></p>
                                            <button className='rounded bg-theme-color px-4 py-2 text-[12px] text-white'>Get Started</button>
                                        </div>
                                        <div className='text-center flex-1 border-l border-l-black/20 py-3'>
                                            <span className='font-medium text-xl'>Free</span>
                                            <p className='font-medium my-2'>$0 USD/ <span className='font-normal text-sm text-black/50'>month</span></p>
                                            <button className='rounded bg-theme-color px-4 py-2 text-[12px] text-white'>Get Started</button>
                                        </div>
                                        <div className='text-center flex-1 border-l border-l-black/20 py-3'>
                                            <span className='font-medium text-xl'>Free</span>
                                            <p className='font-medium my-2'>$0 USD/ <span className='font-normal text-sm text-black/50'>month</span></p>
                                            <button className='rounded bg-theme-color px-4 py-2 text-[12px] text-white'>Get Started</button>
                                        </div>
                                        <div className='text-center flex-1 border-l border-l-black/20 py-3'>
                                            <span className='font-medium text-xl'>Free</span>
                                            <p className='font-medium my-2'>$0 USD/ <span className='font-normal text-sm text-black/50'>month</span></p>
                                            <button className='rounded bg-theme-color px-4 py-2 text-[12px] text-white'>Get Started</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-5'>
                                    {/* <div className='border-b'>
                                        <Button
                                            onClick={() => setOpen(!open)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={open}
                                            className='bg-transparent text-black !py-[18px] border-0 md:!text-2xl !flex items-center justify-between w-full'
                                        >
                                            All  Features
                                            <IoIosArrowDown className={`text-lg ${open ? 'rotate-180' : 'rotate-0'}`} />
                                        </Button>
                                    </div> */}
                                    {/* <Collapse in={open} className='visible'> */}
                                        <div id="example-collapse-text">
                                            <div className='flex border-b py-[18px]'>
                                                <div className='basis-[40%] shrink-0 px-4 flex items-center gap-2'>Daily Sessions <IoIosInformationCircleOutline className='text-xl' /></div>
                                                <div className='flex w-full'>
                                                    <div className='flex-1 text-center'>35</div>
                                                    <div className='flex-1 text-center'>35</div>
                                                    <div className='flex-1 text-center'>500-270k</div>
                                                    <div className='flex-1 text-center'>Up to 270k</div>
                                                </div>
                                            </div>
                                            <div className='flex border-b py-[18px]'>
                                                <div className='basis-[40%] shrink-0 px-4 flex items-center gap-2'>365 days data storage <IoIosInformationCircleOutline className='text-xl' /></div>
                                                <div className='flex w-full'>
                                                    <div className='flex-1 text-center'><GrCheckmark className='text-theme-color mx-auto' /></div>
                                                    <div className='flex-1 text-center'><GrCheckmark className='text-theme-color mx-auto' /></div>
                                                    <div className='flex-1 text-center'><GrCheckmark className='text-theme-color mx-auto' /></div>
                                                    <div className='flex-1 text-center'><GrCheckmark className='text-theme-color mx-auto' /></div>
                                                </div>
                                            </div>
                                            <div className='flex border-b py-[18px]'>
                                                <div className='basis-[40%] shrink-0 px-4 flex items-center gap-2'>Daily Capture <IoIosInformationCircleOutline className='text-xl' /></div>
                                                <div className='flex w-full'>
                                                    <div className='flex-1 text-center'>35</div>
                                                    <div className='flex-1 text-center'>35</div>
                                                    <div className='flex-1 text-center'>500-270k</div>
                                                    <div className='flex-1 text-center'>Up to 270k</div>
                                                </div>
                                            </div>
                                            <div className='flex border-b py-[18px]'>
                                                <div className='basis-[40%] shrink-0 px-4 flex items-center gap-2'>365 days data storage <IoIosInformationCircleOutline className='text-xl' /></div>
                                                <div className='flex w-full'>
                                                    <div className='flex-1 text-center'><GrCheckmark className='text-theme-color mx-auto' /></div>
                                                    <div className='flex-1 text-center'><GrCheckmark className='text-theme-color mx-auto' /></div>
                                                    <div className='flex-1 text-center'><GrCheckmark className='text-theme-color mx-auto' /></div>
                                                    <div className='flex-1 text-center'><GrCheckmark className='text-theme-color mx-auto' /></div>
                                                </div>
                                            </div>
                                            <div className='flex border-b py-[18px]'>
                                                <div className='basis-[40%] shrink-0 px-4 flex items-center gap-2'>365 days data storage <IoIosInformationCircleOutline className='text-xl' /></div>
                                                <div className='flex w-full'>
                                                    <div className='flex-1 text-center'><GrCheckmark className='text-theme-color mx-auto' /></div>
                                                    <div className='flex-1 text-center'><GrCheckmark className='text-theme-color mx-auto' /></div>
                                                    <div className='flex-1 text-center'><GrCheckmark className='text-theme-color mx-auto' /></div>
                                                    <div className='flex-1 text-center'><GrCheckmark className='text-theme-color mx-auto' /></div>
                                                </div>
                                            </div>
                                        </div>
                                    {/* </Collapse> */}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='mt-5'>
                        <div className='bg-light-purple p-4 d-md-flex rounded-lg items-center justify-between'>
                            <div className='px-lg-5'>
                                <p className='md:text-lg font-semibold my-0'>Not sure which plan is best for you?</p>
                                <p className='md:text-lg my-0'>Contact us to ask questions, and chat about your needs.</p>
                            </div>
                            <div className='text-end mt-md-0 mt-3'>
                                <a href='/contact-us' className='bg-theme-color mx-lg-5 px-3 py-2 rounded-lg text-white'>Contact Sales</a>
                            </div>
                        </div>
                    </div>
                </div>
                <PriceFaq />
            </div>
        </section>
    )
}

export default PlansSection