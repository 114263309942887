import React from 'react';
import { Link } from 'react-router-dom';

const PinterestDetails = () => {
    const pinTypes = [
        { name: "Pin", size: "1080 x 1080", imgSrc: '/assets/images/home/pin2.svg' },
        { name: "Idea Pin", size: "1080 x 1080", imgSrc: '/assets/images/home/pin3.svg' }
    ];

    return (
        <div>
            <h1 className='md:text-[24px] text-base my-4'>Pinterest</h1>
            <div className="flex details gap-3 flex-nowrap overflow-x-auto whitespace-nowrap">
                {pinTypes.map((pin, index) => (
                    <Link to={`/create/${encodeURIComponent(pin.name.replace(/\s+/g, '-'))}/${pin.size.replace(/\s*x\s*/, '-x-')}`}  key={index} className='group flex-shrink-0 md:w-[220px] w-[170px]'>
                        <div className='relative rounded-[12px] overflow-hidden'>
                            <img src={pin.imgSrc} className='w-full' alt={pin.name} />
                            <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>
                                Start Generating
                            </div>
                        </div>
                        <div className='mt-2'>
                            <p className='mb-0 text-sm font-medium'>{pin.name}</p>
                            <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>{pin.size}</span>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default PinterestDetails;
