import {
  ADD_BRAND_KIT,
  GET_BRAND_KIT,
  GET_BRAND_KITS,
  UPLOAD_IMAGES,
  DELETE_BRAND_KITS,
} from "../actions/BrandKit.action";

const initialState = {
  uploadImages: "",
  addBrandKit: "",
  getBrandKit: "",
  getBrandKits: "",
  deleteBrandKits: "",
  brandKitId: null,
};

const brandKitReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BRAND_KIT:
      return { ...state, addBrandKit: action.payload };
    case UPLOAD_IMAGES:
      return { ...state, uploadImages: action.payload };
    case GET_BRAND_KIT:
      return { ...state, getBrandKit: action.payload };
    case GET_BRAND_KITS:
      return { ...state, getBrandKits: action.payload };
    case DELETE_BRAND_KITS:
      return { ...state, deleteBrandKits: action.payload };
    case "SET_BRAND_KIT_ID":
      return { ...state, brandKitId: action.payload };
    default:
      return state;
  }
};

export default brandKitReducer;
