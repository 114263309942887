import React from 'react'
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import InstagramDetails from './details/InstagramDetails';
import FacebookDetails from './details/FacebookDetails';
import PopularDetails from './details/PopularDetails';
import LinkedinDetails from './details/LinkedinDetails ';
import YoutubeDetails from './details/YoutubeDetails';
import PinterestDetails from './details/PinterestDetails';
import WhatsappSection from './details/WhatsappDetails';
import AmazonSection from './details/AmazonDetails';

const SocialMedia = () => {

  const [key, setKey] = useState('popular');

  return (
    <div className='social_media py-[50px]'>
      <div className='container-fluid md:!px-[60px] !px-[16px]'>
        <div>
          <h1 className='md:text-[24px] text-[20px]'>Social Media</h1>
          <p className='text-black/50 mb-0'>Make  social posts, reels, resumes and flyers with professionally designed templates.</p>

          <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="my-5 border-0 trending_tab">
            <Tab eventKey="popular" title="Popular">
              <PopularDetails/>
            </Tab>
            <Tab eventKey="instagram" title="Instagram">
              <InstagramDetails />
            </Tab>
            <Tab eventKey="facebook" title="Facebook">
              <FacebookDetails/>
            </Tab>
            <Tab eventKey="linkedin" title="Linkedin">
              <LinkedinDetails/>
            </Tab>
            <Tab eventKey="youtube" title="Youtube">
              <YoutubeDetails/>
            </Tab>
            <Tab eventKey="pinterest" title="Pinterest">
              <PinterestDetails/>
            </Tab>
            <Tab eventKey="whatsapp" title="Whatsapp">
              <WhatsappSection/>
            </Tab>
            <Tab eventKey="display" title="Display Ads">
            </Tab>
            <Tab eventKey="amazon" title="Amazon">
              <AmazonSection/>
            </Tab>

          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default SocialMedia