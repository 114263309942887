import React from 'react'
import AccountOption from './AccountOption'

const Index = () => {
  return (
    <>
        <AccountOption/>
    </>
  )
}

export default Index