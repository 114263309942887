import React from 'react'

const StayDate = () => {
    return (
        <section className='pb-[50px] bg-right' style={{ backgroundImage: 'url("/assets/images/zunno-blog/back1.png")'}}>
            <div className='container-fluid md:!px-[60px] !px-[16px]'>
                <div className='row gy-4 items-center justify-center'>
                    <div className='col-lg-6'>
                        <div className='bg-cover text-center mb-4'>
                            <h3 className='mb-3 md:text-[30px] text-[24px]'>Stay up to date!</h3>
                            <p className='mb-0'>Subscribe to our newsletter to get inbox notifications</p>
                        </div>
                        {/* </div>
                    <div className='col-lg-6 text-lg-start text-center'> */}
                        {/* <div className='font-[600] my-3 text-center'>
                            Sign up to our newsletter ↓
                        </div> */}
                        <div className='flex items-center justify-center gap-2'>
                            <input type='email' placeholder='Enter your email' className='bg-[#F5F8FB] rounded-lg border-[1px] border-[#F5F8FB] px-3 py-2 md:w-[300px]' />
                            <button className='bg-theme-color px-3 py-2 text-white rounded-lg'>Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StayDate