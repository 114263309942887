import React from 'react'
import HomeBanner from './HomeBanner'
import SocialMedia from './SocialMedia'

const Index = () => {
  return (
    <div>
        <HomeBanner/>
        <SocialMedia/>
    </div>
  )
}

export default Index