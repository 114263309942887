import React from 'react'

const PrivacyDetail = () => {
  return (
    <div className='desclimer_detail py-[50px]'>
      <div className="container !px-[16px]">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h1 className='md:text-[35px] text-[24px]'>Zunno Privacy Policy</h1>
            <p>Zunno respects the privacy of our users. This Privacy Policy explains what information we collect when you use our website, how we use that information, and how we protect it.</p>
            <p className='text-[#505050] lg:mb-[80px] mb-[40px]'>Last Modified: August 31 , 2024</p>

            <div className="privacy-wrap">
              <ul className='list-decimal list-inside'>
                <li className='text-xl font-semibold mb-3'>Information We Collect</li>
                <p>We collect the following types of information:</p>
                <ul className='list-disc ms-5 !mb-[40px]'>
                  <li><b>Information you provide directly:</b> This includes information you provide when you register for an account, contact us, or participate in other activities on the Website. This may include your name, email address, phone number, and company name.</li>
                  <li><b>Information collected automatically:</b> When you use the Website, we may collect certain information automatically, such as your IP address, browser type, operating system, and the referring website. This information is used to improve the functionality of the Website and understand how users interact with it.</li>
                  <li><b>Cookies:</b> : We may use cookies to store your preferences and track your activity on the Website. You can choose to disable cookies through your browser settings.</li>
                </ul>

                <li className='text-xl font-semibold mb-3'>Use of Information</li>
                <p>We use the information we collect for the following purposes:</p>
                <ul className='list-disc ms-5 !mb-[40px]'>
                  <li>To provide and operate the Website</li>
                  <li>To process your requests and respond to your inquiries</li>
                  <li>To send you information about our services and promotional offers</li>
                  <li>To improve the functionality of the Website</li>
                  <li>To analyze how users interact with the Website</li>
                  <li>To comply with legal and regulatory requirements</li>
                </ul>

                <li className='text-xl font-semibold mb-3'>Sharing of Information</li>
                <p>We may share your information with third-party service providers who help us operate the Website and provide our services. These service providers are contractually obligated to keep your information confidential and to use it only for the purposes for which it has been disclosed.</p>
                <p>We will not share your information with any third-party for marketing purposes without your consent.</p>

                <li className='text-xl font-semibold mb-3'>Data Security</li>
                <p>We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no website or internet transmission is completely secure. We cannot guarantee the security of your information.</p>

                <li className='text-xl font-semibold mb-3'>Your Rights</li>
                <p>You have the right to access, update, and delete your personal information. You can also opt out of receiving marketing communications from us.</p>

                <li className='text-xl font-semibold mb-3'>Children's Privacy</li>
                <p>The Website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you believe your child has provided us with personal information, please contact us so we can delete it.</p>

                <li className='text-xl font-semibold mb-3'>Changes to this Privacy Policy</li>
                <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                <li className='text-xl font-semibold mb-3'>Contact Us</li>
                <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:legal@zunno.io">legal@zunno.io</a>.</p>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyDetail