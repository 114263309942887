import React, { useEffect, useState } from "react";
import { FaEyeSlash } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { auth, signInWithFacebookPopup } from "../firebase/firebase.config";
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
  fetchSignInMethodsForEmail,
  linkWithCredential,
} from "firebase/auth";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import { googleSignIn } from "../api/auth.req";

const SignUp = () => {
  const backgroundImages = [
    "/assets/images/random/5.jpg",
    "/assets/images/random/8.webp",
    "/assets/images/random/7.jpg",
    "/assets/images/random/6.jpeg",
    "/assets/images/random/9.png",
  ];

  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    const randomImage =
      backgroundImages[Math.floor(Math.random() * backgroundImages.length)];
    setBackgroundImage(randomImage);
  }, []);

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user);
        toast.success("Registration Successfully!");
        navigate("/sign-in");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorCode, errorMessage);
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const logGoogleUser = async () => {
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider());
      console.log(auth);
      const user = result.user;
      console.log("Google User Info:", user);

      const accessToken = user.accessToken;
      console.log("Google Access Token:", accessToken);

      const { data } = await googleSignIn(
        {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(data);

      console.log(data.data.authToken);

      if (data?.status === "success") {
        toast.success("Login with Google successfully!");
        localStorage.setItem('authToken', data.data.authToken);
        navigate("/");
      }
    } catch (error) {
      toast.error("Google Login failed");
      console.error("Google Login Error:", error);
    }
  };

  const logFacebookUser = async () => {
    try {
      const result = await signInWithFacebookPopup(
        auth,
        new FacebookAuthProvider()
      );

      const user = result.user;
      console.log("Facebook User Info:", user);
      const accessToken = user.accessToken;
      console.log("Facebook Access Token:", accessToken);
      const { data } = await googleSignIn(
        {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (data?.status === "success") {
        toast.success("Logged in with Facebook successfully!");
        localStorage.setItem('authToken', data.data.authToken);

        navigate("/");
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      toast.error(`Error: ${errorCode} - ${errorMessage}`);
      console.error("Facebook Login Error:", error);
    }
  };

  return (
    <div
      className="sign-up"
      style={{
        backgroundImage: `linear-gradient(rgba(6, 7, 9, 0.5), rgba(6, 7, 9, 0.5)),url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
    >
      <div className="container">
        <div className="row items-center justify-center min-h-screen">
          <div className="col-5 d-xl-block d-none">
            <div>
              <Link to="/" className="inline-block">
                <img src="/assets/images/black_logos.svg" alt="logo" />
              </Link>
              <span className="text-white block">
                Unleash your Creativity with AI
              </span>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 col-md-8">
            <div className="text-center mb-4 d-xl-none">
              <Link to="/">
                <img
                  src="/assets/images/black_logos.svg"
                  className="md:w-auto w-[150px] mx-auto"
                  alt="logo"
                />
              </Link>
              <span className="text-white block">
                Unleash your Creativity with AI
              </span>
            </div>
            <div className="bg-white rounded-lg md:p-[40px] p-[20px]">
              <h1 className="md:text-[30px] text-[26px] mb-4">
                Create an account
              </h1>
              <div className="flex items-center gap-3">
                <div
                  onClick={logGoogleUser}
                  className="border-[1px] w-[50px] h-[50px] border-[#13131433] cursor-pointer flex items-center gap-2 justify-center rounded-full py-2"
                >
                  <img
                    src="/assets/images/google.svg"
                    alt="google"
                    className="h-[30px]"
                  />
                </div>
                <div
                  onClick={logFacebookUser}
                  className="border-[1px] w-[50px] h-[50px] border-[#13131433] cursor-pointer flex items-center gap-2 justify-center rounded-full py-2"
                >
                  <img
                    src="/assets/images/facebook.svg"
                    className="h-[24px]"
                    alt="facebook"
                  />
                </div>
              </div>

              <div className="my-4 flex items-center">
                <div className="w-full h-[1px] bg-[#eaeaea]"></div>
                <span className="block text-center bg-white px-4 text-sm">
                  Or
                </span>
                <div className="w-full h-[1px] bg-[#eaeaea]"></div>
              </div>

              <b className="mb-2 block">Sign up with email</b>

              <form>
                <div className="mb-4">
                  <label className="text-sm mb-2">Email address</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="bg-[#F1F3F5] text-sm w-full rounded-lg outline-none p-2 border-[1px] border-[rgba(0, 0, 0, 0.2)]"
                  />
                </div>
                <div className="mb-4">
                  <label className="text-sm mb-2">Password</label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="bg-[#F1F3F5] text-sm w-full rounded-lg outline-none p-2 border-[1px] border-[rgba(0, 0, 0, 0.2)]"
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      className="absolute top-3 right-4 text-[#505050] cursor-pointer"
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                </div>
                <div className="text-end">
                  <button
                    type="submit"
                    onClick={onSubmit}
                    className="text-[12px] bg-theme-color text-white py-[10px] px-[20px] rounded-lg"
                  >
                    Continue
                  </button>
                </div>
              </form>

              <p className="mt-5 text-center">
                Already have an account?{" "}
                <Link to="/sign-in" className="text-theme-color">
                  Sign in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
