import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={`${className} custom-arrow w-[50px] h-[50px] bg-[#fff6] rounded-full border-[1px] border-[#394c6026]`} onClick={onClick}>
            <img src="/assets/images/right.png" alt="Next" />
        </div>
    );
};

const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={`${className} custom-arrow w-[50px] h-[50px] bg-[#fff6] rounded-full border-[1px] border-[#394c6026]`} onClick={onClick}>
            <img src="/assets/images/left.png" alt="Previous" />
        </div>
    );
};

const AboutValues = () => {

    const settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        draggable: false,
        swipe: false,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint:1200,
                settings:{
                    slidesToShow:3,
                }
            },
            {
                breakpoint:992,
                settings:{
                    slidesToShow:2
                }
            },
            {
                breakpoint:700,
                settings:{
                    slidesToShow:1
                }
            }
        ],
    };

    const data = [
        { id: 1, img: '/assets/images/about/value.png', heading: 'Innovation at Our Core' },
        { id: 2, img: '/assets/images/about/value1.png', heading: 'Empowering Creativity' },
        { id: 3, img: '/assets/images/about/value2.svg', heading: 'User-Centered Design' },
        { id: 4, img: '/assets/images/about/value5.svg', heading: 'Ethical AI Development' },
        { id: 5, img: '/assets/images/about/value4.png', heading: 'Collaboration & Community' },
        { id: 6, img: '/assets/images/about/value3.svg', heading: 'Harmonizing Human Creativity with AI' },
    ]

    return (
        <section className='my-5'>
            <h2 className='text-center mb-5 text-[#0006] md:text-[35px] text-[24px]'>Our Values</h2>
            <Slider {...settings} className='value_slider'>
                {
                    data.map((res) => {
                        return (
                            <div key={res.id} className='item h-full'>
                                <div className='bg-light-purple mx-[12px] rounded-xl p-2'>
                                    <img src={res.img} className='max-w-[230px] mx-auto' alt={res.heading} />
                                    <p className='my-4 text-center'>{res.heading}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
        </section>
    )
}

export default AboutValues;
