import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';

const Security = () => {

    const [show, setShow] = useState(false);
    const toggle = () => { setShow(!show) }
    const [show1, setShow1] = useState(false);
    const toggle1 = () => { setShow1(!show1) }

    return (
        <div className='col-lg-10 py-[50px]'>
            {/* <div className='text-left'>
                <h1 className='md:text-[28px] mb-2 text-xl'>Security</h1>
                <p className='mb-0 text-black/50'>Info about you and your preferences across Google services</p>
            </div> */}
            <div className='flex items-center justify-between'>
                <div>
                    <h1 className='md:text-[28px] mb-3 text-xl'>Your profile info in Zunno services</h1>
                    <p className='mb-0'>Personal info and options to manage  it.You can make some of
                        this info , like your contact details , visible to others so that they can reach you easily.
                    </p>
                </div>
                <img src='/assets/images/account/img1.png' className='w-[250px]' />
            </div>

            <div className='border-t py-4 mt-4'>
                <h1 className='text-lg'>Reset Password</h1>
                <p className='text-sm mb-4'>How you log into Zunno</p>
                <button onClick={toggle} className='text-theme-color border-[1px] border-theme-color px-4 rounded-pill py-[5px]'>Password Reset</button>
            </div>

            <div className='border-t py-4'>
                <h1 className='text-lg'>Security</h1>
                <p className='text-sm mb-4'>Forgot to sign out from a shared device? Log out from all devices to secure your account.</p>
                <button className='text-theme-color border-[1px] border-theme-color px-4 rounded-pill py-[5px]'>Sign out from all devices</button>
            </div>

            <div className='border-t py-4'>
                <h1 className='text-lg'>Delete your account</h1>
                <p className='text-sm mb-4'>Deleting your account will remove access to your designs and Zunno login. Your account was created on 16 Sept 2024 at 9:24 am.</p>
                <button onClick={toggle1} className='text-theme-color border-[1px] border-theme-color px-4 rounded-pill py-[5px]'>Delete Account</button>
            </div>

            {/* Password modal */}
            <Modal show={show} onHide={toggle} centered>
                <Modal.Header closeButton className='border-0'>               
                </Modal.Header>
                <Modal.Body>
                    <form className='pb-4 px-3'>
                        <h1 className="md:text-[24px] text-xl text-center">Reset Password</h1>
                        <p className='text-center text-sm text-black/50 mb-4'>Please confirm your password before updating your account setting</p>
                        <div className="mb-4">
                            <label className='mb-1 block'>Current Password</label>
                            <input type='text' placeholder='Enter your current password' className="w-full border px-3 py-2 rounded-lg" />
                        </div>
                        <div className="mb-4">
                            <label className='mb-1 block'>New Password</label>
                            <input type='text' placeholder='Enter your new password' className="w-full border px-3 py-2 rounded-lg" />
                        </div>
                        <div className="mb-5">
                            <label className='mb-1 block'>Confirm New Password</label>
                            <input type='text' placeholder='Confirm your new password' className="w-full border px-3 py-2 rounded-lg" />
                        </div>
                        <div className="flex items-center gap-3">
                            <button className='bg-theme-color border-[1px] w-[100px] border-theme-color text-white px-4 rounded-pill py-[5px]'>Save</button>
                            <button onClick={toggle} className='text-theme-color border-[1px] w-[100px] border-theme-color px-4 rounded-pill py-[5px]'>Cancel</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            {/* delete modal */}
            <Modal show={show1} onHide={toggle1} centered>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title>You’re about to delete your account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-black/60 text-sm'>After submitting this form, you have <span className='text-black'>14 days</span> to log back into your account to restore it before it’s <span className='text-black'>permanently deleted.</span></p>

                    <div className="bg-light-theme p-2 border-l-2 border-l-theme-color my-4">
                        <p className='font-medium'>Important</p>
                        <span className='text-sm'>To keep teams and their designs active, <span className='underline'>Transfer Ownership</span> to another user. The new owner must accept before deleting your account.</span>
                    </div>

                    <p className='text-black/60 text-sm'>Enter the code we just sent to <span className='text-black'>admin@example.com</span> if you want to proceed:</p>
                    <form>
                        <div className="mb-5">
                            <input type='text' placeholder='Enter Code' className="w-full border px-3 py-2 rounded-lg" />
                        </div>
                        <div className="flex items-center gap-3">
                            <button className='bg-theme-color border-[1px] w-[100px] border-theme-color text-white px-4 rounded-pill py-[5px]'>Save</button>
                            <button onClick={toggle1} className='text-theme-color border-[1px] w-[100px] border-theme-color px-4 rounded-pill py-[5px]'>Cancel</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Security 