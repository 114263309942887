import React, { useEffect, useRef, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PurchaseHistory from './PurchaseHistory';
import Subscription from './Subscription';
import PaymentDetails from './PaymentDetails';

const PurchaseData = () => {

    const [key, setKey] = useState('assessments');

    return (
        <section className='py-[50px]'>
            <div className='container-fluid md:!px-[60px] px-[16px]'>

                <div className="relative mb-5">
                    <img src="/assets/images/about/billing3.png" className='rounded-[7px]' alt="kit-banner" />
                    <h1 className='md:text-[32px] text-[24px] text-white absolute top-[30px] left-[30px]'>Billing Central</h1>
                </div>

                {/* <div className=" pb-3"><h1 className='md:text-[35px] text-[24px]'></h1></div> */}

                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-md-5 mb-3 border-0 service_tab">
                    <Tab eventKey="assessments" title="Payment details">
                        <PaymentDetails />
                    </Tab>
                    <Tab eventKey="profile" title="Subscription">
                        <Subscription />
                    </Tab>
                    <Tab eventKey="contact" title="Purchase history">
                        <PurchaseHistory />
                    </Tab>
                </Tabs>
            </div>
        </section>
    )
}

export default PurchaseData;
