import React from 'react'

const DisclaimerDetail = () => {
    return (
        <div className='desclimer_detail py-[50px]'>
            <div className="container !px-[16px]">
                <div className="row">
                    <div className="col-md-8 mx-auto">
                        <h1 className='md:text-[35px] text-[24px]'>Zunno Disclaimer</h1>
                        <p className='text-[#505050] lg:mb-[80px] mb-[40px]'>Last Modified: August 31 , 2024</p>
                        <div className="privacy-wrap">
                            <ul className='list-decimal list-inside'>
                                <li className='text-xl font-semibold mb-3'>Content Disclaimer</li>
                                <p>The information contained on this website is provided for informational purposes only and should not be construed as professional advice. While we endeavor to keep the information on the Website accurate and up-to-date, we make no representations or warranties of any kind, express or implied, about the accuracy, completeness, reliability, suitability, or availability of the information or content contained on the Website for any purpose. You acknowledge that any reliance on such information shall be at your sole risk.
                                </p>

                                <li className='text-xl font-semibold mb-3'>Functionality Disclaimer</li>
                                <p>Codersbay attempts to ensure the availability and functionality of the Website but does not guarantee uninterrupted or error-free access. There may be occasions when the Website is unavailable due to scheduled maintenance, system upgrades, or other unforeseen circumstances. We shall not be liable for any loss or damage arising from the Website's unavailability.
                                </p>

                                <li className='text-xl font-semibold mb-3'>Links Disclaimer</li>
                                <p>The Website may contain links to other websites or resources provided by third parties. These links are provided for your convenience only and do not constitute an endorsement by Codersbay of the content on those websites. We have no control over the content of these sites and are not responsible for any losses or damages arising from your use of them.
                                </p>

                                <li className='text-xl font-semibold mb-3'>Errors and Omissions Disclaimer</li>
                                <p>Despite our efforts, the Website may contain errors or omissions. We reserve the right to correct any errors or omissions at any time without prior notice.
                                </p>

                                <li className='text-xl font-semibold mb-3'>Limitation of Liability</li>
                                <p>To the maximum extent permitted by law, Codersbay shall not be liable for any direct, indirect, incidental, consequential, special, punitive, or exemplary damages arising out of or relating to your use of the Website or its content.
                                </p>

                                <li className='text-xl font-semibold mb-3'>Indemnification</li>
                                <p>You agree to indemnify and hold harmless Codersbay from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of the Website.
                                </p>

                                <li className='text-xl font-semibold mb-3'>Governing Law</li>
                                <p>These terms shall be governed by and construed in accordance with the laws of India.</p>

                                <li className='text-xl font-semibold mb-3'>Changes to Disclaimer</li>
                                <p>Codersbay may revise this Disclaimer at any time by updating this page. You are expected to check this page periodically to be aware of any modifications. Your continued use of the Website following the posting of any changes to the Disclaimer will be deemed your acceptance of those changes.
                                </p>

                                <li className='text-xl font-semibold mb-3'>Contact Us</li>
                                <p>If you have any questions about this Disclaimer, please contact us at <a href="mailto:legal@zunno.io">legal@zunno.io</a>.</p>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DisclaimerDetail