import React, { useEffect, useRef, useState } from 'react'
import { CiSearch } from "react-icons/ci";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { BsFileEarmarkText } from "react-icons/bs";
import { CiFilter } from "react-icons/ci";
import { Offcanvas, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

const PurchaseHistory = ({ space, text, border }) => {

    const dropRef = useRef(null)
    const [visibleDropdownIndex, setVisibleDropdownIndex] = useState(null);
    const [showBar, setShowBar] = useState(false);
    const barToggle = () => { setShowBar(!showBar) }

    const toggleDropdown = (index) => {
        setVisibleDropdownIndex(visibleDropdownIndex === index ? null : index);
    };

    const handleClickOutside = () => {
        if (dropRef.current) {
            setVisibleDropdownIndex(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={space}>
            {/* <div className='row gy-4'>
                <div className='col-xl-3 col-lg-4'>
                    <label className='mb-2'>Search for Invoice ID</label>
                    <div className='border-[1px] border-[#D1CFCF] flex items-center gap-2 rounded px-3 py-2'>
                        <CiSearch className='text-2xl' />
                        <input type='search' placeholder='1234562-52565482' className='w-full outline-none' />
                        <CiFilter className='text-2xl d-md-none' onClick={barToggle} />
                    </div>
                </div>
                <div className='col-xl-2 col-lg-4 col-6 d-md-block d-none'>
                    <label className='mb-2'>Filter by Type</label>
                    <select className='border-[1px] w-full border-[#D1CFCF] px-3 py-2 outline-none rounded custom-select'>
                        <option value=''>Any item type</option>
                        <option value='Credit'>Credit</option>
                        <option value='License'>License</option>
                        <option value='Subscription'>Subscription</option>
                    </select>
                </div>
                <div className='col-xl-2 col-lg-4 col-6 d-md-block d-none'>
                    <label className='mb-2'>Filter by date</label>
                    <select className='border-[1px] w-full border-[#D1CFCF] px-3 py-2 outline-none rounded custom-select'>
                        <option value=''>All dates</option>
                        <option value='Last 30 days'>Last 30 days</option>
                        <option value='Last 90 days'>Last 90 days</option>
                        <option value='Last 120 days'>Last 120 days</option>
                        <option value='2024'>2024</option>
                        <option value='2023'>2023</option>
                    </select>
                </div>
            </div> */}
            <h1 className={`${border} md:text-[28px] mb-0 text-xl`}>{text}</h1>
            <div className={`purchase_table my-5 table-responsive`}>
                <Table>
                    <thead>
                        <tr>
                            <th style={{}}>Date</th>
                            <th style={{}}>Description</th>
                            <th style={{}}>Payment Method</th>
                            <th style={{}}>Invoice</th>
                            <th style={{ textAlign: 'right' }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ['1', '2', '3'].map((index) => {
                                return (
                                    <tr key={index} className='align-middle'>
                                        <td>30 August 2024</td>
                                        <td>
                                            <div className='flex items-center gap-3'>
                                                <div className="w-[109px] h-[75px] my-2 rounded flex items-center justify-center border-[1px] border-black/20">
                                                    <img src='/assets/images/favicon.svg' width='40px' alt='purchase' />
                                                </div>
                                                <div>
                                                    <p className='font-medium mb-1'>Subscription purchase</p>
                                                    <span className='text-black/50 text-xs'>123456-425856122555</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td><div className='flex items-center'><img src="/assets/images/paytm.svg" alt="paytm" />7***@pthdfc</div></td>
                                        <td>
                                            <div className='flex items-center gap-4'>
                                                <span className='text-black/50 hover:text-theme-color cursor-pointer flex items-center gap-2' onClick={barToggle}><img src="/assets/images/eye.svg" alt="eye" /> View</span>
                                                {/* <span className='relative'>
                                                    <HiOutlineDotsHorizontal className='cursor-pointer' onClick={() => toggleDropdown(index)} />
                                                    {visibleDropdownIndex === index && (
                                                        <div ref={dropRef} className='bg-white z-10 shadow absolute top-[20px] w-[200px] rounded-lg right-0'>
                                                            <div className='flex items-center px-3 h-[48px] gap-2 text-sm cursor-pointer hover:bg-[#F5F6F6]'><BsFileEarmarkText /> New Invoice</div>
                                                            <div className='flex items-center gap-2 px-3 h-[48px] text-sm cursor-pointer hover:bg-[#F5F6F6]'><BsFileEarmarkText />Download Invoice</div>
                                                        </div>
                                                    )}
                                                </span> */}
                                            </div>
                                        </td>
                                        <td className='text-right'>
                                            <span className='text-lg'>$200</span>
                                            <span className='text-xs text-black/50 block'>168.64(+$39.36 IGST)</span>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>

            {/* Filter Toggle */}
            {/* <Offcanvas placement='bottom' show={showBar} onHide={barToggle} className="bar_button">
                <Offcanvas.Header closeButton className='border-b'>
                    <Offcanvas.Title>Filters</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p className='font-[500]'>Filter by type</p>
                    <span className='text-sm pb-3 block'>Any item type</span>
                    <span className='text-sm pb-3 block'>Credit</span>
                    <span className='text-sm pb-3 block'>License</span>
                    <span className='text-sm pb-3 block'>Subscription</span>

                    <p className='font-[500]'>Filter by date</p>
                    <span className='text-sm pb-3 block'>All dates</span>
                    <span className='text-sm pb-3 block'>Last 30 days</span>
                    <span className='text-sm pb-3 block'>Last 90 days</span>
                    <span className='text-sm pb-3 block'>Last 120 days</span>
                    <span className='text-sm pb-3 block'>2024</span>
                    <span className='text-sm pb-3 block'>2023</span>
                </Offcanvas.Body>
            </Offcanvas> */}

            <Modal show={showBar} onHide={barToggle} size='lg' centered className='invoice'>
                <Modal.Body>
                    <div className='bg-theme-color/10 py-3 px-4 rounded-xl'>
                        <div className="flex mb-4 items-center justify-between">
                            <h1 className='text-xl'>Invoice</h1>
                            <span className='text-xs flex items-center gap-1 hover:underline cursor-pointer'><img src="/assets/images/download.svg" className='w-[14px]' alt="download" /> Download</span>
                        </div>
                        <div className='flex items-center justify-between'>
                            <div>
                                <span className='text-xs text-[#60737D]'>Billed To:</span>
                                <h1 className='text-lg mb-0'>Client Name</h1>
                                <span className='text-xs'>Address / Contact Info</span>
                            </div>
                            <div className='text-right'>
                                <span className='text-xs text-[#60737D]'>Invoice No.</span>
                                <h1 className='text-lg mb-0'>#000123</h1>

                                <span className='text-xs block text-[#60737D] mt-3 mb-1'>Issued on</span>
                                <span className='text-xs block text-[#121722]'>December 7, 2022.</span>

                                <span className='text-xs block text-[#60737D] mt-3 mb-1'>Payment Due</span>
                                <span className='text-xs block text-[#121722]'>December 22, 2022.</span>
                            </div>
                        </div>
                    </div>

                    <div className="my-5 px-4 table-responsive pb-5">
                        <table width='100%' cellPadding={5}>
                            <tr>
                                <td style={{ minWidth: '200px' }} className='text-[#121722] font-semibold'>Services</td>
                                <td style={{ minWidth: '100px' }} className='text-[#60737D]'>Qty</td>
                                <td style={{ minWidth: '100px' }} className='text-[#60737D]'>Price</td>
                                <td style={{ minWidth: '100px' }} className='text-right'>Total</td>
                            </tr>
                            <tr>
                                <td>Invoice Item 1</td>
                                <td className='text-[#60737D]'>1</td>
                                <td className='text-[#60737D]'>4,000.00</td>
                                <td className='text-right'>4,000.00</td>
                            </tr>
                        </table>
                    </div>

                    <span className="bg-[#EAEBF5] px-[20px] w-[350px] mb-[-30px] ms-auto flex items-center rounded-xl justify-between py-3">
                        <span>Total <span>(USD)</span></span>
                        <span className='text-lg font-semibold'>4,000.00</span>
                    </span>

                    <div className="border-t py-5 px-4">
                        <img src="/assets/images/logos.svg" className='w-[150px] mb-3' alt="" />
                        <div className="row gx-5">
                            <div className="col-4">
                                <p className='mb-2'>Address / Contact Info</p>
                                <a href="#email" className='text-theme-color text-sm'>email@company.com</a>
                                <span className='block text-sm mt-2 text-[#60737D]'>ID#1 Label</span>
                                <span className='block text-sm'>1234567890-123</span>
                                <span className='block text-sm mt-2 text-[#60737D]'>ID#2 Label</span>
                                <span className='block text-sm'>ABC-0987654321</span>
                            </div>
                            <div className="col-5">
                                <p className='mb-2 font-semibold'>Payment Instructions</p>
                                <p className='mb-0 text-sm text-[#60737D]'>Voluptas nisi aut. Est vitae dolore molestias porro praesentium. Tempore recusandae voluptatem necessitatibus corporis inventore neque magnam ut.</p>
                                <span className='block mt-2 text-sm text-[#60737D]'>ID#1 Label</span>
                                <span className='block text-sm'>1234567890-123</span>
                                <span className='block mt-2 text-sm text-[#60737D]'>ID#2 Label</span>
                                <span className='block text-sm'>ABC-0987654321</span>
                            </div>
                            <div className="col-3">
                                <p className='mb-2 font-semibold'>Additional Notes</p>
                                <span className='block mt-2 text-sm text-[#60737D]'>Have a great day</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PurchaseHistory