import React from 'react'
import { AiOutlineInfoCircle } from "react-icons/ai";

const PaymentDetails = ({ space, text, border }) => {
    return (
        <div>
            <form className={space}>
                <h1 className={`${border} md:text-[28px] mb-0 text-xl`}>{text}</h1>
                <div className='row mt-5'>
                    <div className='col-8'>
                        <div className='row gy-4 gx-5'>
                            <div className='col-6'>
                                <label className='flex items-center gap-2 mb-2'>
                                    Organization name
                                    <div className='relative payment_tooltip'>
                                        <AiOutlineInfoCircle />
                                        <span className='absolute w-[300px] bg-[#616161] z-20 text-white top-[-50px] text-xs py-2 px-3 rounded-lg'>This represents the registered name of your company or entity.</span>
                                    </div>
                                </label>
                                <input type="text" className='border rounded-lg px-3 py-2 w-full outline-none' />
                                <p className='text-xs mt-1'>This is the name registered under your tax ID.</p>
                            </div>
                            <div className='col-6'>
                                <label className='flex items-center gap-2 mb-2'>Organization address
                                    <div className='relative payment_tooltip'>
                                        <AiOutlineInfoCircle />
                                        <span className='absolute w-[300px] bg-[#616161] z-20 text-white top-[-50px] text-xs py-2 px-3 rounded-lg'>The address displayed on your organization's invoices, update in payments.</span>
                                    </div>
                                </label>
                                <input type="text" className='border rounded-lg px-3 py-2 w-full outline-none' />
                                <p className='text-xs mt-1'>Enter your company address</p>
                            </div>
                            <div className='col-6'>
                                <label className='flex items-center gap-2 mb-2'>Email address
                                    <div className='relative payment_tooltip'>
                                        <AiOutlineInfoCircle />
                                        <span className='absolute w-[300px] bg-[#616161] z-20 text-white top-[-50px] text-xs py-2 px-3 rounded-lg'>This is the email address associated with this profile.</span>
                                    </div>
                                </label>
                                <input type="email" className='border rounded-lg px-3 py-2 w-full outline-none' />
                            </div>
                        </div>
                        <h1 className='text-[24px] mt-5 flex items-center gap-1'>Tax Info</h1>
                        <p className='mb-5 text-sm mt-1'>This refers to your tax ID number in the country where your business is established, like your VAT, GST, or business registration number.</p>

                        <div className='row gy-4 gx-5'>
                            <div className='col-6'>
                                <label className='block mb-2'>Tax Status</label>
                                <input type="text" className='border rounded-lg px-3 py-2 w-full outline-none' />
                            </div>
                            <div className='col-6'>
                                <label className='block mb-2'>PAN(optional)</label>
                                <input type="text" className='border rounded-lg px-3 py-2 w-full outline-none' />
                                <p className='text-xs mt-1'>Example: ABCDE1234A</p>
                            </div>
                            <div className='col-6'>
                                <label className='block mb-2'>TAN(optional)</label>
                                <input type="text" className='border rounded-lg px-3 py-2 w-full outline-none' />
                                <p className='text-xs mt-1'>Example: AGRB12986B</p>
                            </div>
                            <div className='col-6'>
                                <label className='block mb-2'>GST identification number (GSTIN)</label>
                                <input type="text" className='border rounded-lg px-3 py-2 w-full outline-none' />
                                <p className='text-xs mt-1'>Example: 12ABCDE1234A9Z9</p>
                            </div>
                            <div className='col-12 text-end'>
                                <button className='me-3 border-[1px] border-theme-color mt-5 w-[100px] text-theme-color px-4 rounded-pill py-[5px]'>Cancel</button>
                                <button className='bg-theme-color border-[1px] border-theme-color mt-5 w-[100px] text-white px-4 rounded-pill py-[5px]'>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default PaymentDetails