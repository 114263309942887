import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { GoPlus } from "react-icons/go";
import CoinPrice from "../../common/CoinPrice";
import UploadImageModal from "../../common/UploadImageModal";
import { HiOutlineBars3 } from "react-icons/hi2";
import SideBar from "./SideBar";
import { VscFeedback } from "react-icons/vsc";
import FeedbackModal from "../../common/FeedbackModal";
import { Nav, Offcanvas } from "react-bootstrap";
import ReferAccount from "../../components/refer-account/ReferAccount";
import Logout from "../../common/Logout";
import NotificationModal from "../../common/NotificationModal";
import { useNavigate } from "react-router-dom";

const Header1 = ({ collapseToggle }) => {
  const dropdownRef = useRef(null);
  const createButtonRef = useRef(null);
  const [profile, setProfile] = useState(false);
  const [show, setShow] = useState(false);
  const [showBar, setShowBar] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);
  const [createMenu, setcreateMenu] = useState(false);
  const [show1, setShow1] = useState(false);
  const navigate = useNavigate()
  const toggle = () => {
    setShow1(!show1);
  };

  const [notifications, setNotifications] = useState(false);
  const notificationToggle = () => {
    setNotifications(!notifications);
  };

  const [logOut, setLogOut] = useState(false);
  const logOutToggle = () => {
    setLogOut(!logOut);
    setProfile(false);
  };

  const [shows1, setShows1] = useState(false);
  const toggles = () => {
    setShows1(!shows1);
    setProfile(false);
  };

  const barToggle = () => {
    setShowBar(!showBar);
  };
  const showToggle = () => {
    setShow(!show);
  };
  const uploadToggle = () => {
    setUploadImage(!uploadImage);
  };
  const menuToggle = () => {
    setSideMenu(!sideMenu);
  };
  const [activeDropdown, setActiveDropdown] = useState("");

  const createToggle = () => {
    
    if (activeDropdown === "create") {
      setActiveDropdown("");
      setcreateMenu(false);
    } else {
      setActiveDropdown("create");
      setcreateMenu(true);
      setProfile(false);
    }
  };

  const profileToggle = () => {
    if (activeDropdown === "profile") {
      setActiveDropdown("");
      setProfile(false);
    } else {
      setActiveDropdown("profile");
      setProfile(true);
      setcreateMenu(false);
    }
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      createButtonRef.current &&
      !createButtonRef.current.contains(event.target)
    ) {
      setActiveDropdown("");
      setcreateMenu(false);
      setProfile(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const socialLinks = [
    {
      name: "LinkedIn",
      image: "/assets/images/home/linkedin.png",
      alt: "linkedin",
      size:" 1080x1080",
      trending: true,
    },
    {
      name: "Facebook",
      image: "/assets/images/home/facebook.png",
      alt: "facebook",
      size:"1200x630",
      trending: true,
    },
    {
      name: "Instagram",
      image: "/assets/images/home/instagram.png",
      alt: "instagram",
      size:" 1080x1350",

      trending: true,
    },
    {
      name: "Whatsapp",
      image: "/assets/images/home/whatsapp.png",
      alt: "whatsapp",
      trending: true,
      size:"192x192",

    },
    {
      name: "Youtube",
      image: "/assets/images/home/youtube.png",
      alt: "youtube",
      size:"2560x1440",
      trending: false,
    },
    {
      name: "Pinterest",
      image: "/assets/images/home/pinterest.png",
      alt: "pinterest",
      size:"1200x630",
      trending: false,
    },
    {
      name: "Twitter",
      image: "/assets/images/home/twitter.png",
      size:"1200 x 675 ",
      alt: "twitter",
      trending: true,
    },
    {
      name: "Amazon",
      image: "/assets/images/home/amazon.png",
      size:"1200x630",
      alt: "amazon",
      trending: false,
    },
    {
      name: "Snapchat",
      image: "/assets/images/home/snapchat.png",
      size:"1200x630",
      alt: "snapchat",
      trending: false,
    },
    {
      name: "Display Ads",
      image: "/assets/images/home/ad.png",
      alt: "ad",
      size:"1200x630",
      trending: false,
    },
  ];
  const handleMediaNavigate = (name,size)=>{
    console.log(name,size);
    
    navigate(`/create/${name}/${size}`)
    console.log("hit");
    

  }

  return (
    <div className="bg-white border-b-[1px] border-b-[#00000033] fixed top-0 left-0 w-full z-30">
      <Navbar expand="lg " className="py-0">
        <Container fluid className="!px-[16px]">
          <div className="flex items-center flex-grow">
            <div
              onClick={menuToggle}
              className="w-[40px] me-md-3 h-[40px] group rounded-full flex justify-center items-center hover:bg-light-purple cursor-pointer duration-300"
            >
              <HiOutlineBars3 className="cursor-pointer text-2xl" />
            </div>
            <Navbar.Brand
              href="/"
              className="select-none me-0 flex-grow md:flex-grow-0 d-flex gap-1 justify-content-sm-start items-center justify-content-center"
            >
              <img
                src="/assets/images/logos.svg"
                className="w-[150px]"
                alt="logo"
              />
              <div
                className="text-theme-color bg-light-purple py-[2px] px-[8px] font-[600] text-[12px] rounded-pill"
                style={{ letterSpacing: "1px" }}
              >
                BETA
              </div>
            </Navbar.Brand>
          </div>
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          <div className="flex items-center gap-3">
            <div className="relative d-sm-block hidden">
              <button
                ref={createButtonRef}
                onClick={createToggle}
                className="select-none flex items-center bg-theme-color text-white gap-2 border-[1px] border-theme-color px-[12px] py-[5px] rounded-full text-[14px]"
              >
                <GoPlus className="text-xl" />
                Create
              </button>
              <div
                ref={dropdownRef}
                className={`${
                  createMenu ? "opacity-100 visible" : "opacity-0 invisible"
                } duration-300 absolute bg-white w-[250px] mt-2 h-[340px] right-0 py-3 rounded-lg shadow overflow-auto`}
              >
                <span className="text-black/50 text-[12px] font-medium px-3">
                  Create with Zunno
                </span>
                {socialLinks.map((link, index) => (
                  <div
                    key={index}
                    className="h-[48px] flex items-center justify-between cursor-pointer hover:bg-[#F5F6F6] px-3"
                  >
                    <div className="flex items-center gap-2 text-sm" 
                    onClick={()=>handleMediaNavigate(link.name,link.size)}
                    >
                      <img
                        src={link.image}
                        alt={link.alt}
                        className="w-[20px]"
                      />
                      {link.name}
                    </div>
                    {link.trending && (
                      <i className="text-theme-color bg-light-purple py-1 px-2 text-[10px] rounded-pill">
                        Trending
                      </i>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div
              onClick={showToggle}
              className="flex cursor-pointer relative group text-sm items-center gap-1"
            >
              <img
                src="/assets/images/home/coin.svg"
                className="w-[24px]"
                alt="coin"
              />
              20
              <div className="bg-[#616161] p-2 rounded-lg text-[12px] w-[180px] duration-300 opacity-0 invisible group-hover:!opacity-100 group-hover:!visible d-md-block d-none text-white absolute -right-10 top-[170%]">
                You have 20 boosts left today
              </div>
            </div>

            <div
              onClick={notificationToggle}
              className="group rounded-full d-sm-flex hidden justify-center items-center relative cursor-pointer duration-300"
            >
              <img src="/assets/images/notification.png" className="w-[20px]" />
              <div className="bg-[#616161] p-2 rounded-lg text-[12px] duration-300 opacity-0 invisible group-hover:!opacity-100 group-hover:!visible text-white absolute top-[195%]">
                Notifications
              </div>
            </div>

            <div
              onClick={toggle}
              className="text-black relative cursor-pointer group d-sm-flex hidden items-center justify-center rounded-full"
            >
              <img
                src="/assets/images/feedback.svg"
                className="w-[20px]"
                alt="feedback"
              />
              <div className="bg-[#616161] p-2 rounded-lg text-[12px] duration-300 opacity-0 invisible group-hover:!opacity-100 group-hover:!visible text-white absolute top-[180%]">
                Feedback
              </div>
            </div>

            <div className="relative d-sm-block hidden" ref={dropdownRef}>
              <div
                className="w-[32px] h-[32px] border-[1px] border-theme-color font-bold text-theme-color rounded-full flex items-center justify-center cursor-pointer"
                onClick={profileToggle}
              >
                J
              </div>

              <div
                className={`${
                  profile ? "opacity-100 visible" : "opacity-0 invisible"
                } duration-500 bg-white shadow w-[300px] p-3 absolute top-full mt-2 rounded-lg right-0`}
              >
                <div className="flex items-center gap-3 border-b pb-3 mb-3">
                  <div className="w-[50px] h-[50px] text-xl border-[1px] border-theme-color font-bold text-theme-color rounded-full flex items-center justify-center cursor-pointer">
                    J
                  </div>
                  <div className="flex flex-col">
                    <b>Jemas Scalf</b>
                    <span>jemas.scalf09@gmail.com</span>
                  </div>
                </div>
                {/* <a href='#new' className='text-black flex items-center rounded-lg my-1 h-[40px]  px-[12px] hover:bg-light-theme'>What's new</a> */}
                <a
                  href="/accounts"
                  className="text-black flex items-center rounded-lg my-1 h-[40px]  px-[12px] hover:bg-light-theme"
                >
                  Account
                </a>
                <a
                  href="/purchase-history"
                  className="text-black flex items-center rounded-lg my-1 h-[40px]  px-[12px] hover:bg-light-theme"
                >
                  Billing Central
                </a>
                <a
                  href="#report"
                  className="text-black flex items-center rounded-lg my-1 h-[40px]  px-[12px] hover:bg-light-theme"
                >
                  Report content
                </a>
                <a
                  href="#report"
                  className="text-black flex items-center rounded-lg my-1 h-[40px]  px-[12px] hover:bg-light-theme"
                >
                  About Us
                </a>
                <a
                  onClick={toggles}
                  href="#refer"
                  className="text-black flex items-center rounded-lg my-1 h-[40px]  px-[12px] hover:bg-light-theme"
                >
                  Refer Zunno
                </a>
                <a
                  href="/setting"
                  className="text-black flex items-center rounded-lg my-1 h-[40px]  px-[12px] hover:bg-light-theme"
                >
                  Settings
                </a>
                <div className="my-1 border-t"></div>
                <a
                  href="#logout"
                  onClick={logOutToggle}
                  className="text-black flex items-center rounded-lg py-1 h-[40px]  px-[12px] hover:bg-light-theme"
                >
                  Sign out of Zunno
                </a>
              </div>
            </div>

            <div onClick={barToggle} className="d-sm-none">
              <img src="/assets/images/dots.svg" alt="dots" />
            </div>
          </div>
        </Container>
      </Navbar>

      <CoinPrice show={show} showToggle={showToggle} />
      <UploadImageModal uploadImage={uploadImage} uploadToggle={uploadToggle} />
      <SideBar
        sideMenu={sideMenu}
        menuToggle={menuToggle}
        setSideMenu={setSideMenu}
      />
      <FeedbackModal show={show1} toggle={toggle} />
      <ReferAccount shows1={shows1} toggles={toggles} />
      <Logout logOut={logOut} logOutToggle={logOutToggle} />
      <NotificationModal
        notifications={notifications}
        notificationToggle={notificationToggle}
      />

      {/* Bar Toggle */}
      {/* <Offcanvas placement='bottom' show={showBar} onHide={barToggle} className="bar_button">
                <Offcanvas.Body>
                    <Nav className="d-block">
                        <Nav.Link href="/create" className='text-black h-[48px] px-4 !flex gap-3 items-center'><img src='/assets/images/create.svg' className='grayscale' alt='home' />Create</Nav.Link>
                        <Nav.Link href="#upload" className='text-black h-[48px] px-4 !flex gap-3 items-center'><img src='/assets/images/upload.svg' alt='about' />Upload</Nav.Link>
                        {/* <Nav.Link href="/plans-pricing" className='text-black h-[48px] px-4 !flex gap-3 items-center'><img src='/assets/images/icon4.svg' alt='price' />Pricing Plans</Nav.Link>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas> */}
    </div>
  );
};

export default Header1;
