import React from 'react'
import PhoneInput from "react-phone-input-2";
import { CountryDropdown } from 'react-country-region-selector';
import "react-phone-input-2/lib/style.css";

const ContactPage = () => {
  return (
    <section className='py-[50px]'>
      <div className='container-fluid md:!px-[60px] !px-[16px]'>
        <div className='row gy-4'>
          <div className='col-xl-7 col-lg-6'>
            <h1 className='mb-4 md:text-[35px] text-[24px]'>Contact Our Team</h1>
            <p>We can give you a demo, help you choose the best plan, or create a personalized package to help your organization get the most out of Canva.</p>
            <p>For technical or product support email us at <a href='mailto:support@zunno.io'>support@zunno.io</a></p>
          </div>
          <div className='col-xl-5 col-lg-6'>
            <form className='bg-white shadow rounded-xl p-4'>
              <div className='row gy-4'>
                <div className='col-sm-6'>
                  <label>First Name <span className='text-danger'>*</span></label>
                  <input type='text' placeholder='John' className='w-full border-[1px] rounded py-2 px-3 mt-1 outline-none focus:border-theme-color' />
                </div>
                <div className='col-sm-6'>
                  <label>Last Name <span className='text-danger'>*</span></label>
                  <input type='text' placeholder='Smith' className='w-full border-[1px] rounded py-2 px-3 mt-1 outline-none focus:border-theme-color' />
                </div>
                <div className='col-12'>
                  <label>Company Email<span className='text-danger'>*</span></label>
                  <input type='email' placeholder='john.smith@gmail.com' className='w-full border-[1px] rounded py-2 px-3 mt-1 outline-none focus:border-theme-color' />
                </div>
                <div className='col-sm-6'>
                  <label>Company <span className='text-danger'>*</span></label>
                  <input type='text' placeholder='Acme' className='w-full border-[1px] rounded py-2 px-3 mt-1 outline-none focus:border-theme-color' />
                </div>
                <div className='col-sm-6'>
                  <label>Company Size<span className='text-danger'>*</span></label>
                  <select className='w-full border-[1px] rounded py-2 px-3 mt-1 outline-none focus:border-theme-color'>
                    <option value="" disabled selected>Select company size</option>
                    <option value="1">1</option>
                    <option value="1">2 - 9</option>
                    <option value="1">10 - 99</option>
                    <option value="1">100 - 499</option>
                    <option value="1">500 - 999</option>
                    <option value="1">1000 - 1999</option>
                    <option value="1">2000+</option>
                  </select>
                </div>
                <div className='col-12'>
                  <label>Country<span className='text-danger'>*</span></label>
                  <CountryDropdown name='country' classes='w-full border-[1px] rounded py-2 px-3 mt-1 outline-none focus:border-theme-color'/>                  
                </div>                              
                <div className='col-12'>
                  <label>Phone<span className='text-danger'>*</span></label>
                  <PhoneInput className="number mt-1 w-100 outline-none focus:border-theme-color" name="number" country={"in"} placeholder='xxx-xxx-xxxx' />
                  {/* <input type='tel' placeholder='Cell phone number' className='w-full border-[1px] rounded py-2 px-3 mt-1' /> */}
                </div>
                <div className='col-12'>
                  <label>What’s your enquiry about?<span className='text-danger'>*</span></label>
                  <textarea rows={3} placeholder='' className='w-full border-[1px] rounded py-2 px-3 mt-1 outline-none focus:border-theme-color'></textarea>
                </div>                
                <p className='text-sm'>By submitting this form, you agree to receive marketing and information from Canva. You can opt out at any time by clicking unsubscribe. Canva handles your information as described in our <a href='/privacy-policy' className='text-theme-color !underline'>Privacy Policy.</a></p>
                <button className='bg-theme-color text-white rounded-lg py-3 w-full mt-4'>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactPage