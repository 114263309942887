import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const Option2 = ({ handleNext, handlePrev, formData }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [localData, setLocalData] = useState(formData);
    console.log('localData', localData);

    const options = [
        { value: 'retail', label: 'Retail' },
        { value: 'hospitality', label: 'Hospitality' },
        { value: 'Beauty and Personal Care', label: 'Beauty and Personal Care' },
        { value: ' Courier and Logistics Services industry', label: ' Courier and Logistics Services industry' },
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLocalData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    useEffect(() => {
        setLocalData(formData);
      }, [formData]);

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setLocalData((prev) => ({
            ...prev,
            industry: selectedOption?.value,
        }));
    };

    const handleNextClick = () => {
        handleNext(localData);
    };

    return (
        <>
            <div className='col-lg-8 border-b pb-4'>
                <div className='flex items-center justify-between'>
                    <div>
                        <h1 className='md:text-[28px] mb-3 text-xl'>Audience</h1>
                        <p className='mb-0'>
                            Select push and email notifications that you'd like to
                            receive
                        </p>
                    </div>
                    <img
                        src='/assets/images/brand-kit/Audience.svg'
                        className='w-[250px]'
                        alt='Audience'
                    />
                </div>
            </div>
            <div className='col-lg-8'>
                <label className='my-[16px] font-semibold'>Industry Type</label>
                <Select
                    defaultValue={selectedOption}
                    onChange={handleSelectChange}
                    options={options}
                    className='outline-none'
                />
                <span className='text-[#737373] text-[12px] py-1 rounded'>
                    Specify the business sector (e.g., retail, hospitality, tech)
                    to generate relevant content.
                </span>
            </div>
            <div className='col-lg-8'>
                <label className='my-[16px] font-semibold'>Target Audience</label>
                <div className='w-full border rounded-lg p-3'>
                    <div className='mb-3'>
                        <label className='mb-2'>Demographics : </label>
                        <input
                            type='text'
                            name='demographic'
                            value={localData.demographic || ''}
                            onChange={handleInputChange}
                            placeholder='Enter demographics'
                            className='w-full border rounded-lg px-3 py-2'
                        />
                    </div>
                    <div>
                        <label className='mb-2'>Psychographics : </label>
                        <input
                            type='text'
                            name='psychographic'
                            value={localData.psychographic || ''}
                            onChange={handleInputChange}
                            placeholder='Enter psychographics'
                            className='w-full border rounded-lg px-3 py-2'
                        />
                    </div>
                </div>
                <span className='text-[#737373] text-[12px] py-1 rounded'>
                    Define the demographics (age, gender, location) and
                    psychographics (interests, behaviors) to shape the tone and
                    style of the creative.
                </span>
            </div>
            <div className='col-lg-8'>
                <label className='my-[16px] font-semibold'>Audience Interests</label>
                <input
                    type='text'
                    name='audienceInterest'
                    value={localData.audienceInterest || ''}
                    onChange={handleInputChange}
                    placeholder='Enter audience interests'
                    className='w-full border rounded-lg px-3 py-2'
                />
                <span className='text-[#737373] text-[12px] py-1 rounded'>
                    Ask for keywords related to audience interests to fine-tune the content.
                </span>
            </div>
            <div className='col-lg-8 text-end mt-5'>
                <button
                    type='button'
                    onClick={handlePrev}
                    className='text-theme-color me-3 border-[1px] border-theme-color px-4 rounded-pill py-[5px]'
                >
                    Prev
                </button>
                <button
                    type='button'
                    onClick={handleNextClick}
                    className='bg-theme-color border-[1px] border-theme-color text-white px-4 rounded-pill py-[5px]'
                >
                    Next
                </button>
            </div>
        </>
    );
};

export default Option2;
