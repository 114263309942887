import React from 'react'

const Index = () => {
  return (
    <div className='h-full bg-[#FAFAFA] flex flex-col justify-center items-center'>
      <img src="/assets/images/subscription.svg" width='80px' className='mb-3' alt="img" />
      <h1 className='md:text-[24px] text-black/50 mb-0 text-xl'>You haven't created any projects yet.</h1>
    </div>
  )
}

export default Index