import React, { useEffect, useState } from 'react'
import AboutValues from './AboutValues';

const AboutPage = () => {

    const logo = [
        { id: 1, img: '/assets/images/creation/asset 1.webp' },
        { id: 2, img: '/assets/images/creation/asset 16.webp' },
        { id: 3, img: '/assets/images/creation/asset 17.webp' },
        { id: 4, img: '/assets/images/creation/asset 18.webp' },
        { id: 5, img: '/assets/images/creation/asset 20.webp' },
        { id: 6, img: '/assets/images/creation/asset 22.webp' },
        { id: 7, img: '/assets/images/creation/asset 23.webp' },
        { id: 8, img: '/assets/images/creation/asset 27.webp' },
        { id: 9, img: '/assets/images/creation/asset 28.webp' },
        { id: 10, img: '/assets/images/creation/asset 29.webp' },
        { id: 1, img: '/assets/images/creation/asset 1.webp' },
        { id: 2, img: '/assets/images/creation/asset 16.webp' },
        { id: 3, img: '/assets/images/creation/asset 17.webp' },
        { id: 4, img: '/assets/images/creation/asset 18.webp' },
        { id: 5, img: '/assets/images/creation/asset 20.webp' },
        { id: 6, img: '/assets/images/creation/asset 22.webp' },
        { id: 7, img: '/assets/images/creation/asset 23.webp' },
        { id: 8, img: '/assets/images/creation/asset 27.webp' },
        { id: 9, img: '/assets/images/creation/asset 28.webp' },
        { id: 10, img: '/assets/images/creation/asset 29.webp' },
    ]

    const texts = [
        'A mountain village lake shot on Hasselblad...',
        'A serene forest path at dawn...',
        'A bustling cityscape at night...',
        'A tranquil beach during sunset...',
        'A snowy peak under a clear sky...',
    ];

    const [currentTextIndex, setCurrentTextIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 2000);

        return () => clearInterval(intervalId);
    }, [texts.length]);

    return (
        <section className=''>
            <div className='relative'>
                <h1 className='md:text-[40px] text-[24px] pt-[50px] text-gradient mb-5 text-center col-xl-5 col-md-7 col-10 mx-auto font-bold'>Unlesh your creativity with Zunno AI</h1>
                <div className='mt-5'>
                    <div class="slider about_slider">
                        <div class="slide-track slide-track1">
                            {
                                logo.map((res) => {
                                    return (
                                        <>
                                            <div className="item rounded-xl" key={res.id}>
                                                <img src={res.img} className='rounded-xl' alt="images not found" />
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <img src='/assets/images/about/lines.png' className='w-full h-full object-cover absolute top-0 left-0 z-[-1] invert' alt='lines' />
            </div>
            <div className='container-fluid md:!px-[60px] !px-[16px]'>
                <div className='row justify-center'>
                    <div className='col-12'>
                        {/* <p className='text-center text-2xl text-[#0006] font-medium'>Interset shown by</p>
                        <div className='row gy-4'>
                            <div className="col-4">
                                <div className='bg-[#f6f5f4] aspect-video flex items-center justify-center'>
                                    <img src='/assets/images/logo9.png' className='!w-[120px] mx-auto grayscale' alt="images not found" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className='bg-[#f6f5f4] aspect-video flex items-center justify-center'>
                                    <img src='/assets/images/logo9.png' className='!w-[120px] mx-auto grayscale' alt="images not found" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className='bg-[#f6f5f4] aspect-video flex items-center justify-center'>
                                    <img src='/assets/images/logo9.png' className='!w-[120px] mx-auto grayscale' alt="images not found" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className='bg-[#f6f5f4] aspect-video flex items-center justify-center'>
                                    <img src='/assets/images/logo9.png' className='!w-[120px] mx-auto grayscale' alt="images not found" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className='bg-[#f6f5f4] aspect-video flex items-center justify-center'>
                                    <img src='/assets/images/logo9.png' className='!w-[120px] mx-auto grayscale' alt="images not found" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className='bg-[#f6f5f4] aspect-video flex items-center justify-center'>
                                    <img src='/assets/images/logo9.png' className='!w-[120px] mx-auto grayscale' alt="images not found" />
                                </div>
                            </div>
                        </div> */}

                        <h1 className='md:text-[24px] text-[#0006] text-base mb-3 mt-md-5 mt-3'>About Zunno</h1>
                        <h1 className='mb-4 md:text-[40px] text-[24px]'>The story behind ...</h1>
                        <p className='text-lg'>We believe in a future of creative tools where AI empowers creatives to do their best work with less stress. We’re here to build it in collaboration with the creative community.</p>

                        <p className='text-lg'>Being a creative professional is particularly stressful in today’s world. Budgets are tight, craft is not always valued, AI is on the horizon, and creatives are often being asked to do more with less time and fewer resources. We see a path where AI can provide creatives with the assistance that will allow creatives to freely explore the question of “what if?” and focus on what they love most in their creative process.</p>

                        <p className='text-lg'>We believe in a future of creative tools where AI empowers creatives to do their best work with less stress. We’re here to build it in collaboration with the creative community.</p>

                        <p className='text-lg'>Being a creative professional is particularly stressful in today’s world. Budgets are tight, craft is not always valued, AI is on the horizon, and creatives are often being asked to do more with less time and fewer resources. We see a path where AI can provide creatives with the assistance that will allow creatives to freely explore the question of “what if?” and focus on what they love most in their creative process.</p>

                    </div>

                    <AboutValues />

                    <div className='mb-5'>
                        <h1 className='text-center text-black md:text-[35px] text-[24px]'>Start generating now</h1>
                        <p className='text-center text-black'>Facet is free to try in your browser—nothing to install, no waitlist, no credit card, no roadblocks!</p>
                        <div className='col-xl-7 col-lg-9 mx-auto  mt-5'>
                            <div className='bg-black p-4 rounded-lg d-sm-flex items-center justify-between text-white'>
                                <p className='mb-sm-0 mb-3 md:text-lg text-base'>{texts[currentTextIndex]}</p>
                                <a href='/sign-in' className='bg-gradients text-center d-sm-inline-block d-block rounded-lg text-white px-4 py-2'>Sign up for free</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default AboutPage