import React, { useState } from 'react'
import { MdOutlineUnfoldMore } from "react-icons/md";
import { MdUnfoldLess } from "react-icons/md";

const FaqsPage = () => {
    // const [expandAll, setExpandAll] = useState(false);

    // const handleExpandAll = () => {
    //     setExpandAll(!expandAll);
    // };
    return (
        <section className='py-[50px]'>
            <div className='container-fluid md:!px-[60px] px-[16px]'>
                <div className='text-center'>
                    <h1 className='md:text-[35px] text-[24px]'>Frequently Asked Questions</h1>
                </div>
                <div className="partner_faq faq_page pt-[50px]">
                    <div className="container">
                        {/* <div className='flex justify-end mb-3 cursor-pointer'>
                            <span className='flex items-center gap-2 font-semibold text-[#271A62]' onClick={handleExpandAll}>{!expandAll ? <> Expand all <MdOutlineUnfoldMore /> </> : <>Collapse all <MdUnfoldLess /> </>}</span>
                        </div> */}

                        <div className="accordion faqs" id="softwareFaq">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className={`accordion-button`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#softwareOne"
                                        // aria-expanded={expandAll}
                                        aria-controls="softwareOne">
                                        How do you ensure the quality of your software solutions?
                                    </button>
                                </h2>
                                <div id="softwareOne" className={`accordion-collapse collapse show `} data-bs-parent="#softwareFaq">
                                    <div className="accordion-body visible">
                                        ActiveCampaign is a category-defining Customer Experience Automation Platform that helps over 150,000 businesses in 170+ countries meaningfully connect and engage with their customers. It creates optimized customer experiences by automating many behind-the-scenes marketing, sales, and support processes.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className={`accordion-button collapsed`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#softwareTwo"
                                        // aria-expanded={expandAll}
                                        aria-controls="softwareTwo">
                                        What industries do you specialize in for software development?
                                    </button>
                                </h2>
                                <div id="softwareTwo" className={`accordion-collapse collapse`} data-bs-parent="#softwareFaq">
                                    <div className="accordion-body visible">
                                        Our team has experience across various industries, including healthcare, finance, retail, manufacturing, and more, ensuring tailored solutions for each sector.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className={`accordion-button collapsed`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#softwareThree"
                                        aria-controls="softwareThree">
                                        What is your development process?
                                    </button>
                                </h2>
                                <div id="softwareThree" className={`accordion-collapse collapse`} data-bs-parent="#softwareFaq">
                                    <div className="accordion-body visible">
                                        You don’t need to be a developer or a designer to use ActiveCampaign. Anyone can create email marketing campaigns and marketing automations on our platform. Our Automation Recipe Library contains hundreds of pre-built automations — no coding skills are required.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className={`accordion-button collapsed`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#softwareFour"
                                        // aria-expanded={expandAll}
                                        aria-controls="softwareFour">
                                        How do you stay updated with the latest technologies?
                                    </button>
                                </h2>
                                <div id="softwareFour" className={`accordion-collapse collapse`} data-bs-parent="#softwareFaq">
                                    <div className="accordion-body visible">
                                        Our team continually learns and adapts to the latest technological advancements, ensuring we use cutting-edge tools and methodologies in all our projects.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className={`accordion-button collapsed`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#softwareFive"
                                        // aria-expanded={expandAll}
                                        aria-controls="softwareFive">
                                        What industries do you specialize in for software development?
                                    </button>
                                </h2>
                                <div id="softwareFive" className={`accordion-collapse collapse`} data-bs-parent="#softwareFaq">
                                    <div className="accordion-body visible">
                                        Our team has experience across various industries, including healthcare, finance, retail, manufacturing, and more, ensuring tailored solutions for each sector.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className={`accordion-button collapsed`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#softwaresix"
                                        // aria-expanded={expandAll}
                                        aria-controls="softwaresix">
                                        What is your development process?
                                    </button>
                                </h2>
                                <div id="softwaresix" className={`accordion-collapse collapse`} data-bs-parent="#softwareFaq">
                                    <div className="accordion-body visible">
                                        You don’t need to be a developer or a designer to use ActiveCampaign. Anyone can create email marketing campaigns and marketing automations on our platform. Our Automation Recipe Library contains hundreds of pre-built automations — no coding skills are required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FaqsPage