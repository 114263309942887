import React, { useEffect, useState } from 'react';

const Option4 = ({ handleNext, handlePrev, formData }) => {
    const [localData, setLocalData] = useState(formData);
    const [competitorInput, setCompetitorInput] = useState('');
    const [benchmarkInput, setBenchmarkInput] = useState('');

    // Update competitor brands
    useEffect(() => {
        setLocalData(formData);
    }, [formData]);
    const handleCompetitorAdd = (e) => {
        if (e.key === 'Enter' && competitorInput.trim()) {
            setLocalData((prev) => ({
                ...prev,
                competitorBrands: [...(prev.competitorBrands || []), competitorInput.trim()],
            }));
            setCompetitorInput(''); // Reset input
        }
    };

    // Update benchmark brands
    const handleBenchmarkAdd = (e) => {
        if (e.key === 'Enter' && benchmarkInput.trim()) {
            setLocalData((prev) => ({
                ...prev,
                benchmarkBrands: [...(prev.benchmarkBrands || []), benchmarkInput.trim()],
            }));
            setBenchmarkInput(''); // Reset input
        }
    };

    // Remove a competitor brand
    const removeCompetitorBrand = (index) => {
        const updatedCompetitorBrands = localData.competitorBrands.filter((_, i) => i !== index);
        setLocalData((prev) => ({
            ...prev,
            competitorBrands: updatedCompetitorBrands,
        }));
    };

    // Remove a benchmark brand
    const removeBenchmarkBrand = (index) => {
        const updatedBenchmarkBrands = localData.benchmarkBrands.filter((_, i) => i !== index);
        setLocalData((prev) => ({
            ...prev,
            benchmarkBrands: updatedBenchmarkBrands,
        }));
    };

    const handleNextClick = () => {
        handleNext(localData); // Pass updated localData to parent
    };

    return (
        <>
            <div className='col-lg-8 border-b pb-4'>
                <div className='flex items-center justify-between'>
                    <div>
                        <h1 className='md:text-[28px] mb-3 text-xl'>Competitors & Benchmarking</h1>
                        <p className='mb-0'>Select push and email notifications that you'd like to receive</p>
                    </div>
                    <img src='/assets/images/brand-kit/Competitor.svg' className='w-[250px]' />
                </div>
            </div>
            <div className='col-lg-8'>
                <label className='my-[16px] font-semibold'>Competitor Brands</label>
                <input
                    type='text'
                    value={competitorInput}
                    onChange={(e) => setCompetitorInput(e.target.value)}
                    onKeyDown={handleCompetitorAdd}
                    placeholder='Add a competitor and press Enter'
                    className='w-full border rounded-lg px-3 py-2'
                />
                <div className='flex flex-wrap mt-2'>
                    {localData.competitorBrands && localData.competitorBrands.map((brand, index) => (
                        <span
                            key={index}
                            className='bg-gray-200 rounded-full px-3 py-1 text-sm mr-2 mb-2'>
                            {brand}
                            <button
                                type='button'
                                onClick={() => removeCompetitorBrand(index)}
                                className='ml-2 text-red-500'>&times;</button>
                        </span>
                    ))}
                </div>
            </div>
            <div className='col-lg-8'>
                <label className='my-[16px] font-semibold'>Benchmark Brands</label>
                <input
                    type='text'
                    value={benchmarkInput}
                    onChange={(e) => setBenchmarkInput(e.target.value)}
                    onKeyDown={handleBenchmarkAdd}
                    placeholder='Add a benchmark brand and press Enter'
                    className='w-full border rounded-lg px-3 py-2'
                />
                <div className='flex flex-wrap mt-2'>
                    {localData.benchmarkBrands && localData.benchmarkBrands.map((brand, index) => (
                        <span
                            key={index}
                            className='bg-gray-200 rounded-full px-3 py-1 text-sm mr-2 mb-2'>
                            {brand}
                            <button
                                type='button'
                                onClick={() => removeBenchmarkBrand(index)}
                                className='ml-2 text-red-500'>&times;</button>
                        </span>
                    ))}
                </div>
            </div>
            <div className='col-lg-8 text-end mt-5'>
                <a href='#' onClick={handlePrev} className='text-theme-color me-3 border-[1px] border-theme-color px-4 rounded-pill py-[5px]'>Prev</a>
                <a href='#' onClick={handleNextClick} className='bg-theme-color border-[1px] border-theme-color text-white px-4 rounded-pill py-[5px]'>Next</a>
            </div>
        </>
    );
};

export default Option4;
