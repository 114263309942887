import React from 'react'
import Modal from 'react-bootstrap/Modal';
import PriceModal from './PriceModal';

const CoinPrice = ({ show, showToggle }) => {
    return (
        <Modal show={show} className='coin_modal fade' centered animation={false} onHide={showToggle}>
            <Modal.Header closeButton>
                <Modal.Title className='flex items-center gap-1'>
                    <a href='/'><img src='/assets/images/logos.svg' className='w-[100px]' alt='logo' /></a>
                    <div className='text-theme-color bg-light-purple py-[2px] px-[8px] font-[600] text-[10px] rounded-pill' style={{ letterSpacing: '1px' }}>BETA</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='h-[83vh] overflow-auto'>
                <PriceModal />
            </Modal.Body>
        </Modal>
    )
}

export default CoinPrice