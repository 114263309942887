import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation

const YoutubeDetails = () => {
    const videoTypes = [
        { name: "Banner", size: "1080 x 1080", imgSrc: '/assets/images/home/pin.svg' },
        { name: "Video Thumbnail", size: "1080 x 1080", imgSrc: '/assets/images/home/pin1.svg' }
    ];

    return (
        <div>
            <h1 className='md:text-[24px] text-base my-4'>YouTube</h1>
            <div className="flex details gap-3 flex-nowrap overflow-x-auto whitespace-nowrap">
                {videoTypes.map((video, index) => (
                    <Link to={`/create/${encodeURIComponent(video.name.replace(/\s+/g, '-'))}/${video.size.replace(/\s*x\s*/, '-x-')}`}  key={index} className='flex-shrink-0 md:w-[220px] w-[170px]'>
                        <div className='group'>
                            <div className='relative rounded-[12px] overflow-hidden'>
                                <img src={video.imgSrc} className='w-full' alt={video.name} />
                                <div className='bg-[rgba(174,174,174,0.9)] opacity-0 group-hover:!opacity-100 duration-500 w-full h-full flex items-center justify-center text-white absolute top-0 left-0'>
                                    Start Generating
                                </div>
                            </div>
                            <div className='mt-2'>
                                <p className='mb-0 text-sm font-medium'>{video.name}</p>
                                <span className='text-black/50 text-[12px] opacity-0 group-hover:!opacity-100 duration-500'>{video.size}</span>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default YoutubeDetails;
