import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const Option3 = ({ handleNext, handlePrev, formData }) => {
  const [localData, setLocalData] = useState(formData);

  const [selectedOption, setSelectedOption] = useState(localData.toneOfVoice ? { value: localData.toneOfVoice, label: localData.toneOfVoice } : null);
  const [selectedOption1, setSelectedOption1] = useState(localData.messageStyle ? { value: localData.messageStyle, label: localData.messageStyle } : null);
  const [selectedOption2, setSelectedOption2] = useState(localData.visualStyle ? { value: localData.visualStyle, label: localData.visualStyle } : null);

  const options = [
    { value: 'formal', label: 'Formal' },
    { value: 'Intense', label: 'Intense' },
    { value: 'casual', label: 'Casual' },
    { value: 'playful', label: 'Playful' },
    { value: 'authoritative', label: 'Authoritative' },
  ];

  const options1 = [
    { value: 'inspire', label: 'Inspire' },
    { value: 'educate', label: 'Educational' },
    { value: 'promote', label: 'Promote' },
    { value: 'humour', label: 'Humour' },
  ];
  

  const options2 = [
    { value: 'minimalistic', label: 'Minimalistic' },
    { value: 'bold', label: 'Bold' },
    { value: 'vibrant', label: 'Vibrant' },
    { value: 'professional', label: 'Professional' },
  ];

  const handleNextClick = () => {
    handleNext(localData);
  };
  useEffect(() => {
    setLocalData(formData);
}, [formData]);

  return (
    <>
      <div className='col-lg-8 border-b pb-4'>
        <div className='flex items-center justify-between'>
          <div>
            <h1 className='md:text-[28px] mb-3 text-xl'>Brand Voice</h1>
            <p className='mb-0'>Select push and email notifications that you'd like to receive</p>
          </div>
          <img src='/assets/images/brand-kit/img7.png' className='w-[250px]' alt="Brand Kit" />
        </div>
      </div>
      <div className='col-lg-8'>
        <label className='my-[16px] font-semibold'>Tone of Voice</label>
        <Select
          defaultValue={selectedOption}
          onChange={(option) => {
            setSelectedOption(option);
            setLocalData(prev => ({
              ...prev,
              toneOfVoice: option?.value
            }));
          }}
          options={options}
          className='outline-none'
        />
        <span className='text-[#737373] text-[12px] py-1'>Choose the tone that aligns with the brand: formal, casual, playful, authoritative, etc.</span>
      </div>
      <div className='col-lg-8'>
        <label className='my-[16px] font-semibold'>Message Style</label>
        <Select
          defaultValue={selectedOption1}
          onChange={(option) => {
            setSelectedOption1(option);
            setLocalData(prev => ({
              ...prev,
              messageStyle: option?.value
            }));
          }}
          options={options1}
          className='outline-none'
        />
        <span className='text-[#737373] text-[12px] py-1'>Select preferences for the creative’s message style (inspirational, educational, promotional, humorous).</span>
      </div>
      <div className='col-lg-8'>
        <label className='my-[16px] font-semibold'>Visual Style</label>
        <Select
          defaultValue={selectedOption2}
          onChange={(option) => {
            setSelectedOption2(option);
            setLocalData(prev => ({
              ...prev,
              visualStyle: option?.value
            }));
          }}
          options={options2}
          className='outline-none'
        />
        <span className='text-[#737373] text-[12px] py-1'>Choose between minimalistic, bold, vibrant, or professional styles for the overall design.</span>
      </div>
      <div className='col-lg-8 text-end mt-5'>
        <a href='#' onClick={handlePrev} className='text-theme-color me-3 border-[1px] border-theme-color px-4 rounded-pill py-[5px]'>Prev</a>
        <a href='#' onClick={handleNextClick} className='bg-theme-color border-[1px] border-theme-color text-white px-4 rounded-pill py-[5px]'>Next</a>
      </div>
    </>
  );
};

export default Option3;
