import React, { useEffect, useRef, useState } from 'react'
import SettingRoute from './SettingRoute';
import { IoIosArrowDown } from "react-icons/io";
import SettingHome from './SettingHome';
import PurchaseData from '../purchase-history/PurchaseData';
import PersonalInfo from '../accounts/PersonalInfo';
import Security from '../accounts/Security';
import ActiveSection from '../accounts/ActiveSection';
import Legal from '../accounts/Legal';
import PaymentDetails from '../purchase-history/PaymentDetails';
import Subscription from '../purchase-history/Subscription';
import PurchaseHistory from '../purchase-history/PurchaseHistory';

const Index = () => {

    const [activeOption, setActiveOption] = useState(SettingRoute[0]);
    const [collapsedRoutes, setCollapsedRoutes] = useState({});
    const [heights, setHeights] = useState({});

    const subRouteRefs = useRef({});

    console.log('activeOption', activeOption);

    useEffect(() => {
        const calculatedHeights = {};
        for (const key in subRouteRefs.current) {
            if (subRouteRefs.current[key]) {
                calculatedHeights[key] = subRouteRefs.current[key].scrollHeight;
            }
        }
        setHeights(calculatedHeights);
    }, []);


    const handleOptionClick = (route) => {
        if (route.subRoute) {
            setCollapsedRoutes((prevState) => ({
                [route.id]: !prevState[route.id],
            }));
        } else {
            setCollapsedRoutes({});
            setActiveOption(route);
        }
    };

    const handleSubOptionClick = (subRoute) => {
        setActiveOption(subRoute);
    }

    const isParentActive = (route) => {
        return route.id === activeOption.id;
        // return route.id === activeOption.id || (route.subRoute && route.subRoute.some(sub => sub.id === activeOption.id));
    };

    const setActiveOptionFromHome = (routeName) => {
        const matchedRoute = SettingRoute.find((route) =>
            route.subRoute ? route.subRoute.some((sub) => sub.name === routeName) : route.name === routeName
        );

        if (matchedRoute) {
            if (matchedRoute.subRoute) {
                const matchedSubRoute = matchedRoute.subRoute.find((sub) => sub.name === routeName);
                if (matchedSubRoute) {
                    setActiveOption(matchedSubRoute);
                    setCollapsedRoutes({ [matchedRoute.id]: true });
                }
            } else {
                setActiveOption(matchedRoute);
                setCollapsedRoutes({});
            }
        }
    };

    return (
        <div>
            <div className='flex'>
                <div className='bg-[#F9FAFB] border-r-[1px] border-r-[#00000033] fixed min-h-[100vh] w-[300px] px-3'>
                    <div>
                        <p className='text-xl px-[12px] flex items-center h-[48px] my-1 mb-0 font-semibold'>Settings</p>
                        {Array.isArray(SettingRoute) &&
                            SettingRoute.map((route) => (
                                <div key={route.id}>
                                    <a
                                        href='#path'
                                        className={`text-black hover:bg-light-theme flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg 
                                        ${isParentActive(route) ? 'bg-light-theme' : ''}`}
                                        onClick={() => handleOptionClick(route)}
                                    >
                                        <img src={route.icon} alt='brand' className='w-[24px] grayscale' />
                                        {route.name}
                                        {route.subRoute && (
                                            <span className={`ml-auto transition-transform duration-300 ${collapsedRoutes[route.id] ? '-rotate-180' : ''}`}>
                                                <IoIosArrowDown />
                                            </span>
                                        )}
                                    </a>

                                    <div
                                        ref={(el) => (subRouteRefs.current[route.id] = el)}
                                        style={{
                                            maxHeight: collapsedRoutes[route.id] ? `${heights[route.id]}px` : '0px',
                                            transition: 'max-height 0.3s ease',
                                            overflow: 'hidden',
                                        }}
                                    className='pl-9'
                                    >
                                        {route.subRoute &&
                                            route.subRoute.map((sub) => (
                                                <a
                                                    key={sub.id}
                                                    href='# '
                                                    className={`text-black hover:bg-light-theme flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg ${sub.id === activeOption.id ? 'bg-light-theme' : ''}`}
                                                    onClick={() => handleSubOptionClick(sub)}
                                                >
                                                    {/* <img src={sub.icon} alt='brand' className='w-[24px]' />  */}
                                                    {sub.name}
                                                </a>
                                            ))}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

                <section className='ml-[300px] flex-grow'>
                    <div className='container'>
                        <div className='row justify-center'>
                            {activeOption.id === 1 &&
                                <SettingHome setActiveOptionFromHome={setActiveOptionFromHome} />
                            }
                            {activeOption.name === 'Personal Information' &&
                                <PersonalInfo />
                            }
                            {activeOption.name === 'Security' &&
                                <Security />
                            }
                            {activeOption.name === 'Active Sessions' &&
                                <ActiveSection />
                            }
                            {activeOption.name === 'Legal' &&
                                <Legal />
                            }
                            {activeOption.name === 'Payment Details' &&
                                <PaymentDetails space='col-lg-10 py-[50px] mx-auto' text='Payment Details' border='border-b pb-4'/>
                            }
                            {activeOption.name === 'Subscription' &&
                                <Subscription space='col-lg-10 py-[50px] mx-auto' text='Subscription' border='border-b pb-4'/>
                            }
                            {activeOption.name === 'Purchase History' &&
                                <PurchaseHistory space='col-lg-10 py-[50px] mx-auto' text='Purchase History' border='border-b pb-4'/>
                            }

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Index