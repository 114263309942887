import React, { useEffect, useState } from "react";
import { GoQuestion } from "react-icons/go";
import { useDispatch } from "react-redux";
import { uploadImages } from "../../redux/actions/BrandKit.action";
import { IoCloseCircle } from "react-icons/io5";

const Option5 = ({ handlePrev, formData, handleSubmit }) => {
  const dispatch = useDispatch();
  const [localData, setLocalData] = useState(formData);
  const [filePreviews, setFilePreviews] = useState([]);
  const [brandGuidelineDocs, setBrandGuidelineDocs] = useState([]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newFilePreviews = files.map((file) => URL.createObjectURL(file));
    setFilePreviews((prev) => [...prev, ...newFilePreviews]);
    setBrandGuidelineDocs((prev) => [...prev, ...files]);
    handleImageUpload(files);
  };

console.log(localData);




  const handleImageUpload = async (files) => {
    if (files.length) {
      const imageFormData = new FormData();
      files.forEach((file) => {
        imageFormData.append("image", file);
      });

      const response = await dispatch(uploadImages(imageFormData));
      console.log("upload response", response);

      if (response && response.url) {
        setLocalData({
          ...localData,
          brandGuidelinedocs: Array.isArray(response.url)
            ? response.url
            : [response.url],
        });
      } else {
        console.error("Image upload failed or no URL returned");
      }
    }
  };

  const handleRemoveFile = (index) => {
    setFilePreviews((prev) => prev.filter((_, i) => i !== index));
    setBrandGuidelineDocs((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmitLocalData = () => {
    console.log("Submitting data for Option5:", localData);
    handleSubmit(localData);
  };

  const handleInputChange = (e) => {
    setLocalData({ ...localData, copyrightText: e.target.value });
  };

  useEffect(() => {
    setLocalData(formData);
  }, [formData]);

  return (
    <>
      <div className="col-lg-8 border-b pb-4">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="md:text-[28px] mb-3 text-xl">
              Legal and Compliance
            </h1>
            <p className="mb-0">
              Select push and email notifications that you'd like to receive
            </p>
          </div>
          <img src="/assets/images/brand-kit/img5.png" className="w-[250px]" />
        </div>
      </div>
      <div className="col-lg-8">
        <label className="my-[16px] font-semibold">
          Brand Competitor Guidlines
        </label>
        <div
          id="example-collapse-text"
          className="rounded-lg border-[1px] border-[#00000033]"
        >
          <label className="w-full py-[40px]">
            <input
              type="file"
              className="d-none"
              multiple
              onChange={handleFileChange}
            />
            <div className="text-center">
              {filePreviews.length > 0 ? (
                <div className="file-previews flex gap-3 mx-3">
                  {filePreviews.map((preview, index) => (
                    <div key={index} className="file-preview-item relative">
                      <img
                        src={preview}
                        alt={`preview-${index}`}
                        className="w-[100px] mx-auto mb-2"
                      />
                      <IoCloseCircle
                        onClick={() => handleRemoveFile(index)}
                        className="cursor-pointer absolute top-0 right-0 text-theme-color"
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <img
                  src="/assets/images/home/upload.svg"
                  alt="upload"
                  className="w-[30px] mx-auto mb-2"
                />
              )}
              <div className="md:text-lg font-semibold mb-2 mt-2 block">
                Drag and drop or click here to upload
              </div>
              <p className="text-sm">
                You can also{" "}
                <span className="underline cursor-pointer">
                  browse from your computer
                </span>
              </p>
            </div>
          </label>
        </div>
        <span className="text-[#737373] text-[12px] py-1 rounded">
          Some brands have strict guidelines (e.g., proportions for the logo,
          specific fonts, disclaimers). Allow users to upload their brand
          compliance documents or specify any legal disclaimers to include.
        </span>
      </div>

      <div className="col-lg-8">
        <label className="my-[16px] font-semibold">Copyright Information</label>
        <input
          type="text"
          value={localData.copyrightText || ""}
          onChange={handleInputChange}
          placeholder="Enter information"
          className="w-full border rounded-lg px-3 py-2"
        />
        <span className="text-[#737373] text-[12px] py-1 rounded">
          Option to input any copyright or trademark information that must
          appear on the creative.
        </span>
      </div>

      <div className="col-lg-8 text-end mt-5">
        <a
          href="#"
          onClick={handlePrev}
          className="text-theme-color me-3 border-[1px] border-theme-color px-4 rounded-pill py-[5px]"
        >
          Prev
        </a>
        <a
          href="#"
          onClick={handleSubmitLocalData}
          className="bg-theme-color border-[1px] border-theme-color text-white px-4 rounded-pill py-[5px]"
        >
          Submit
        </a>
      </div>
    </>
  );
};

export default Option5;
