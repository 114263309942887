import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { BsBoxArrowUpRight } from "react-icons/bs";

const FeedbackModal = ({ show, toggle }) => {

    const [selectedOption, setSelectedOption] = useState('report');
    const [selectedEmoji, setSelectedEmoji] = useState(null);

    const emojis = [
        '/assets/images/home/emoji1.png',
        '/assets/images/home/emoji2.png',
        '/assets/images/home/emoji3.png',
        '/assets/images/home/emoji4.png',
        '/assets/images/home/emoji5.png',
    ];

    return (
        <Modal show={show} size='md' centered onHide={toggle} className='feedback'>
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='!text-lg flex items-center gap-3'>Submit feedback to Zunno</Modal.Title>
            </Modal.Header>
            <Modal.Body className=''>
                <div>
                    <p className='text-sm font-medium'>What kind of feedback would you like to give?</p>

                    <div>
                        <div className='flex items-center gap-2'>
                            <input
                                type='radio'
                                name='feedback'
                                value='report'
                                className='w-[18px] h-[18px] accent-theme-color'
                                checked={selectedOption === 'report'}
                                onChange={() => setSelectedOption('report')}
                            />
                            <span>Rate us</span>
                        </div>
                        <div className='flex items-center gap-2'>
                            <input
                                type='radio'
                                name='feedback'
                                value='rate'
                                className='w-[18px] h-[18px] accent-theme-color'
                                checked={selectedOption === 'rate'}
                                onChange={() => setSelectedOption('rate')}
                            />
                            <span>Report a bug</span>
                        </div>
                        <div className='flex items-center gap-2'>
                            <input
                                type='radio'
                                name='feedback'
                                value='suggestions'
                                className='w-[18px] h-[18px] accent-theme-color'
                                checked={selectedOption === 'suggestions'}
                                onChange={() => setSelectedOption('suggestions')}
                            />
                            <span>Suggestions to be made</span>
                        </div>

                        {/* Detail Sections */}
                        <div className='my-4'>
                            {selectedOption === 'rate' &&
                                <div>
                                    <textarea rows={4} className='w-full border-[1px] border-theme-color p-2 rounded-lg outline-none placeholder:text-sm' placeholder='Enter your report bug'></textarea>
                                </div>
                            }
                            {selectedOption === 'report' &&
                                <div>
                                    <p className='font-medium text-sm'>How happy are you with this page?</p>
                                    <div className='flex justify-center mb-3 gap-3'>
                                        {emojis.map((emoji, index) => (
                                            <img
                                                key={index}
                                                src={emoji}
                                                alt={`emoji-${index + 1}`}
                                                className={`cursor-pointer ${selectedEmoji !== null && selectedEmoji !== index ? 'opacity-50' : 'opacity-100'}`}
                                                onClick={() => setSelectedEmoji(index)}
                                            />
                                        ))}
                                    </div>
                                    <textarea rows={4} className='w-full border-[1px] border-theme-color p-2 rounded-lg outline-none placeholder:text-sm' placeholder='Tell us what you like or dislike about this page and how we can improve your exprince.'></textarea>
                                </div>
                            }
                            {selectedOption === 'suggestions' &&
                                <textarea rows={4} className='w-full border-[1px] border-theme-color p-2 rounded-lg outline-none placeholder:text-sm' placeholder='Enter your suggestions'></textarea>
                            }
                        </div>
                    </div>

                    <div className='text-[12px]'>Signed in as james.scalf@gmail.com. <span><a target="_blank" href="#learn" className='text-theme-color !underline'>Learn more</a> about how this data is used and your rights. </span>
                        By pressing Submit, your feedback will be used to improve Microsoft products and services. <a target="_blank" className='text-theme-color !underline' href="#privacy">Privacy statement.</a>
                    </div>

                    <button className='bg-theme-color text-white rounded mt-4 border-[1px] px-[16px] py-[5px] me-3'>Submit</button>
                    <button onClick={toggle} className='border border-black rounded px-[16px] hover:bg-[#f3f2f1] py-[5px]'>Cancel</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FeedbackModal