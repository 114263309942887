import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
import { Wrapper } from "./layout/Index";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing.jsx";
import CreatePage from "./pages/CreatePage.jsx";
import BrandKits from "./pages/BrandKits.jsx";
import SignIn from "./auth/SignIn.js";
import SignUp from "./auth/SignUp.js";
import BrandAdd from "./pages/BrandAdd.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import TermsCondition from "./pages/TermsCondition.jsx";
import Disclaimer from "./pages/Disclaimer.jsx";
import PurchaseHistory from "./pages/PurchaseHistory.jsx";
import AboutUs from "./pages/AboutUs.jsx";
import Faqs from "./pages/Faqs.jsx";
import ContectUs from "./pages/ContectUs.jsx";
import ZunnoBlogs from "./pages/ZunnoBlogs.jsx";
import BrandOption from "./pages/BrandOption.jsx";
import Accounts from "./pages/Accounts.jsx";
import MyProject from "./pages/MyProject.jsx";
import Refer from "./pages/Refer.jsx";
import SettingsInfo from "./pages/SettingsInfo.jsx";
import PersonalInfo from "./components/accounts/PersonalInfo.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FinishSignUp from "./auth/FinishSignUp.js";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "./redux/reducers/Index.reducer.js";
import { thunk } from "redux-thunk";
import { useEffect, useState } from "react";
import MobileView from "./common/MobileView.js";
import ProtectedRoute from "./components/ProtectedRoute.js";





function App() {
  const store = createStore(rootReducer, applyMiddleware(thunk));

  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setLoading(false);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (loading) {
    return null;
  }

  if (isMobile) {
    return <MobileView />;
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route element={<Wrapper />}>
            <Route path="/" element={<Home />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            
            {/* Protected Routes */}
            <Route
              path="/plans-pricing"
              element={
                <ProtectedRoute>
                  <Pricing />
                </ProtectedRoute>
              }
            />
            <Route
              path="/brands-kits"
              element={
                <ProtectedRoute>
                  <BrandKits />
                </ProtectedRoute>
              }
            />
            <Route
              path="/brands-kits/brand-option"
              element={
                <ProtectedRoute>
                  <BrandOption />
                </ProtectedRoute>
              }
            />
            <Route path="/brands-kits/brand-option/:brandId" element={<BrandOption />} />

<Route path="/privacy-policy" element={<PrivacyPolicy />} />
<Route path="/terms-condition" element={<TermsCondition />} />
<Route path="/disclaimer" element={<Disclaimer />} />
<Route path="/purchase-history" element={<PurchaseHistory />} />
<Route path="/about-us" element={<AboutUs />} />
<Route path="/faqs" element={<Faqs />} />
<Route path="/contact-us" element={<ContectUs />} />
<Route path="/zunno-blog" element={<ZunnoBlogs />} />
<Route path="/accounts" element={<Accounts />} />
<Route path="/my-project" element={<MyProject />} />
<Route path="/refer" element={<Refer />} />
<Route path="/setting" element={<SettingsInfo />} />

      
          </Route>

          <Route path="/create/:name/:size" element={<CreatePage />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </Provider>
  );
}

export default App;

